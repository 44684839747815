import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'

export const storageOptionsAdapter = createEntityAdapter<StorageOption, string>({
  selectId: (item) => item.name,
  sortComparer: (a, b) => a.value - b.value,
})

export const storageOptionsSlice = createSlice({
  name: 'storageOptions',
  initialState: storageOptionsAdapter.getInitialState(),
  reducers: {
    storageOptionsReceived(state, action: PayloadAction<{ storageOptions: StorageOption[] }>) {
      storageOptionsAdapter.setAll(state, action.payload.storageOptions)
    },
  },
})

export const { storageOptionsReceived } = storageOptionsSlice.actions
