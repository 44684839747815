import { defineMessages } from 'react-intl'

export const UnauthenticatedRequestFailedToastContentMessages = defineMessages({
  body: {
    defaultMessage: 'If the issue persists, please {contact_support}.',
    id: 'r2PB5z0v',
    description: 'A generic error message shown in an error toast notification body.',
  },
  title: {
    defaultMessage: 'Request Failed',
    id: 't96opEGb',
    description: 'The title shown in the generic error toast notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
})
