import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateInferenceEndpointJob, InferenceEndpointJob } from '@saladtechnologies/openapi-cloud-portal-browser'
import { configuresInferenceEndpointJobsEntityId } from './utils'

export interface InferenceEndpointJobsProps {
  organizationName: string
  inferenceEndpointName: string
  inferenceEndpointJobs: InferenceEndpointJob[]
}

export const inferenceEndpointJobsAdapter = createEntityAdapter<InferenceEndpointJobsProps, string>({
  selectId: (item) => configuresInferenceEndpointJobsEntityId(item.organizationName, item.inferenceEndpointName),
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const inferenceEndpointJobsSlice = createSlice({
  name: 'inferenceEndpointJobs',
  initialState: inferenceEndpointJobsAdapter.getInitialState(),
  reducers: {
    createInferenceEndpointJob(
      _state,
      _action: PayloadAction<{
        organizationName: string
        inferenceEndpointName: string
        createInferenceEndpointJob: CreateInferenceEndpointJob
      }>,
    ) {},
    startPollingInferenceEndpointJob(
      _state,
      _action: PayloadAction<{
        organizationName: string
        inferenceEndpointName: string
        inferenceEndpointJobId: string
      }>,
    ) {},
    stopPollingInferenceEndpointJob() {},
    inferenceEndpointJobAdded(
      state,
      action: PayloadAction<{
        organizationName: string
        inferenceEndpointName: string
        inferenceEndpointJob: InferenceEndpointJob
      }>,
    ) {
      const { inferenceEndpointJob, inferenceEndpointName, organizationName } = action.payload
      const entityId = configuresInferenceEndpointJobsEntityId(organizationName, inferenceEndpointName)

      const existingInferenceEndpointJobs = state.entities[entityId]?.inferenceEndpointJobs || []

      const updatedInferenceEndpointJobs = [
        ...existingInferenceEndpointJobs.filter((job) => job.id !== inferenceEndpointJob.id),
        inferenceEndpointJob,
      ]
      inferenceEndpointJobsAdapter.upsertOne(state, {
        organizationName,
        inferenceEndpointName,
        inferenceEndpointJobs: updatedInferenceEndpointJobs,
      })
    },
    inferenceEndpointJobsAdded: (
      state,
      action: PayloadAction<{
        organizationName: string
        inferenceEndpointName: string
        inferenceEndpointJobs: InferenceEndpointJob[]
      }>,
    ) => {
      const { inferenceEndpointJobs, inferenceEndpointName, organizationName } = action.payload
      const entityId = configuresInferenceEndpointJobsEntityId(organizationName, inferenceEndpointName)

      const existingInferenceEndpointJobs = state.entities[entityId]?.inferenceEndpointJobs || []

      const updatedInferenceEndpointJobs = [
        ...existingInferenceEndpointJobs,
        ...inferenceEndpointJobs.filter(
          (job) => !existingInferenceEndpointJobs.some((existingJob) => existingJob.id === job.id),
        ),
      ]
      inferenceEndpointJobsAdapter.upsertOne(state, {
        organizationName,
        inferenceEndpointName,
        inferenceEndpointJobs: updatedInferenceEndpointJobs,
      })
    },
  },
})

export const {
  createInferenceEndpointJob,
  inferenceEndpointJobAdded,
  inferenceEndpointJobsAdded,
  startPollingInferenceEndpointJob,
  stopPollingInferenceEndpointJob,
} = inferenceEndpointJobsSlice.actions
