import { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { concat, EMPTY, filter, first, from, of, retry, switchMap, takeUntil, timer } from 'rxjs'
import { NovuIntegrationAPI } from '../apiMethods'
import { REACT_APP_NOVU_APP_ID } from '../config'
import { selectAuthenticatedState } from '../features/authentication/authenticationSelectors'
import { selectInboxFeatureFlagEnabled } from '../features/featureFlags/featureFlagsSelectors'
import { logout } from '../features/logout/logoutSlice'
import { setNovuSignature } from '../features/novu/novuSlice'
import type { AppEpic } from '../store'

export const onGetNovuSignature: AppEpic = (action$, state$) =>
  state$.pipe(
    first((state) => selectInboxFeatureFlagEnabled(state) && selectAuthenticatedState(state) === true),
    switchMap(() => {
      if (!REACT_APP_NOVU_APP_ID) {
        return EMPTY
      }

      return concat(
        from(NovuIntegrationAPI.getNovuSignature({ createNovuSignature: { appId: REACT_APP_NOVU_APP_ID } })).pipe(
          switchMap(({ signature }) => of(setNovuSignature({ signature }))),
          retry({
            delay: (error, retryCount) => {
              if (error instanceof ResponseError && error.response.status > 400 && error.response.status <= 499) {
                return EMPTY
              }

              return timer(Math.min(Math.pow(2, retryCount) * 1000 + Math.floor(Math.random() * 1000), 30000))
            },
          }),
          takeUntil(action$.pipe(filter(logout.match))),
        ),
      )
    }),
  )
