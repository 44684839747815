import type { Invitation } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Tag } from '../../../../components/Tag'
import { Card } from '../../../../components/base/Card'
import SaladCloudOrgIcon from '../../assets/SaladCloudOrgIcon.png'
import { OrganizationInvitationCardMessages } from '../../messages'
import { OrganizationCardMessages } from '../../messages/OrganizationCardMessages'

export interface OrganizationInvitationCardProps {
  isAcceptInvitationPending: boolean
  isDeclineInvitationPending: boolean
  onAcceptInvitation: (invitationId: string) => void
  onDeclineInvitation: (invitationId: string) => void
  organizationInvitation: Invitation
}

export const OrganizationInvitationCard: FunctionComponent<OrganizationInvitationCardProps> = ({
  isAcceptInvitationPending,
  isDeclineInvitationPending,
  onAcceptInvitation,
  onDeclineInvitation,
  organizationInvitation,
}) => {
  const intl = useIntl()

  return (
    <div className="mb-4">
      <Card>
        <div className="flex flex-wrap">
          <div className="mr-2 flex flex-col">
            <img
              alt={intl.formatMessage(OrganizationCardMessages.saladCloudOrgIconAlt)}
              height={48}
              src={SaladCloudOrgIcon}
              width={48}
            />
          </div>
          <div>
            <div className="mb-1 flex flex-row">
              <Tag color="gray"> {intl.formatMessage(OrganizationInvitationCardMessages.invitationCardTitle)}</Tag>
            </div>
            <div className="mb-4 flex flex-row gap-2">
              <p className="font-bold">{organizationInvitation.organiationDisplayName}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <Button
            isLoading={isAcceptInvitationPending}
            isDisabled={isAcceptInvitationPending || isDeclineInvitationPending}
            onClick={() => onAcceptInvitation(organizationInvitation.id)}
            variant="green-filled-light"
          >
            {intl.formatMessage(OrganizationInvitationCardMessages.acceptInvitationButtonLabel)}
          </Button>
          <Button
            isLoading={isDeclineInvitationPending}
            isDisabled={isAcceptInvitationPending || isDeclineInvitationPending}
            onClick={() => onDeclineInvitation(organizationInvitation.id)}
            variant="blue-filled-light"
          >
            {intl.formatMessage(OrganizationInvitationCardMessages.declineInvitationButtonLabel)}
          </Button>
        </div>
      </Card>
    </div>
  )
}
