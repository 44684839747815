import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { BillingPaymentMethod } from '@saladtechnologies/openapi-cloud-portal-browser'

export const paymentMethodAdapter = createEntityAdapter<BillingPaymentMethod, string>({
  selectId: (paymentMethod) => paymentMethod.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState: paymentMethodAdapter.getInitialState(),
  reducers: {
    paymentMethodAdded: paymentMethodAdapter.addOne,
    paymentMethodRemoved: paymentMethodAdapter.removeOne,
    paymentMethodUpdated: paymentMethodAdapter.updateOne,
  },
})

export const { paymentMethodAdded, paymentMethodRemoved, paymentMethodUpdated } = paymentMethodsSlice.actions
