import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../Button'
import { Link } from '../../base'
import { Modal } from '../../block/Modal'
import { DeploymentInstanceStatusMessages } from '../messages'
import { InstanceStatusDefinition } from './components'
import { InstanceStatusesExplainerModalMessages } from './messages'

interface InstanceStatusesExplainerModalProps {
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
}

export const InstanceStatusesExplainerModal: FunctionComponent<InstanceStatusesExplainerModalProps> = ({
  onCancel,
}) => {
  const intl = useIntl()

  return (
    <Modal
      customModalTailwindSizeClasses="max-w-5xl max-h-full"
      onClose={onCancel}
      title={intl.formatMessage(InstanceStatusesExplainerModalMessages.title)}
    >
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(InstanceStatusesExplainerModalMessages.title)}</h1>
      <p className="mb-4 text-xs">{intl.formatMessage(InstanceStatusesExplainerModalMessages.subtitleParagraph1)}</p>
      <p className="mb-6 text-xs">{intl.formatMessage(InstanceStatusesExplainerModalMessages.subtitleParagraph2)}</p>

      <InstanceStatusDefinition
        definition={intl.formatMessage(InstanceStatusesExplainerModalMessages.allocatingDefinition, {
          status: <strong>{intl.formatMessage(DeploymentInstanceStatusMessages.allocating)}</strong>,
        })}
        deploymentStatus="allocating"
      />

      <i className={classNames('fa-solid fa-arrow-down my-4 text-neutral-40')} />

      <InstanceStatusDefinition
        definition={intl.formatMessage(InstanceStatusesExplainerModalMessages.downloadingDefinition, {
          status: <strong>{intl.formatMessage(DeploymentInstanceStatusMessages.downloading)}</strong>,
        })}
        deploymentStatus="downloading"
      />

      <i className={classNames('fa-solid fa-arrow-down my-4 text-neutral-40')} />

      <InstanceStatusDefinition
        definition={intl.formatMessage(InstanceStatusesExplainerModalMessages.creatingDefinition, {
          status: <strong>{intl.formatMessage(DeploymentInstanceStatusMessages.creating)}</strong>,
        })}
        deploymentStatus="creating"
      />

      <i className={classNames('fa-solid fa-arrow-down my-4 text-neutral-40')} />

      <InstanceStatusDefinition
        definition={intl.formatMessage(InstanceStatusesExplainerModalMessages.startingDefinition, {
          bold_sentence: (
            <strong>
              {intl.formatMessage(InstanceStatusesExplainerModalMessages.runningAndStartingDefinitionBoldSentence)}
            </strong>
          ),
          status: <strong>{intl.formatMessage(DeploymentInstanceStatusMessages.starting)}</strong>,
        })}
        deploymentStatus="running"
      />

      <i className={classNames('fa-solid fa-arrow-down my-4 text-neutral-40')} />

      <InstanceStatusDefinition
        definition={intl.formatMessage(InstanceStatusesExplainerModalMessages.runningDefinition, {
          bold_sentence: (
            <strong>
              {intl.formatMessage(InstanceStatusesExplainerModalMessages.runningAndStartingDefinitionBoldSentence)}
            </strong>
          ),
          status: <strong>{intl.formatMessage(DeploymentInstanceStatusMessages.running)}</strong>,
        })}
        deploymentStatus="running"
        hasStarted
      />

      <p className="my-4 text-neutral-40">{intl.formatMessage(InstanceStatusesExplainerModalMessages.orDividerText)}</p>

      <InstanceStatusDefinition
        definition={intl.formatMessage(InstanceStatusesExplainerModalMessages.stoppingDefinition, {
          status: <strong>{intl.formatMessage(DeploymentInstanceStatusMessages.stopping)}</strong>,
        })}
        deploymentStatus="stopping"
      />

      <p className="my-8 text-xs">
        {intl.formatMessage(InstanceStatusesExplainerModalMessages.learnMoreText, {
          link: (
            <Link url="https://docs.salad.com/products/sce/deployment-lifecycle">
              {intl.formatMessage(InstanceStatusesExplainerModalMessages.learnMoreLinkText)}
            </Link>
          ),
        })}
      </p>

      <div className="w-56">
        <Button variant="green-filled" isFullWidth onClick={onCancel}>
          {intl.formatMessage(InstanceStatusesExplainerModalMessages.closeButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
