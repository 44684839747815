import { createAction } from '@reduxjs/toolkit'
import { CreateContainerGroupProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { EMPTY, catchError, concat, delay, filter, from, mergeMap, of, withLatestFrom } from 'rxjs'
import {
  BillingAPI,
  ContainerGroupsAPI,
  OrganizationDataAPI,
  OrganizationOptionsAPI,
  OrganizationsAPI,
  ProjectsAPI,
  QueuesAPI,
  QuotasAPI,
  assertUnreachable,
} from '../apiMethods'
import { selectRequiredActionModalToShowOnPageLoad } from '../features/createContainerGroup/createContainerGroupSelectors'
import {
  createContainerGroup,
  getCreateContainerGroupWizardData,
  resetRequiredActionModalShowingState,
  setCreateContainerGroupWizardData,
  setRequiredActionModalShowingState,
} from '../features/createContainerGroup/createContainerGroupSlice'
import type { RequiredActionModal } from '../features/createContainerGroup/models'
import type { gpuClassesProps } from '../features/gpuClasses/gpuClassesSlice'
import { gpuClassesReceived } from '../features/gpuClasses/gpuClassesSlice'
import { configureGpuClassesResponse } from '../features/gpuClasses/utils'
import { jobQueuesReceived } from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { paymentMethodAdded, paymentMethodRemoved } from '../features/paymentMethod/paymentMethodSlice'
import { quotasAddedToOrganization, quotasRemovedFromOrganization } from '../features/quotas/quotasSlice'
import { ramOptionsReceived } from '../features/ramOptions/ramOptionsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { storageOptionsReceived } from '../features/storageOptions/storageOptionsSlice'
import {
  getCreateContainerGroupGeneralErrorContent,
  getCreateContainerGroupHostInvalidErrorContent,
  getCreateContainerGroupImageNameInvalidErrorContent,
  getCreateContainerGroupInvalidRamOptionErrorContent,
  getCreateContainerGroupInvalidRegistryErrorContent,
  getCreateContainerGroupInvalidStorageOptionErrorContent,
  getCreateContainerGroupNameConflictErrorContent,
  getCreateContainerGroupOrganizationNotFoundErrorContent,
  getCreateContainerGroupProbeInvalidErrorContent,
  getCreateContainerGroupProjectNotFoundErrorContent,
  getCreateContainerGroupQueueConnectionNotSetErrorContent,
  getUnableToLoadGPUOptionsErrorContent,
  getUnableToRetrieveContainerCountLimitError,
} from '../notifications/clientToastNotificationContent/containerGroups'
import { getContainerGroupDetailsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

/**
 * An epic that creates a Container Group with the values generated through the Create Container Group wizard.
 *
 * If the creation of the container group is successful, the user will be navigated to the Container Group Detail page
 * for the newly created container group.
 *
 * If the API returns an error, we will either show an error notification or the appropriate modal for further steps
 * required to create a container group.
 */
export const onCreateContainerGroup: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createContainerGroup.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'createContainerGroup', status: 'pending' })),
        from(
          ContainerGroupsAPI.createContainerGroup({
            createContainerGroup: action.payload.createContainerGroup,
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
        ).pipe(
          mergeMap((response) => {
            return concat(
              of(
                setRequestStatus({ request: 'createContainerGroup', status: 'succeeded' }),
                navigateTo({
                  path: getContainerGroupDetailsPagePath(
                    action.payload.organizationName,
                    action.payload.projectName,
                    response.name,
                  ),
                }),
              ),
              of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateContainerGroupProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case CreateContainerGroupProblemType.CreatedQuotaExceeded:
                          return concat(
                            of(
                              setRequiredActionModalShowingState({
                                requiredAction: 'deploymentsQuota',
                                showing: true,
                              }),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )

                        case CreateContainerGroupProblemType.NameConflict:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupNameConflictErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidRegistryAuthentication:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupInvalidRegistryErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )

                        case CreateContainerGroupProblemType.CreatedInstanceQuotaExceeded:
                          return concat(
                            of(
                              setRequiredActionModalShowingState({
                                requiredAction: 'instancesQuota',
                                showing: true,
                              }),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidLoggingConfiguration:
                          return concat(
                            of(
                              showToastNotification({
                                body: 'You have provided an invalid logging configuration.',
                                title: 'Logging Configuration Invalid',
                                type: 'error',
                              }),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.PaymentMethodRequired:
                          return concat(
                            of(
                              setRequiredActionModalShowingState({
                                requiredAction: 'billing',
                                showing: true,
                              }),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidRamOption:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupInvalidRamOptionErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidStorageOption:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupInvalidStorageOptionErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidHost:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupHostInvalidErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.ContainerGroupUpdateException:
                        case CreateContainerGroupProblemType.UnexpectedError:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupGeneralErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.ContainerImageNameInvalid:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupImageNameInvalidErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidLivenessProbe:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupProbeInvalidErrorContent(intl, 'liveness')),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidReadinessProbe:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupProbeInvalidErrorContent(intl, 'readiness')),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.InvalidStartupProbe:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupProbeInvalidErrorContent(intl, 'startup')),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.ProjectNotFoundException:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupProjectNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateContainerGroupProblemType.OrganizationNotFoundException:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupOrganizationNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )

                        case CreateContainerGroupProblemType.QueueConnectionNotSet:
                          return concat(
                            of(
                              showToastNotification(getCreateContainerGroupQueueConnectionNotSetErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )

                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getCreateContainerGroupGeneralErrorContent(intl)),
                          setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getCreateContainerGroupGeneralErrorContent(intl)),
                    setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

const showRequiredActionModalForCreateContainerGroupPage = createAction<{ organizationName: string }>(
  'showRequiredActionModalForCreateContainerGroupPage',
)

/**
 * An epic that retrieves all data necessary in order to go through the Create Container Group Wizard (GPU Classes,
 * Container Count Limit, & Storage Options).
 *
 * If we are unable to retrieve either the list of gpu classes, container count limit or storage options, an error
 * message will be provided in the form to the user for the related field.
 */
export const onGetCreateContainerGroupWizardData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getCreateContainerGroupWizardData.match),
    mergeMap(({ payload: { organizationName, projectName } }) =>
      concat(
        of(
          setRequestStatus({ request: 'getCreateContainerGroupPageData', status: 'pending' }),
          resetRequiredActionModalShowingState(),
        ),
        from(
          Promise.allSettled([
            OrganizationDataAPI.listGpuClasses({
              organizationName,
            }),
            OrganizationOptionsAPI.listStorageOptions({
              organizationName,
            }),
            OrganizationOptionsAPI.listRamOptions({
              organizationName,
            }),
            QuotasAPI.getQuotas({
              organizationName,
            }),
            BillingAPI.getPaymentMethod({
              organizationName,
            }),
            OrganizationsAPI.getOrganization({
              organizationName,
            }),
            ProjectsAPI.getProject({
              organizationName,
              projectName,
            }),
            QueuesAPI.listQueues({
              organizationName,
              projectName,
            }),
          ]),
        ).pipe(
          mergeMap(
            ([
              gpuClassesResponse,
              storageOptionsResponse,
              ramOptionsResponse,
              quotasResponse,
              paymentMethodResponse,
              organizationResponse,
              projectResponse,
              jobQueuesResponse,
            ]) => {
              const gpuClasses: gpuClassesProps[] = [
                configureGpuClassesResponse(
                  organizationName,
                  gpuClassesResponse.status === 'fulfilled' ? gpuClassesResponse.value : undefined,
                ),
              ]
              return concat(
                storageOptionsResponse.status === 'fulfilled'
                  ? of(storageOptionsReceived({ storageOptions: storageOptionsResponse.value.items }))
                  : EMPTY,
                ramOptionsResponse.status === 'fulfilled'
                  ? of(ramOptionsReceived({ ramOptions: ramOptionsResponse.value.items }))
                  : EMPTY,
                of(
                  setRequestStatus({ request: 'getCreateContainerGroupPageData', status: 'succeeded' }),
                  gpuClassesReceived({
                    gpuClasses,
                  }),
                  jobQueuesReceived({
                    organizationName,
                    projectName,
                    jobQueues: jobQueuesResponse.status === 'fulfilled' ? jobQueuesResponse.value.items : [],
                  }),
                  setCreateContainerGroupWizardData({
                    organizationDisplayName:
                      organizationResponse.status === 'fulfilled' ? organizationResponse.value.displayName : undefined,
                    projectDisplayName:
                      projectResponse.status === 'fulfilled' ? projectResponse.value.displayName : undefined,
                  }),
                ),
                quotasResponse.status === 'fulfilled'
                  ? of(
                      quotasAddedToOrganization({
                        organizationName,
                        quotas: quotasResponse.value,
                      }),
                    )
                  : of(
                      showToastNotification(getUnableToRetrieveContainerCountLimitError(intl)),
                      quotasRemovedFromOrganization(organizationName),
                    ),
                paymentMethodResponse.status === 'fulfilled'
                  ? of(paymentMethodAdded(paymentMethodResponse.value))
                  : of(paymentMethodRemoved(organizationName)),
                gpuClassesResponse.status === 'rejected'
                  ? of(showToastNotification(getUnableToLoadGPUOptionsErrorContent(intl)))
                  : EMPTY,
                of(
                  showRequiredActionModalForCreateContainerGroupPage({
                    organizationName,
                  }),
                ),
                of(setRequestStatus({ request: 'getCreateContainerGroupPageData', status: 'idle' })).pipe(delay(1)),
              )
            },
          ),
        ),
      ),
    ),
  )

export const onShowRequiredActionModalForCreateContainerGroupPage: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(showRequiredActionModalForCreateContainerGroupPage.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const requiredAction: RequiredActionModal | undefined = selectRequiredActionModalToShowOnPageLoad(
        state,
        action.payload.organizationName,
      )
      if (requiredAction) {
        return of(setRequiredActionModalShowingState({ requiredAction: requiredAction, showing: true }))
      }

      return EMPTY
    }),
  )
