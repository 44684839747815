import { defineMessages } from 'react-intl'

export const EditOrganizationMainContentMessages = defineMessages({
  displayNameFieldLabel: {
    defaultMessage: 'Organization Display Name',
    id: 'bs+UwNaS',
    description: 'The label for the display name field on the Edit Organization Page.',
  },
  displayNameRequired: {
    defaultMessage: 'The display name cannot be empty.',
    id: 'J5THGKd4',
    description: 'The error message shown when the display name is not provided.',
  },
  displayNameMinMaxErrorMessage: {
    defaultMessage: 'The display name must be between 2-63 characters.',
    id: 'sdrq3+Lo',
    description:
      'The error message for filling out the display name input field when the user has not met the min/max character rules.',
  },
  editOrganizationButtonLabel: {
    defaultMessage: 'Save',
    id: '3fU3vled',
    description: 'The button label for the the form submit button used to edit an organization.',
  },
  organizationNameHeader: {
    defaultMessage: 'Organization Slug Name',
    id: '7qbT2x8h',
    description: 'The header for the section describing the organization name.',
  },
  organizationNameDescription: {
    defaultMessage:
      'This is the permanent name of your organization used in API calls. You can change the name used in the Portal by editing the display name below.',
    id: 'LSULIClh',
    description: "The description of how SaladCloud uses an organization's name.",
  },
  returnToOrganizationsLinkText: {
    defaultMessage: 'Return to Your Organizations',
    id: 'IFJw1CXp',
    description: 'The text for the link that that returns the user back to the Organizations page.',
  },
  title: {
    defaultMessage: 'Edit Display Name',
    id: 'FEfStyKT',
    description: 'The title of for the Edit Organization page.',
  },
})
