import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { CreateOrganizationWizardState } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface CreateOrganizationWizardProps {
  createOrganizationWizard: CreateOrganizationWizardState
}

export const createOrganizationWizardAdapter = createEntityAdapter<CreateOrganizationWizardProps, string>({
  selectId: (item) => item.createOrganizationWizard.id,
  sortComparer: (a, b) => a.createOrganizationWizard.id.localeCompare(b.createOrganizationWizard.id),
})

export const createOrganizationWizardSlice = createSlice({
  name: 'createOrganizationWizardSlice',
  initialState: createOrganizationWizardAdapter.getInitialState(),
  reducers: {
    checkOrganizationNameAvailability(_state, _action: PayloadAction<{ organizationName: string }>) {},
    createOrganizationWizard() {},
    createOrganizationWizardAdded: createOrganizationWizardAdapter.addOne,
    editCreateOrganizationWizard(_state, action: PayloadAction<CreateOrganizationWizardProps>) {
      createOrganizationWizardAdapter.upsertOne(_state, action.payload)
    },
    getCreateOrganizationWizardOrganizationInformationPageData(_state, _action) {},
  },
})

export const {
  checkOrganizationNameAvailability,
  createOrganizationWizard,
  createOrganizationWizardAdded,
  editCreateOrganizationWizard,
  getCreateOrganizationWizardOrganizationInformationPageData,
} = createOrganizationWizardSlice.actions
