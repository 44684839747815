import { defineMessages } from 'react-intl'

export const createOrganizationWizardConfigureCreditSettingsGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'There was an error saving your credit settings. Try again and if this issue persists {contact_support}.',
    id: '4/mhhRIL',
    description:
      'The description shown in a toast notification message when we encounter an error on trying to configure credit settings.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'K3W/SF6o',
    description:
      'The text shown for the contact support link in the configure credit settings error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to configure credit settings',
    id: 'dIQ8P5j/',
    description:
      'The toast notification title shown when we encounter an error on trying to configure credit settings.',
  },
})
