import { defineMessages } from 'react-intl'

export const UnableToGetStartedGenericErrorMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to get started. Try again and if this issue persists {contact_support}.',
    id: 'Mdbx9l87',
    description: 'The body for the unable to get started generic error notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: 'Unable to get started',
    id: 'XcD+UEAC',
    description: 'The title for unable to get started generic error notification.',
  },
})
