import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobInvalidMetadataErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your API endpoint job because the metadata you entered were invalid. Please {contact_support} for further assistance.',
    id: 'k9Yu8pw6',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an API endpoint job because of invalid meta data.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'DBiPt1ju',
    description: 'The text shown for the contact support link in the create API endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating API endpoint job',
    id: 'zlzxZWH7',
    description:
      'The toast notification title shown when we encounter an error on their request to create an API endpoint job because of invalid meta data.',
  },
})
