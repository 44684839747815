import { defineMessages } from 'react-intl'

export const InferenceEndpointsMarketplaceFailedErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We are unable to retrieve API Endpoints for the API Endpoints marketplace at this time. Try again and if this issue persists {contact_support}.',
    id: 'ziDtzNpE',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to load the API Endpoints marketplace.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'mEB/VpvC',
    description:
      'The text shown for the contact support link in the failed to load the API Endpoints marketplace error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve API Endpoints',
    id: 'WW4/K4Qs',
    description:
      'The toast notification title shown when we encounter an error on their request to load the API Endpoints for the API Endpoints marketplace.',
  },
})
