import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { ContainerGroup, UpdateContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'
import { configuresContainerGroupsEntityId } from './utils'

export interface ContainerGroupsProps {
  organizationName: string
  projectName: string
  containerGroups: ContainerGroup[]
}

export const containerGroupsAdapter = createEntityAdapter<ContainerGroupsProps, string>({
  selectId: (item) => configuresContainerGroupsEntityId(item.organizationName, item.projectName),
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const containerGroupsSlice = createSlice({
  name: 'containerGroups',
  initialState: containerGroupsAdapter.getInitialState(),
  reducers: {
    containerGroupAddedToOrganization(
      state,
      action: PayloadAction<{ organizationName: string; projectName: string; containerGroup: ContainerGroup }>,
    ) {
      const { containerGroup, organizationName, projectName } = action.payload
      const containerGroups =
        state.entities[configuresContainerGroupsEntityId(organizationName, projectName)]?.containerGroups || []

      const updatedContainerGroups = containerGroups.filter((cg) => cg.name !== containerGroup.name)
      updatedContainerGroups.push(containerGroup)

      containerGroupsAdapter.upsertOne(state, {
        organizationName,
        projectName,
        containerGroups: updatedContainerGroups,
      })
    },
    containerGroupsAddedToOrganization: containerGroupsAdapter.upsertOne,
    editContainerGroup(
      _state,
      _action: PayloadAction<{
        containerGroupName: string
        organizationName: string
        projectName: string
        updateContainerGroup: UpdateContainerGroup
      }>,
    ) {},
    getContainerGroups(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    getContainerGroupsPageData(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    getEditContainerGroupPageData(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; containerGroupName: string }>,
    ) {},
  },
})

export const {
  containerGroupAddedToOrganization,
  containerGroupsAddedToOrganization,
  editContainerGroup,
  getContainerGroups,
  getContainerGroupsPageData,
  getEditContainerGroupPageData,
} = containerGroupsSlice.actions
