import { defineMessages } from 'react-intl'

export const TriangleBackgroundMessages = defineMessages({
  leftImageAltText: {
    defaultMessage: 'A triangle made of smaller triangles located in the top left of the page.',
    id: '2cDB0Zbz',
    description:
      'The alt description for the SaladCloud triangle image used in the background for the authentication page.',
  },
  rightImageAltText: {
    defaultMessage: 'A triangle made of smaller triangles located in the bottom right of the page.',
    id: 'wli+9mQs',
    description:
      'The alt description for the SaladCloud triangle image used in the background for the authentication page.',
  },
})
