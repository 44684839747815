import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Organization } from '@saladtechnologies/openapi-cloud-portal-browser'

export const organizationsAdapter = createEntityAdapter<Organization, string>({
  selectId: (organization) => organization.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState: organizationsAdapter.getInitialState(),
  reducers: {
    getOrganization(_state, _action: PayloadAction<{ organizationName: string }>) {},
    organizationAdded: organizationsAdapter.addOne,
    organizationsReceived(state, action: PayloadAction<{ organizations: Organization[] }>) {
      organizationsAdapter.setAll(state, action.payload.organizations)
    },
    organizationUpdated: organizationsAdapter.updateOne,
  },
})

export const { getOrganization, organizationAdded, organizationsReceived, organizationUpdated } =
  organizationsSlice.actions
