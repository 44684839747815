import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../Button'
import { JobQueueDetailsMessages } from '../messages'

interface JobQueueDetailsProps {
  /** The desired queue length for autoscaling. */
  autoscalingDesiredQueueLength?: number
  /** The maximum downscale per minute for autoscaling. */
  autoscalingMaximumDownscalePerMinute?: number
  /** The maximum number of replicas for autoscaling. */
  autoscalingMaximumReplicas?: number
  /** The maximum upscale per minute for autoscaling. */
  autoscalingMaximumUpscalePerMinute?: number
  /** The minimum number of replicas for autoscaling. */
  autoscalingMinimumReplicas?: number
  /** The period for autoscaling. */
  autoscalingPeriod?: number
  /** The flag indicating if autoscaling is enabled. */
  isAutoscalingEnabled?: boolean
  /** The feature flag indicating if the job queue autoscaler is enabled. */
  isJobQueueAutoscalerFeatureFlagEnabled?: boolean
  /** The name for the job queue. */
  name?: string
  /** The callback executed when the user clicks the add job queue button. */
  onClickAddJobQueueButton?: () => void
  /** The path for the job queue. */
  path?: string
  /** The port number for the job queue. */
  port?: number
}

export const JobQueueDetails: FunctionComponent<JobQueueDetailsProps> = ({
  autoscalingDesiredQueueLength,
  autoscalingMaximumDownscalePerMinute,
  autoscalingMaximumReplicas,
  autoscalingMaximumUpscalePerMinute,
  autoscalingMinimumReplicas,
  autoscalingPeriod,
  isAutoscalingEnabled,
  isJobQueueAutoscalerFeatureFlagEnabled,
  name,
  onClickAddJobQueueButton,
  path,
  port,
}) => {
  const intl = useIntl()

  return name ? (
    <div className="flex flex-col">
      {name && (
        <p className="truncate">{intl.formatMessage(JobQueueDetailsMessages.queueNameLabel, { queue: name })}</p>
      )}
      {port && <p>{intl.formatMessage(JobQueueDetailsMessages.queuePortLabel, { port })}</p>}
      {path && <p className="truncate">{intl.formatMessage(JobQueueDetailsMessages.queuePathLabel, { path })}</p>}
      {isJobQueueAutoscalerFeatureFlagEnabled && (
        <>
          <p>
            {intl.formatMessage(JobQueueDetailsMessages.autoscalingEnabledLabel, {
              enabled: isAutoscalingEnabled ? 'Yes' : 'No',
            })}
          </p>
          {isAutoscalingEnabled && (
            <>
              {autoscalingDesiredQueueLength && (
                <p className="pl-2">
                  {intl.formatMessage(JobQueueDetailsMessages.autoscalingDesiredQueueLengthLabel, {
                    length: autoscalingDesiredQueueLength,
                  })}
                </p>
              )}
              {(autoscalingMinimumReplicas || autoscalingMinimumReplicas === 0) && (
                <p className="pl-2">
                  {intl.formatMessage(JobQueueDetailsMessages.autoscalingMinimumReplicasLabel, {
                    min_replicas: autoscalingMinimumReplicas,
                  })}
                </p>
              )}
              {autoscalingMaximumReplicas && (
                <p className="pl-2">
                  {intl.formatMessage(JobQueueDetailsMessages.autoscalingMaximumReplicasLabel, {
                    max_replicas: autoscalingMaximumReplicas,
                  })}
                </p>
              )}
              {autoscalingPeriod && (
                <p className="pl-2">
                  {intl.formatMessage(JobQueueDetailsMessages.autoscalingPeriodLabel, { period: autoscalingPeriod })}
                </p>
              )}
              {autoscalingMaximumUpscalePerMinute && (
                <p className="pl-2">
                  {intl.formatMessage(JobQueueDetailsMessages.autoscalingMaximumUpscalePerMinuteLabel, {
                    max_upscale: autoscalingMaximumUpscalePerMinute,
                  })}
                </p>
              )}
              {autoscalingMaximumDownscalePerMinute && (
                <p className="pl-2">
                  {intl.formatMessage(JobQueueDetailsMessages.autoscalingMaximumDownscalePerMinuteLabel, {
                    max_downscale: autoscalingMaximumDownscalePerMinute,
                  })}
                </p>
              )}
            </>
          )}
        </>
      )}
    </div>
  ) : onClickAddJobQueueButton ? (
    <Button variant="green-filled-light" onClick={onClickAddJobQueueButton}>
      {intl.formatMessage(JobQueueDetailsMessages.addJobQueueLabel)}
    </Button>
  ) : (
    <p>{intl.formatMessage(JobQueueDetailsMessages.disabledLabel)}</p>
  )
}
