import { defineMessages } from 'react-intl'

export const HelpScoutSupportButtonMessages = defineMessages({
  helpScoutWidgetFailedViaContactSupportMixpanelEventLabel: {
    defaultMessage: 'Help Scout Widget Failed to Open Via Contact Support',
    id: 'Pm3zX6Tv',
    description: 'The label for the HelpScout Widget Failed to Open Mixpanel Event',
  },
  helpScoutSupportButtonClickedMixpanelEventLabel: {
    defaultMessage: 'Contact Support Clicked',
    id: 'IvZx4ZVo',
    description: 'The label for the contact support button Clicked Mixpanel',
  },
  helpScoutSupportContactSupportButtonLabel: {
    defaultMessage: 'Contact Support',
    id: '6utMk+R3',
    description: 'The label for the contact support button.',
  },
})
