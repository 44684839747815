import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { ButtonLink, Link } from '../../../components/base'
import { Modal } from '../../../components/block/Modal'
import { HelpScoutBeaconFailedToLoadModalMessages } from '../messages'

export interface HelpScoutBeaconFailedToLoadModalProps {
  /** The callback executed when the user clicks the `Close` button. */
  onClose: () => void
}

export const HelpScoutBeaconFailedToLoadModal: FunctionComponent<HelpScoutBeaconFailedToLoadModalProps> = ({
  onClose,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onClose} title={intl.formatMessage(HelpScoutBeaconFailedToLoadModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(HelpScoutBeaconFailedToLoadModalMessages.title)}</h1>
      <p className="mb-12 pr-6">
        {intl.formatMessage(HelpScoutBeaconFailedToLoadModalMessages.body, {
          contact_support_email: (
            <Link url="mailto:cloud@salad.com?subject=SaladCloud Support">
              {intl.formatMessage(HelpScoutBeaconFailedToLoadModalMessages.supportEmail)}
            </Link>
          ),
          documentation_link_text: (
            <Link url="https://docs.salad.com/">
              {intl.formatMessage(HelpScoutBeaconFailedToLoadModalMessages.documentationLinkText)}
            </Link>
          ),
        })}
      </p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onClose} isFullWidth>
          {intl.formatMessage(HelpScoutBeaconFailedToLoadModalMessages.closeButtonLabel)}
        </Button>
        <ButtonLink variant="green-filled" url="https://docs.salad.com/" isFullWidth>
          {intl.formatMessage(HelpScoutBeaconFailedToLoadModalMessages.readDocumentationButtonLabel)}
        </ButtonLink>
      </div>
    </Modal>
  )
}
