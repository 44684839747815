import { defineMessages } from 'react-intl'

export const HelpScoutSupportLinkMessages = defineMessages({
  helpScoutWidgetFailedViaContactSupportMixpanelEventLabeL: {
    defaultMessage: 'Help Scout Widget Failed to Open Via Contact Support',
    id: 'Pm3zX6Tv',
    description: 'The label for the HelpScout Widget Failed to Open Mixpanel Event',
  },
  helpScoutSupportLinkClickedMixpanelEventLabel: {
    defaultMessage: 'Contact Support Clicked',
    id: 'zrdeHyLA',
    description: 'The label for the contact support link clicked Mixpanel event',
  },
})
