import { defineMessages } from 'react-intl'

export const turnstileUnsupportedBrowserErrorMessages = defineMessages({
  bodyText: {
    defaultMessage:
      'Your browser is not supported by the verification tool. Please try an alternative browser or {contact_support}. (Cloudflare Turnstile Error {error_code})',
    description:
      'The body text for the unsupported browser error toast notification, including the error code and contact support link.',
    id: '0Xj0dKxV',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    description: 'The text shown for the contact support link in the unsupported browser error toast notification.',
    id: 'a8+LvgbF',
  },
  turnstileErrorTitle: {
    defaultMessage: 'Unsupported Browser',
    description: 'The title for the unsupported browser error toast notification.',
    id: 'FThSMhh3',
  },
})
