import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { WebhookSecretKeyMainContent } from './components/WebhookSecretKeyMainContent'

export interface WebhookSecretKeyPageProps {
  /**
   * The current organization that the user is in.
   *
   * @param name The organization slug name,
   * @param displayName The organization display name.
   */
  currentOrganization: { name: string; displayName: string }
  /** The flag indicating that the request to change the Webhook Secret key is pending. */
  isChangeWebhookSecretKeyRequestPending: boolean
  /** The flag indicating that the request to change the Webhook Secret key was successful. */
  isChangeWebhookSecretKeyRequestSuccessful?: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user clicks on the Change Webhook Secret Key button. */
  onChangeWebhookSecretKey: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   *
   * @param projectName The selected project name.
   */
  onProjectChange: (projectName: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects?: ProjectSelectOptions
  /** The Webhook Secret Key. */
  webhookSecretKey: string
}

export const WebhookSecretKeyPage: FunctionComponent<WebhookSecretKeyPageProps> = ({
  currentOrganization,
  isChangeWebhookSecretKeyRequestPending,
  isChangeWebhookSecretKeyRequestSuccessful,
  isLeftColumnOpen,
  onChangeWebhookSecretKey,
  onCloseLeftDrawer,
  onProjectChange,
  projects,
  webhookSecretKey,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          isProjectSelectPlaceholderOptionSelectable={true}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <WebhookSecretKeyMainContent
          webhookSecretKey={webhookSecretKey}
          isChangeWebhookSecretKeyRequestPending={isChangeWebhookSecretKeyRequestPending}
          isChangeWebhookSecretKeyRequestSuccessful={isChangeWebhookSecretKeyRequestSuccessful}
          onChangeWebhookSecretKey={onChangeWebhookSecretKey}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
