import { InferenceEndpoint, InferenceEndpointJob } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { InferenceEndpointDetailsMainContent } from './components/InferenceEndpointDetailsMainContent.tsx/InferenceEndpointDetailsMainContent'
import { InferenceEndpointPlaygroundFormValues } from './models'

export interface InferenceEndpointDetailsPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /**
   * The current inference endpoint that the user is viewing. This is used to display the details of the inference
   * endpoint.
   */
  inferenceEndpoint: InferenceEndpoint
  /** The inference endpoint job associated with the inference endpoint. */
  inferenceEndpointJob?: InferenceEndpointJob
  /** The path to the Inference Endpoint Marketplace */
  inferenceEndpointMarketplacePath: string
  /** The flag indicating if the billing information missing modal is showing. */
  isBillingInformationMissingModalShowing?: boolean
  /** The flag indicating if the create inference endpoint job is pending. */
  isCreateInferenceEndpointJobPending: boolean
  /** The flag indicating if the exhausted free trial modal is showing. */
  isExhaustedFreeTrialModalShowing?: boolean
  /** The flag indicating if the left column is showing. */
  isLeftColumnOpen: boolean
  /** The flag indicating if the transcription free trial is enabled. */
  isTranscriptionFreeTrialFeatureFlagEnabled?: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback executed when the user submits the playground form. */
  onCreateInferenceEndpointJob: (values: InferenceEndpointPlaygroundFormValues) => void
  /** The callback executed when the user needs to enter their billing information. */
  onEnterBillingInformation: () => void
  /** The handler updating the project */
  onProjectChange: (projectName: string) => void
  /** The callback executed when there is an update to the billing information required modal showing state */
  onSetBillingInformationMissingModalShowingState: (showing: boolean) => void
  /** The callback executed when there is an update to the exhausted free trial modal showing state */
  onSetExhaustedFreeTrialModalShowingState: (showing: boolean) => void
  /** An array of the current available projects. */
  projects: ProjectSelectOptions
}

export const InferenceEndpointDetailsPage: FunctionComponent<InferenceEndpointDetailsPageProps> = ({
  currentOrganization,
  inferenceEndpoint,
  inferenceEndpointJob,
  inferenceEndpointMarketplacePath,
  isBillingInformationMissingModalShowing,
  isCreateInferenceEndpointJobPending,
  isExhaustedFreeTrialModalShowing,
  isLeftColumnOpen,
  isTranscriptionFreeTrialFeatureFlagEnabled,
  onCloseLeftDrawer,
  onCreateInferenceEndpointJob,
  onEnterBillingInformation,
  onProjectChange,
  onSetBillingInformationMissingModalShowingState,
  onSetExhaustedFreeTrialModalShowingState,
  projects,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          isProjectSelectPlaceholderOptionSelectable
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      MainColumn={
        <InferenceEndpointDetailsMainContent
          inferenceEndpoint={inferenceEndpoint}
          inferenceEndpointJob={inferenceEndpointJob}
          inferenceEndpointMarketplacePath={inferenceEndpointMarketplacePath}
          isBillingInformationMissingModalShowing={isBillingInformationMissingModalShowing}
          isCreateInferenceEndpointJobPending={isCreateInferenceEndpointJobPending}
          isExhaustedFreeTrialModalShowing={isExhaustedFreeTrialModalShowing}
          isTranscriptionFreeTrialFeatureFlagEnabled={isTranscriptionFreeTrialFeatureFlagEnabled}
          onCreateInferenceEndpointJob={onCreateInferenceEndpointJob}
          onEnterBillingInformation={onEnterBillingInformation}
          onSetBillingInformationMissingModalShowingState={onSetBillingInformationMissingModalShowingState}
          onSetExhaustedFreeTrialModalShowingState={onSetExhaustedFreeTrialModalShowingState}
        />
      }
    />
  )
}
