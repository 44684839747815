import { defineMessages } from 'react-intl'

export const turnstileGenericClientExecutionErrorMessages = defineMessages({
  bodyText: {
    defaultMessage:
      'Verification failed due to suspicious activity. Please try again or {contact_support}. (Cloudflare Turnstile Error {error_code})',
    description:
      'The body text for the suspicious activity error toast notification, including the error code and contact support link.',
    id: 'nZpteHNW',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    description: 'The text shown for the contact support link in the suspicious activity error toast notification.',
    id: 'WPRXBAYr',
  },
  turnstileErrorTitle: {
    defaultMessage: 'Suspicious Activity Detected',
    description: 'The title for the suspicious activity error toast notification.',
    id: '2CSSw+3H',
  },
})
