import { defineMessages } from 'react-intl'

export const InferenceEndpointDetailsGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve this API endpoint. Try again and if this issue persists {contact_support}.',
    id: '0+9CW7Xw',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get their API endpoint details.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'NCAg4kEK',
    description:
      'The text shown for the contact support link in the get API endpoint details error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve API endpoint',
    id: 'b79Bv12U',
    description:
      'The toast notification title shown when we encounter an error on their request to get their API endpoint details.',
  },
})
