import { DeleteProfileProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { UserAccountsAPI, assertUnreachable } from '../apiMethods'
import { setAuthenticatedState } from '../features/authentication/authenticationSlice'
import { deleteAccount } from '../features/deleteAccount/deleteAccountSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getDeleteAccountAsLastMemberOfOrganizationContent,
  getDeleteAccountRequestFailedContent,
  getDeleteAccountSucceededContent,
} from '../notifications/clientToastNotificationContent/account'
import type { AppEpic } from '../store'

export const onDeleteAccount: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(deleteAccount.match),
    mergeMap(({ payload: { password } }) => {
      return concat(
        of(setRequestStatus({ request: 'deleteAccount', status: 'pending' })),
        from(UserAccountsAPI.deleteProfile({ deleteProfile: { password } })).pipe(
          mergeMap(() => {
            return concat(
              of(
                showToastNotification(getDeleteAccountSucceededContent(intl)),
                setRequestStatus({ request: 'deleteAccount', status: 'succeeded' }),
                setAuthenticatedState({ authenticated: false }),
              ),
              of(setRequestStatus({ request: 'deleteAccount', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status !== 500
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as DeleteProfileProblemType | null

                    if (errorType != null) {
                      switch (errorType) {
                        case DeleteProfileProblemType.LastMemberInOrganization:
                          return concat(
                            of(
                              showToastNotification(getDeleteAccountAsLastMemberOfOrganizationContent(intl)),
                              setRequestStatus({ request: 'deleteAccount', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'deleteAccount', status: 'idle' })).pipe(delay(1)),
                          )

                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getDeleteAccountRequestFailedContent(intl)),
                          setRequestStatus({ request: 'deleteAccount', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'deleteAccount', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getDeleteAccountRequestFailedContent(intl)),
                    setRequestStatus({ request: 'deleteAccount', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'deleteAccount', status: 'idle' })).pipe(delay(1)),
                ),
          ),
        ),
      )
    }),
  )
