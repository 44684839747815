import { defineMessages } from 'react-intl'

export const EditContainerGroupQueueConnectionNotSetErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'You must add and configure a job queue to enable autoscaling on your container.',
    id: 'Xke8xION',
    description: 'The description shown when we can not edit a container group because the queue is not set.',
  },
  title: {
    defaultMessage: 'Unable to edit container group',
    id: 'tX6I6Gh9',
    description:
      'The toast notification title shown when we can not edit a container group because the queue is not set.',
  },
})
