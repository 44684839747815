import type { StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Returns the `Storage Option` that the user has selected.
 *
 * @param storageOptions The `Storage Options` that SaladCloud Provides users to select from.
 * @param storageValue The `Storage Option` value that the user has listed in their resources.
 */
export const getSelectedStorageOption = (
  storageOptions: StorageOption[] | undefined,
  storageValue: number | null | undefined,
) => {
  if (storageValue === undefined || storageOptions === undefined) return undefined

  return storageOptions.find((storageOption) => storageOption.value === storageValue)
}
