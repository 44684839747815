import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Tag } from '../../../../components/Tag'
import { Link } from '../../../../components/base'
import { SelectProductMainContentMessages } from '../../messages'
import { ProductCard } from '../ProductCard'

export interface SelectProductMainContentProps {
  /** The path for the Container Groups page. */
  containerGroupsPath: string
  /** The path for the Create Container Groups for this project button. */
  createContainerGroupPath: string
  /** The path for the Inference Endpoints Marketplace page. */
  inferenceEndpointsMarketplacePath: string
  /** A flag indicating if the transcription free trial feature flag is enabled. */
  isTranscriptionFreeTrialFeatureFlagEnabled?: boolean
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
}

export const SelectProductMainContent: FunctionComponent<SelectProductMainContentProps> = ({
  containerGroupsPath,
  createContainerGroupPath,
  inferenceEndpointsMarketplacePath,
  isTranscriptionFreeTrialFeatureFlagEnabled,
  onRecordMixpanelElementClickedEvent,
}) => {
  const intl = useIntl()

  return (
    <div className="w-full max-w-2xl">
      <div className="mb-6">
        <Link url={containerGroupsPath}>
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(SelectProductMainContentMessages.backToProjectsLinkText)}
        </Link>
      </div>
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(SelectProductMainContentMessages.title)}</h1>
      <p className="mb-10 text-base">{intl.formatMessage(SelectProductMainContentMessages.description)}</p>
      <div className="flex flex-col gap-10">
        <ProductCard
          buttonLabel={intl.formatMessage(SelectProductMainContentMessages.apiEndpointsProductButtonLabel)}
          description={intl.formatMessage(SelectProductMainContentMessages.apiEndpointsProductDescription)}
          iconImageClassName="fa-solid fa-code"
          onClick={() =>
            onRecordMixpanelElementClickedEvent(
              SelectProductMainContentMessages.apiEndpointsProductButtonLabel.defaultMessage,
            )
          }
          productPath={inferenceEndpointsMarketplacePath}
          tagJSX={
            <span className="flex gap-2">
              <Tag color="green">{intl.formatMessage(SelectProductMainContentMessages.quickStartProductTag)}</Tag>
              {isTranscriptionFreeTrialFeatureFlagEnabled && (
                <Tag color="blue">
                  {intl.formatMessage(SelectProductMainContentMessages.apiEndpointsIntroCreditsTag)}
                </Tag>
              )}
            </span>
          }
          title={intl.formatMessage(SelectProductMainContentMessages.apiEndpointsProductHeader)}
        />
        <ProductCard
          buttonLabel={intl.formatMessage(SelectProductMainContentMessages.containerGroupsProductButtonLabel)}
          description={intl.formatMessage(SelectProductMainContentMessages.containerGroupsProductDescription)}
          iconImageClassName="fa-solid fa-boxes"
          onClick={() =>
            onRecordMixpanelElementClickedEvent(
              SelectProductMainContentMessages.containerGroupsProductButtonLabel.defaultMessage,
            )
          }
          productPath={createContainerGroupPath}
          tagJSX={<Tag color="white">{intl.formatMessage(SelectProductMainContentMessages.advancedProductTag)}</Tag>}
          title={intl.formatMessage(SelectProductMainContentMessages.containerGroupsProductHeader)}
        />
      </div>
    </div>
  )
}
