import { defineMessages } from 'react-intl'

export const InstanceStatusesExplainerModalMessages = defineMessages({
  allocatingDefinition: {
    id: 'ri6KVmsW',
    defaultMessage:
      '{status} means SaladCloud is searching for an available machine to run your container. This usually takes less than a minute. When the allocating tag is yellow, machines are not available currently and the replica will remain allocating until a machine becomes available.',
    description: 'The definition for the allocating instance status',
  },
  closeButtonLabel: {
    id: 'HMak7Kua',
    defaultMessage: 'Close Window',
    description: 'The button label for closing the Instance Statuses Explainer Modal',
  },
  creatingDefinition: {
    id: 'xmRBnHjZ',
    defaultMessage:
      '{status} means SaladCloud is setting up the container image to an accessible node onto the machine. This normally takes about 15 seconds per GB container image. If an instance appears stuck in this state for significantly longer, you may try reallocating the instance to a new node, but keep in mind that the new instance will start over from Allocating.',
    description: 'The definition for the creating instance status',
  },
  downloadingDefinition: {
    id: '3Yrn7nw2',
    defaultMessage:
      '{status} means SaladCloud is downloading and unpacking the container image onto the machine. This typically takes about two minutes per GB container image. If in this state for longer, press ‘Reallocate’ in the action column.',
    description: 'The definition for the downloading instance status',
  },
  learnMoreLinkText: {
    id: '4r5KlWtH',
    defaultMessage: 'here',
    description: 'The linked text for the learn more section of the Instance Statuses Explainer Modal',
  },
  learnMoreText: {
    id: '+pG94bqp',
    defaultMessage: 'Want to learn more? Check out our documentation {link}.',
    description: 'The text for the learn more section of the Instance Statuses Explainer Modal',
  },
  orDividerText: {
    id: 'rk5WPSkL',
    defaultMessage: 'OR',
    description: 'The text for the "OR" divider in the Instance Statuses Explainer Modal',
  },
  runningAndStartingDefinitionBoldSentence: {
    id: 'nNtjzv4b',
    defaultMessage: 'You are charged for time that the instance is in a running or starting state.',
    description: 'The bolded sentence of definition for the running instance status',
  },
  runningDefinition: {
    id: 'fgvW2ZU9',
    defaultMessage: '{status} means your container instance has started on the machine. {bold_sentence}',
    description: 'The definition for the running instance status',
  },
  startingDefinition: {
    id: 'cUxqU3iP',
    defaultMessage:
      '{status} means that you container is now setup on the machine and is waiting for a signal that the container is running properly. If your application requires additional setup, such as downloading models, during this phase, we recommend setting up a Startup Probe. {bold_sentence}',
    description: 'The definition for the starting instance status',
  },
  stoppingDefinition: {
    id: 'R0UqAsFC',
    defaultMessage:
      '{status} means a stop signal has been sent to your container instance. It can take up to several minutes for your container to stop while the signal propagates through the network.',
    description: 'The definition for the stopping instance status',
  },
  subtitleParagraph1: {
    id: 'FuyyrEWM',
    defaultMessage:
      'While deploying on SaladCloud is usually quick, it can occasionally take 20 minutes or more to get your containers up and running, but you are only charged for an instance once it reaches the Running state.',
    description: 'The first paragraph of the subtitle for the Instance Statuses Explainer Modal',
  },
  subtitleParagraph2: {
    id: 'CTZJqLl2',
    defaultMessage: 'Instances pass through the following states while being set up:',
    description: 'The second paragraph of the subtitle for the Instance Statuses Explainer Modal',
  },
  title: {
    id: 'N3Cem/FI',
    defaultMessage: 'Understanding Instance Statuses',
    description: 'The title for the Instance Statuses Explainer Modal',
  },
})
