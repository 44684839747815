import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'

export interface InputHelperTextProps {
  /** The flag indicating that error styles should be applied. */
  hasError?: boolean
  /** The helper text to be shown for the input. */
  helperText: string | ReactNode
  /** The flag indicating that disabled styles should be applied. */
  isDisabled?: boolean
  /* The flag indicating that the text should be green. */
  isHelperTextGreen?: boolean
}

export const InputHelperText: FunctionComponent<InputHelperTextProps> = ({
  hasError,
  helperText,
  isDisabled,
  isHelperTextGreen,
}) => {
  return (
    <span
      className={classNames('mt-1 text-xs', {
        'text-red-70': hasError,
        'text-neutral-40': isDisabled,
        'text-green-80': isHelperTextGreen,
      })}
    >
      {helperText}
    </span>
  )
}
