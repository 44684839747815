import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Quotas } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface QuotasProps {
  organizationName: string
  quotas: Quotas
}

export const quotasAdapter = createEntityAdapter<QuotasProps, string>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const quotasSlice = createSlice({
  name: 'quotas',
  initialState: quotasAdapter.getInitialState(),
  reducers: {
    quotasAddedToOrganization: quotasAdapter.upsertOne,
    quotasRemovedFromOrganization: quotasAdapter.removeOne,
    quotasReceived(state, action: PayloadAction<{ quotas: QuotasProps[] }>) {
      quotasAdapter.setAll(state, action.payload.quotas)
    },
  },
})

export const { quotasAddedToOrganization, quotasReceived, quotasRemovedFromOrganization } = quotasSlice.actions
