import { defineMessages } from 'react-intl'

export const DeleteOrganizationModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'HRUyNmBz',
    description: 'The label for the Cancel button on the modal that shows when a user wants to delete an organization.',
  },
  description: {
    defaultMessage: `Are you absolutely sure? This will permanently delete {organization_name}, remove all your associations to projects, and {forfeit_credits_warning}. You cannot undo this.`,
    id: '2T6k1fv0',
    description: 'The description for the modal that shows when a user wants to delete an organization.',
  },
  forfeitCreditsWarning: {
    defaultMessage: "forfeit any remaining credits you've purchased in that organization",
    id: '8ldpVwz3',
    description: 'The warning on forfeit credits for the modal that shows when a user wants to delete an organization.',
  },
  organizationInputLabel: {
    defaultMessage: `Please type the organization's name to confirm deletion`,
    id: 'men6ZJ+E',
    description: 'The title for the input on the modal that shows when a user wants to delete an organization.',
  },
  title: {
    defaultMessage: 'Delete {organization_name}',
    id: 'XcqODyLk',
    description: 'The title for the modal that shows when a user wants to delete an organization.',
  },
})
