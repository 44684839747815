import { defineMessages } from 'react-intl'

export const createOrganizationWizardCreateOrganizationGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create a new organization. Try again and if this issue persists {contact_support}.',
    id: 'x2LA28/r',
    description:
      'The description shown in a toast notification message shown when we encounter an error on trying create a new organization wizard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'NyAtEPbf',
    description: 'The text shown for the contact support link in the get create organization error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to create a new organization',
    id: 'sVRh2rU+',
    description:
      'The toast notification title shown when we encounter an error on trying create a new organization wizard.',
  },
})
