import type { ClientGpuClass } from '../../../../features/gpuClasses/models'

/**
 * Returns the GPU Classes that the user has selected.
 *
 * @param availableGpuClasses The available GPU Classes that SaladCloud Provides users to select from.
 * @param gpuClassesSelected The selected GPU Classes from the user.
 */
export const getGpuClasses = (availableGpuClasses: ClientGpuClass[], gpuClassesSelected: string[]) => {
  return availableGpuClasses.filter(
    (gpuClass) => gpuClassesSelected.includes(gpuClass.name) || gpuClassesSelected.includes(gpuClass.id),
  )
}
