import { IntlShape } from 'react-intl'
import { ParseEntry, parse as shellParse } from 'shell-quote'
import { Link } from '../../../../../components/base'
import { CodeBlock } from '../components/CodeBlock'
import { DockerOptionsMessages, DockerRunParsingMessages } from '../messages'
import { ParsedDockerRunItem } from '../models'
import { dockerOptions } from './DockerOptions'
import { dockerParseCommandAction } from './DockerRunParsingActions'

export const parseDockerCommand = (command: string, intl: IntlShape): ParsedDockerRunItem[] => {
  const customEnv = (key: string) => `$${key}`

  const dockerObjects = shellParse(command, customEnv) as ParseEntry[]
  const result: ParsedDockerRunItem[] = []

  if (!isValidDockerCommand(dockerObjects)) {
    return [
      {
        name: 'docker run',
        value: '',
        description: intl.formatMessage(DockerRunParsingMessages.noDockerRunCommand),
        isSupported: false,
      },
    ]
  }

  let startIndex = getStartIndex(dockerObjects)
  let entrypoint: string | undefined
  let isImageParsed = false

  while (startIndex < dockerObjects.length) {
    const dockerObject = dockerObjects[startIndex]

    if (typeof dockerObject === 'string' && isFlag(dockerObject) && !isImageParsed) {
      const { flag, nextIndex } = parseFlag(dockerObject, dockerObjects, startIndex, intl)
      if (flag.name === '--entrypoint') {
        entrypoint = flag.value as string
      } else {
        result.push(flag)
      }

      startIndex = nextIndex
    } else if (typeof dockerObject === 'string' && !isImageParsed) {
      const imageOption = {
        name: 'image',
        value: dockerObject,
        description: intl.formatMessage(DockerRunParsingMessages.containerImageSpecified, {
          value: <CodeBlock value={dockerObject} />,
        }),
        isSupported: true,
      }
      result.push(imageOption)
      isImageParsed = true
      startIndex++
    } else {
      break
    }
  }

  if (isImageParsed) {
    const command = parseCommand(dockerObjects, startIndex, entrypoint, intl)
    if (command) result.push(command)
  } else {
    const noImageInOptions = {
      name: 'image',
      value: '',
      description: intl.formatMessage(DockerRunParsingMessages.noImage),
      isSupported: false,
    }
    result.push(noImageInOptions)
  }

  addRecommendedOptions(result, intl)
  return result
}

const isValidDockerCommand = (dockerObjects: ParseEntry[]): boolean => {
  return (
    dockerObjects.length >= 3 &&
    dockerObjects[0] === 'docker' &&
    (dockerObjects[1] === 'run' || (dockerObjects[1] === 'container' && dockerObjects[2] === 'run'))
  )
}

const getStartIndex = (dockerObjects: ParseEntry[]): number => {
  return dockerObjects[1] === 'run' ? 2 : 3
}

const isFlag = (dockerObject: ParseEntry): boolean => {
  return typeof dockerObject === 'string' && dockerObject.startsWith('-')
}

const parseFlag = (
  dockerObject: string,
  dockerObjects: ParseEntry[],
  index: number,
  intl: IntlShape,
): { flag: ParsedDockerRunItem; nextIndex: number } => {
  const flag: ParsedDockerRunItem = { name: dockerObject, value: '', description: '', isSupported: false }
  const option = dockerOptions.find((opt) => opt.names.includes(flag.name))

  if (option) {
    const nextObject = dockerObjects[index + 1]
    if (typeof nextObject === 'string' && !isFlag(nextObject)) {
      flag.value = nextObject
    }

    flag.isSupported = option.isSupported
    flag.isWarning = option.isWarning

    let descriptionMessage = option.description

    if (option.action && typeof flag.value === 'string') {
      const { description, isSupported, value } = option.action(flag.value)
      if (value) {
        flag.value = value
      }
      flag.isSupported = isSupported !== undefined ? isSupported : option.isSupported
      descriptionMessage = description ? description : descriptionMessage
    }

    const hasValue = (descriptionMessage.defaultMessage as string)?.includes('{value}')
    const hasLearnMore = (descriptionMessage.defaultMessage as string)?.includes('{learn_more}')
    const messageVariables: Record<string, any> = {}

    if (hasValue) {
      const flagValue = (flag.value as string) || 'No Value'
      messageVariables['value'] = <CodeBlock value={flagValue} />
    }

    if (hasLearnMore) {
      messageVariables['learn_more'] = (
        <Link url={option.learnMore || ''}>{intl.formatMessage(DockerRunParsingMessages.learnMoreLinkText)}</Link>
      )
    }
    flag.description = intl.formatMessage(descriptionMessage, messageVariables)
    const nextIndex = option.hasNoValue || (nextObject && isFlag(nextObject)) ? index + 1 : index + 2
    return { flag, nextIndex }
  }

  const nextObject = dockerObjects[index + 1]
  if (typeof nextObject === 'string' && !isFlag(nextObject)) {
    flag.value = nextObject
  }

  flag.description = intl.formatMessage(DockerOptionsMessages.unknownFlagDescription, {
    value: <CodeBlock value={flag.value as string} />,
  })
  flag.isSupported = false
  const nextIndex = typeof flag.value === 'string' && flag.value !== '' ? index + 2 : index + 1
  return { flag, nextIndex }
}

const parseCommand = (
  dockerObjects: ParseEntry[],
  index: number,
  entrypoint: string | undefined,
  intl: IntlShape,
): ParsedDockerRunItem | undefined => {
  const commandArray = dockerObjects.slice(index).filter((item): item is string => typeof item === 'string')

  const { value } = dockerParseCommandAction(entrypoint || '', commandArray) as { value: string[] }
  const commandsCodeBlock = (
    <code className="block whitespace-pre-wrap rounded bg-neutral-30 px-2 py-1">
      {value.map((item: string, index: number) => (
        <div key={`${index}-commands`} className="pl-2">
          {item}
        </div>
      ))}
    </code>
  )

  let description
  let isWarning = false

  if (entrypoint && commandArray.length > 0) {
    description = intl.formatMessage(DockerRunParsingMessages.commandDockerEntryPointCmdArg, {
      learn_more: (
        <Link url="https://docs.salad.com/products/sce/specifying-a-command">
          {intl.formatMessage(DockerRunParsingMessages.learnMoreLinkText)}
        </Link>
      ),
      value: commandsCodeBlock,
    })
    isWarning = false
  } else if (entrypoint && commandArray.length === 0) {
    description = intl.formatMessage(DockerRunParsingMessages.commandDockerEntryPointOnly, {
      learn_more: (
        <Link url="https://docs.salad.com/products/sce/specifying-a-command">
          {intl.formatMessage(DockerRunParsingMessages.learnMoreLinkText)}
        </Link>
      ),
      value: commandsCodeBlock,
    })
    isWarning = true
  } else if (!entrypoint && commandArray.length > 0) {
    description = intl.formatMessage(DockerRunParsingMessages.commandDockerCmdOnly, {
      learn_more: (
        <Link url="https://docs.salad.com/products/sce/specifying-a-command">
          {intl.formatMessage(DockerRunParsingMessages.learnMoreLinkText)}
        </Link>
      ),
      value: commandsCodeBlock,
    })
    isWarning = true
  } else {
    return undefined
  }

  return {
    name: 'command',
    value,
    description,
    isSupported: true,
    isWarning,
  }
}

const addRecommendedOptions = (result: ParsedDockerRunItem[], intl: IntlShape) => {
  dockerOptions.forEach((option) => {
    if (option.isRecommended && !result.some((r) => option.names.includes(r.name))) {
      const description = option.recommendedDescription ? intl.formatMessage(option.recommendedDescription) : ''

      result.push({
        name: option.names[0] || '',
        value: '',
        description,
        isSupported: option.isSupported,
        isRecommended: true,
      })
    }
  })
}
