import { defineMessages } from 'react-intl'

export const turnstileInconsistentUserAgentMessages = defineMessages({
  bodyText: {
    defaultMessage:
      'Verification failed due to inconsistent user-agent. Please try disabling any extensions or settings designed to spoof the user-agent, or {contact_support}. (Cloudflare Turnstile Error {error_code})',
    description:
      'The body text for the inconsistent user-agent error toast notification, including the error code and contact support link.',
    id: 's/b7HwZx',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    description: 'The text shown for the contact support link in the inconsistent user-agent error toast notification.',
    id: '2WPnaH0w',
  },
  turnstileErrorTitle: {
    defaultMessage: 'Inconsistent User-Agent Error',
    description: 'The title for the inconsistent user-agent error toast notification.',
    id: 'lPLlb0Y8',
  },
})
