import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Modal } from '../../../../components/block/Modal'
import { Button } from '../../../../components/Button'
import { ExhaustedFreeTrialModalMessages } from '../../messages'

export interface ExhaustedFreeTrialModalProps {
  /** The callback executed when the user clicks the `Back` button. */
  onCloseWindow: () => void
  /** The callback executed when the user clicks the `View Billing Information` button. */
  onViewBillingInfo: () => void
}

export const ExhaustedFreeTrialModal: FunctionComponent<ExhaustedFreeTrialModalProps> = ({
  onCloseWindow,
  onViewBillingInfo,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCloseWindow} title={intl.formatMessage(ExhaustedFreeTrialModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(ExhaustedFreeTrialModalMessages.title)}</h1>
      <p className="mb-8 pr-6">{intl.formatMessage(ExhaustedFreeTrialModalMessages.description)}</p>
      <p className="mb-12 pr-6">{intl.formatMessage(ExhaustedFreeTrialModalMessages.additionalInfo)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCloseWindow} isFullWidth>
          {intl.formatMessage(ExhaustedFreeTrialModalMessages.cancelButtonLabel)}
        </Button>
        <Button onClick={onViewBillingInfo} isFullWidth>
          {intl.formatMessage(ExhaustedFreeTrialModalMessages.viewBillingInfoButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
