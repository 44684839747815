import type { FunctionComponent } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormTrigger } from 'react-hook-form'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../../components/Button'
import { TextField } from '../../../../../components/TextField'
import { Card, Link } from '../../../../../components/base'
import { SidePanelModal } from '../../../../../components/block/SidePanelModal'
import { CommandFieldsMessages } from '../../../messages'
import type { CreateContainerGroupValues } from '../../../models'
import { CreateContainerGroupField } from '../../../models'

interface CommandSidePanelModalProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The flag indicating if the side panel is open. */
  isShown: boolean
  /** The callback executed to close the modal. */
  onClose: () => void
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const CommandSidePanelModalModal: FunctionComponent<CommandSidePanelModalProps> = ({
  clearErrors,
  control,
  isShown,
  onClose,
  trigger,
}) => {
  const intl = useIntl()
  const commandFieldName = CreateContainerGroupField.COMMAND
  const argumentFieldName = CreateContainerGroupField.COMMAND_ARGUMENTS
  const { append, fields, remove } = useFieldArray({
    control,
    name: argumentFieldName,
  })
  const commandValues = useWatch({ control, name: commandFieldName })
  const formID = 'commandFields'

  const handleValidateFieldsBeforeClose = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    await trigger([commandFieldName, argumentFieldName]).then((isValid) => {
      if (fields.length === 0 && commandValues === undefined) {
        clearErrors(commandFieldName)
        onClose()

        return
      }

      if (isValid) {
        onClose()
      }
    })
  }

  const handleRemoveArgument = async (index: number) => {
    await trigger([argumentFieldName]).then(() => {
      if (fields.length === 1) {
        clearErrors([commandFieldName, argumentFieldName])
      }
      remove(index)
    })
  }

  return (
    <SidePanelModal
      CustomButton={
        <Button
          variant="green-filled"
          onClick={() => handleValidateFieldsBeforeClose()}
          form={formID}
          isFullWidth
          type="button"
        >
          {intl.formatMessage(CommandFieldsMessages.configureButtonLabel)}
        </Button>
      }
      isShown={isShown}
      onClose={() => handleValidateFieldsBeforeClose()}
      title={intl.formatMessage(CommandFieldsMessages.commandFieldLabel)}
    >
      <form onSubmit={handleValidateFieldsBeforeClose} id={formID}>
        <div className="my-12 px-10 pb-12">
          <div className="mb-10">
            <h2 className="mb-1 text-3xl font-bold">{intl.formatMessage(CommandFieldsMessages.commandFieldLabel)}</h2>
            <div>
              {intl.formatMessage(CommandFieldsMessages.description, {
                learn_more_here_link: (
                  <Link url="https://docs.salad.com/products/sce/specifying-a-command">
                    {intl.formatMessage(CommandFieldsMessages.learMoreLinkText)}
                  </Link>
                ),
              })}
            </div>
          </div>

          <div className="mb-4">
            <Card>
              <div className="mb-5">
                <p className="text-lg font-bold">{intl.formatMessage(CommandFieldsMessages.commandFieldLabel)}</p>
              </div>

              <Controller
                name={commandFieldName}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      helperText={fieldState.error?.message}
                      placeholder={intl.formatMessage(CommandFieldsMessages.commandFieldPlaceholder)}
                      isFullWidth
                      isTextarea
                      type="text"
                    />
                  )
                }}
              />
            </Card>
          </div>

          {fields.map((item, index) => (
            <div className="mb-4" key={item.id}>
              <Card>
                <div className="mb-5 flex justify-between">
                  <p className="text-lg font-bold">
                    {intl.formatMessage(CommandFieldsMessages.argumentCardHeader, {
                      count: index + 1,
                    })}
                  </p>
                  <Button variant="red-outlined" onClick={() => handleRemoveArgument(index)} type="button">
                    {intl.formatMessage(CommandFieldsMessages.removeArgumentButtonLabel)}
                  </Button>
                </div>

                <Controller
                  name={`${argumentFieldName}.${index}.argument`}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        {...fieldState}
                        defaultValue={field.value}
                        placeholder={intl.formatMessage(CommandFieldsMessages.argumentFieldPlaceholder)}
                        isFullWidth
                        isTextarea
                        type="text"
                      />
                    )
                  }}
                />
              </Card>
            </div>
          ))}

          <Button
            variant="green-filled-light"
            onClick={() => append({ argument: '' })}
            type="button"
            isDisabled={fields.length === 100}
          >
            {intl.formatMessage(CommandFieldsMessages.addArgumentButtonLabel)}
          </Button>
        </div>
      </form>
    </SidePanelModal>
  )
}
