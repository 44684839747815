import type { FunctionComponent } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { HealthProbeFieldsMessages } from '../../../messages'
import type { CreateContainerGroupValues } from '../../../models'
import { CreateContainerGroupField, CreateContainerGroupFormSectionIdAttributes } from '../../../models'
import { HealthProbeFields } from '../components'

interface StartupProbeProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: UseFormSetValue<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const StartupProbeFields: FunctionComponent<StartupProbeProps> = ({
  clearErrors,
  control,
  setValue,
  trigger,
}) => {
  const intl = useIntl()
  const timeoutSecondsFieldName = CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS
  const failureThresholdFieldName = CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD
  const initialDelaySecondsFieldName = CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS
  const periodSecondsFieldName = CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS

  const timeoutSeconds = useWatch({ control, name: timeoutSecondsFieldName })
  const failureThreshold = useWatch({ control, name: failureThresholdFieldName })
  const initialDelay = useWatch({ control, name: initialDelaySecondsFieldName })
  const periodSeconds = useWatch({ control, name: periodSecondsFieldName })
  const calculatedTime =
    parseInt(initialDelay) + (parseInt(periodSeconds) + parseInt(timeoutSeconds)) * parseInt(failureThreshold)
  const subtitle = intl.formatMessage(HealthProbeFieldsMessages.startupSubtitle, {
    learn_more_link_text: (
      <a
        href="https://docs.salad.com/products/sce/health-probes/startup-probes"
        rel="noreferrer"
        target="_blank"
        className="text-blue-90 underline"
      >
        {intl.formatMessage(HealthProbeFieldsMessages.learMoreLinkText)}
      </a>
    ),
  })

  return (
    <HealthProbeFields
      calculatedTime={calculatedTime}
      clearErrors={clearErrors}
      commandFieldName={CreateContainerGroupField.STARTUP_PROBE_COMMAND}
      commandArgumentsFieldName={CreateContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS}
      control={control}
      enabledFieldName={CreateContainerGroupField.STARTUP_PROBE_ENABLED}
      failureThresholdFieldName={failureThresholdFieldName}
      formID="startupProbeFieldsForm"
      headersFieldName={CreateContainerGroupField.STARTUP_PROBE_HEADERS}
      healthProbe="Startup"
      id={CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE}
      initialDelaySecondsFieldName={initialDelaySecondsFieldName}
      pathFieldName={CreateContainerGroupField.STARTUP_PROBE_PATH}
      periodSecondsFieldName={periodSecondsFieldName}
      portFieldName={CreateContainerGroupField.STARTUP_PROBE_PORT}
      protocolFieldName={CreateContainerGroupField.STARTUP_PROBE_PROTOCOL}
      setValue={setValue}
      serviceFieldName={CreateContainerGroupField.STARTUP_PROBE_SERVICE}
      subtitle={subtitle}
      successThresholdFieldName={CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD}
      timeoutSecondsFieldName={timeoutSecondsFieldName}
      trigger={trigger}
    />
  )
}
