import { defineMessages } from 'react-intl'

export const turnstileChallengeTimedOutErrorMessages = defineMessages({
  bodyText: {
    defaultMessage:
      'Verification timed out. Please try again or {contact_support}. (Cloudflare Turnstile Error {error_code})',
    description:
      'The body text for the timeout error toast notification, including the error code and contact support link.',
    id: '8kPcSKF0',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    description: 'The text shown for the contact support link in the timeout error toast notification.',
    id: 'mA0k7rU4',
  },
  turnstileErrorTitle: {
    defaultMessage: 'Verification Timeout',
    description: 'The title for the timeout error toast notification.',
    id: 'v1+hmY4f',
  },
})
