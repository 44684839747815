import { defineMessages } from 'react-intl'

export const VerificationErrorMessages = defineMessages({
  title: {
    defaultMessage: 'Seems like there was an issue with verification.',
    id: 'C9uSe4w0',
    description: 'The title of verification issue dialog',
  },
  contactSupportLabel: {
    defaultMessage: 'Contact Support',
    id: 'W9fl/XeE',
    description: 'The "Contact Support" button text',
  },
  contactSupportButtonClickedMixpanelEventLabel: {
    defaultMessage: 'Contact Support Clicked On Verification Error',
    id: 'HtDy34hy',
    description: 'The label for the contact support button Clicked Mixpanel',
  },
  contactSupportHelpScoutWidgetFailedMixpanelEventLabel: {
    defaultMessage: 'Help Scout Widget Failed to Open On Verification Error',
    id: '6/MCay7a',
    description: 'The label for the HelpScout Widget Failed to Open Mixpanel Event',
  },
  description: {
    defaultMessage:
      'There was an error while verifying your email address. If you already verified your account, please try to login.',
    id: '/xczNq1/',
    description: 'The description of verification issue dialog',
  },
  logInButtonLabel: {
    defaultMessage: 'Log In',
    id: '/UjEQinW',
    description: 'The "Log In" button text',
  },
})
