import { ContainerGroupStatus } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import type { DeploymentInstance } from '../../../features/containerGroupInstanceTable/models'
import type { ContainerGroupLogsFormValues } from '../../../pages/ContainerGroupDetails/models'
import { getInstancePath } from '../../../routes/routes-utils'
import { Link } from '../../base'
import { Table } from '../../block/Table'
import { CustomTableHeader, TableColumnWidth, TableSortingOrder } from '../../block/Table/models'
import {
  DeploymentInstanceTableActionsButton,
  DeploymentInstanceTableAgeRowItem,
  DeploymentInstanceTableMachineIdAndStatusRowItem,
  DeploymentInstanceTableReadinessRowItem,
  DeploymentInstanceTableStatusDetailsRowItem,
} from './components'
import { DeploymentInstanceTableMessages } from './messages'

export interface DeploymentInstanceTableProps {
  /** List of deployment instances */
  deploymentInstances: DeploymentInstance[]
  /** Deployment current status */
  deploymentStatus: ContainerGroupStatus
  /** The container group details page path */
  instanceDetailsPagePath?: string
  /**
   * The callback executed when the user reallocates a deployment instance.
   *
   * @param machineId The machine ID of the deployment instance to reallocate.
   */
  onReallocateDeploymentInstance?: (machineId: string) => void
  /**
   * A callback that when executed records a mixpanel event
   *
   * @param label The label of the element that was clicked
   */
  onTrackMixpanelEvent: (label: string, machineId?: string) => void
  /**
   * The callback executed when the user recreates a deployment instance.
   *
   * @param machineId The machine ID of the deployment instance to reallocate.
   */
  onRecreateDeploymentInstance?: (machineId: string) => void
  /**
   * The callback executed when the user restarts a deployment instance.
   *
   * @param machineId The machine ID of the deployment instance to reallocate.
   */
  onRestartDeploymentInstance?: (machineId: string) => void
  /** The callback executed when the `InstanceStatusExplainerModal` component should be shown. */
  onShowInstanceStatusExplainerModal: () => void
  /**
   * The callback executed when the user views the container group logs.
   *
   * @param containerGroupLogsFormValues The container group logs form values.
   */
  onViewContainerGroupLogs?: (
    containerGroupLogsFormValues: Partial<ContainerGroupLogsFormValues>,
    tabValue: number,
  ) => void
}

export const DeploymentInstanceTable: FunctionComponent<DeploymentInstanceTableProps> = ({
  deploymentInstances,
  deploymentStatus,
  instanceDetailsPagePath,
  onReallocateDeploymentInstance,
  onRecreateDeploymentInstance,
  onRestartDeploymentInstance,
  onShowInstanceStatusExplainerModal,
  onTrackMixpanelEvent,
  onViewContainerGroupLogs,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const isContainerGroupStopped = deploymentStatus === ContainerGroupStatus.Stopped
  const isContainerGroupFailed = deploymentStatus === ContainerGroupStatus.Failed
  const isContainerGroupPending = deploymentStatus === ContainerGroupStatus.Pending
  const isDeploymentInstanceRunning = (state: string) => (isContainerGroupStopped ? false : state === 'running')
  const showActionsHeader =
    onReallocateDeploymentInstance !== undefined &&
    onRecreateDeploymentInstance !== undefined &&
    onRestartDeploymentInstance !== undefined &&
    onViewContainerGroupLogs !== undefined

  const headers: CustomTableHeader[] = [
    {
      key: DeploymentInstanceTableMessages.machineIdAndStatusHeader.id,
      label: intl.formatMessage(DeploymentInstanceTableMessages.machineIdAndStatusHeader),
      columnWidth: TableColumnWidth.FourTwelfths,
      initialSort: true,
    },
    {
      key: DeploymentInstanceTableMessages.readyHeader.id,
      label: intl.formatMessage(DeploymentInstanceTableMessages.readyHeader),
      columnWidth: TableColumnWidth.OneTwelfth,
    },
    {
      key: DeploymentInstanceTableMessages.lastStatusUpdateHeader.id,
      label: intl.formatMessage(DeploymentInstanceTableMessages.lastStatusUpdateHeader),
      initialSort: true,
      direction: TableSortingOrder.Ascending,
      columnWidth: TableColumnWidth.ThreeTwelfths,
    },
    {
      key: DeploymentInstanceTableMessages.ageHeader.id,
      label: intl.formatMessage(DeploymentInstanceTableMessages.ageHeader),
      columnWidth: TableColumnWidth.TwoTwelfths,
    },
  ]

  if (showActionsHeader) {
    headers.push({
      key: DeploymentInstanceTableMessages.actionsHeader.id,
      label: intl.formatMessage(DeploymentInstanceTableMessages.actionsHeader),
      noSort: true,
      columnWidth: TableColumnWidth.TwoTwelfths,
    })
  }

  const handleOnNavigateToInstanceDetailsPage = (instanceId: string) => {
    instanceDetailsPagePath && navigate(getInstancePath(instanceDetailsPagePath, instanceId))
  }

  const tableItems = deploymentInstances.map((instance) => {
    const {
      allocatingTakingLongerThanExpected,
      creatingProgress,
      downloadingProgress,
      hasStarted,
      isReady,
      isReallocatePending,
      isRecreatePending,
      isRestartPending,
      machineId,
      status,
      updateTime,
      version,
    } = instance

    let item = {
      [DeploymentInstanceTableMessages.machineIdAndStatusHeader.id]: {
        value: (
          <DeploymentInstanceTableMachineIdAndStatusRowItem
            machineId={machineId}
            deploymentStatus={status}
            creatingProgress={creatingProgress}
            downloadingProgress={downloadingProgress}
            isDeploymentInstanceRunning={isDeploymentInstanceRunning}
            hasAllocatingTakenLongerThanExpected={allocatingTakingLongerThanExpected}
            hasStarted={hasStarted}
            onNavigateToInstanceDetailsPage={handleOnNavigateToInstanceDetailsPage}
            onTrackMixpanelEvent={onTrackMixpanelEvent}
            instanceDetailsPagePath={instanceDetailsPagePath}
            onClickStatus={onShowInstanceStatusExplainerModal}
          />
        ),
        sortWith: status,
      },
      [DeploymentInstanceTableMessages.readyHeader.id]: {
        value: (
          <DeploymentInstanceTableReadinessRowItem
            onNavigateToInstanceDetailsPage={handleOnNavigateToInstanceDetailsPage}
            instanceDetailsPagePath={instanceDetailsPagePath}
            isDeploymentInstanceRunning={isDeploymentInstanceRunning}
            isReady={isReady}
            machineId={machineId}
            status={status}
          />
        ),
        sortWith: isReady,
      },
      [DeploymentInstanceTableMessages.lastStatusUpdateHeader.id]: {
        value: (
          <DeploymentInstanceTableStatusDetailsRowItem
            instanceDetailsPagePath={instanceDetailsPagePath}
            isDeploymentInstanceRunning={isDeploymentInstanceRunning}
            machineId={machineId}
            onNavigateToInstanceDetailsPage={handleOnNavigateToInstanceDetailsPage}
            status={status}
            updateTime={updateTime}
            version={version}
          />
        ),
        sortWith: updateTime,
      },
      [DeploymentInstanceTableMessages.ageHeader.id]: {
        value: (
          <DeploymentInstanceTableAgeRowItem
            onNavigateToInstanceDetailsPage={handleOnNavigateToInstanceDetailsPage}
            instanceDetailsPagePath={instanceDetailsPagePath}
            isDeploymentInstanceRunning={isDeploymentInstanceRunning}
            machineId={machineId}
            status={status}
            updateTime={updateTime}
          />
        ),
        sortWith: updateTime,
      },
    }

    if (showActionsHeader && status !== 'allocating' && machineId) {
      item[DeploymentInstanceTableMessages.actionsHeader.id] = {
        value: (
          <DeploymentInstanceTableActionsButton
            isDisabled={deploymentStatus === ContainerGroupStatus.Stopped}
            isReallocateInstancePending={isReallocatePending}
            isRecreateInstancePending={isRecreatePending}
            isRestartInstancePending={isRestartPending}
            onReallocateInstance={() => onReallocateDeploymentInstance(machineId)}
            onRecreateInstance={() => onRecreateDeploymentInstance(machineId)}
            onRestartInstance={() => onRestartDeploymentInstance(machineId)}
            onViewContainerGroupLogs={() => onViewContainerGroupLogs({ machineId }, 3)}
            state={status}
          />
        ),
        sortWith: null,
      }
    }

    return item
  })

  return (
    <>
      <p className="my-4 text-sm">
        {intl.formatMessage(DeploymentInstanceTableMessages.instanceStatusesDescription, {
          read_more_here_link: (
            <Link url="https://docs.salad.com/products/sce/deployment-lifecycle">
              {intl.formatMessage(DeploymentInstanceTableMessages.readMoreHere)}
            </Link>
          ),
        })}
      </p>
      <Table
        headers={headers}
        items={
          isContainerGroupPending ||
          isContainerGroupFailed ||
          (isContainerGroupStopped && deploymentInstances.length === 0)
            ? []
            : tableItems
        }
        isCentered
      />
    </>
  )
}
