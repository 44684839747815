import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import {
  EditLoggingInputFieldMessages,
  EditSelectExternalLoggingServiceMessages,
  EditTCPInputFieldsMessages,
} from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditTCPInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
}

export const EditTCPInputFields: FunctionComponent<EditTCPInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={EditContainerGroupField.TCP_HOST}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditTCPInputFieldsMessages.hostLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.TCP_PORT}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={intl.formatMessage(EditTCPInputFieldsMessages.portHelperText)}
            label={intl.formatMessage(EditTCPInputFieldsMessages.portLabel)}
            type="number"
            max={65535}
            min={1}
            isFullWidth
          />
        )}
      />
      <div>
        <p className="mb-2">
          {intl.formatMessage(EditLoggingInputFieldMessages.needHelpText, {
            external_logging_provider: intl.formatMessage(EditSelectExternalLoggingServiceMessages.tcpLabel),
          })}
        </p>
        <ButtonLink url="https://docs.salad.com/products/sce/external-logging/tcp-logging" variant="blue-filled-light">
          {intl.formatMessage(EditLoggingInputFieldMessages.learnMoreText)}
        </ButtonLink>
      </div>
    </>
  )
}
