import { defineMessages } from 'react-intl'

export const BillingStripeFormMessages = defineMessages({
  addPaymentMethodAndPrepayButton: {
    defaultMessage: 'Prepay $50 and add payment method',
    id: '8WLcD4Ob',
    description: 'The button label for add payment method and prepay.',
  },
  addPaymentMethodButton: {
    defaultMessage: 'Add Payment Method',
    id: 'ew0adc9g',
    description: 'The button label for add payment method.',
  },
  addPaymentMethodTitle: {
    defaultMessage: 'Add a Payment Method to increase your deployments quotas on SaladCloud',
    id: 'msh3h5YB',
    description: 'The title label for add payment method.',
  },
  billingPrepayDisclaimer: {
    defaultMessage: `The first time you add a payment method for an organization, it will be charged $50 USD. If the charge is successful, we'll apply $75 total credit to your account. This is a one-time prepayment. Once you run out of introductory or other credit, you'll be billed each time your balance reaches $200. Any remailing balance will be charged at the end of the month. By pressing "Add Payment Method," you agree that your usage beyond free credits can be billed to this card.`,
    id: 'YO5DYCsv',
    description: 'The disclaimer about the prepayment billing process.',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    id: 'bRhzJzic',
    description: 'The button label for cancel.',
  },
  stripeSecureHelperText: {
    defaultMessage: 'All payment and billing are securely handled through Stripe',
    id: 'BCm0I/PH',
    description: 'The Stripe helper text for add payment method.',
  },
  temporaryHoldInfo: {
    defaultMessage:
      'A temporary hold of $10 will be placed on your card to ensure it is valid. Once verified it will be immediately cancelled. By pressing “Add Payment Method,” you agree that your usage beyond free credits can be billed to this card.',
    id: 'xTWo3tjv',
    description: 'The additional quota info text about temporary hold of 10$.',
  },
  updatePaymentMethodButton: {
    defaultMessage: 'Update Payment Method',
    id: 'Q1XH/l/w',
    description: 'The button label for update payment method.',
  },
})
