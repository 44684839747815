import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { ChangePasswordMainContentMessages } from '../../messages'
import { useChangePasswordFormValidation } from './useChangePasswordFormValidation'

export interface ChangePasswordValues {
  /** The user's current password. */
  currentPassword: string
  /** The user's new password. */
  newPassword: string
  /** The user's new password, verified. */
  verifyPassword: string
}

interface ChangePasswordMainContentProps {
  /** The user's email */
  email: string
  /** The flag indicating that the request to change the password is pending. */
  isChangePasswordPending: boolean
  /** A callback that when executed changes the user's password. */
  onChangePassword: (currentPassword: string, newPassword: string) => void
}

export const ChangePasswordMainContent: FunctionComponent<ChangePasswordMainContentProps> = ({
  email,
  isChangePasswordPending,
  onChangePassword,
}) => {
  const intl = useIntl()
  const changePasswordValidationScheme = useChangePasswordFormValidation()

  const { control, handleSubmit } = useFixedForm<ChangePasswordValues>({
    resolver: yupResolver(changePasswordValidationScheme),
    onSubmit: ({ currentPassword, newPassword }) => onChangePassword(currentPassword, newPassword),
  })

  return (
    <div className="w-full max-w-2xl">
      <div className="mb-4">
        <Link url="/account">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(ChangePasswordMainContentMessages.returnToAccountSettingsLinkText)}
        </Link>
      </div>

      <h1 className="mb-2 text-3xl font-bold">{intl.formatMessage(ChangePasswordMainContentMessages.title)}</h1>
      <p className="mb-10 text-base">
        <FormattedMessage
          {...ChangePasswordMainContentMessages.subtitle}
          values={{
            email: <span className="text-green-80">{email}</span>,
          }}
        />
      </p>

      <form onSubmit={handleSubmit}>
        <Controller
          name="currentPassword"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={fieldState.error?.message}
              leftIconClassName="fa-solid fa-lock"
              label={intl.formatMessage(ChangePasswordMainContentMessages.currentPasswordFieldLabel)}
              type="password"
              isFullWidth
            />
          )}
        />
        <Controller
          name="newPassword"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              isExtraHeight
              helperText={intl.formatMessage(ChangePasswordMainContentMessages.newPasswordInvalid)}
              leftIconClassName="fa-solid fa-lock"
              label={intl.formatMessage(ChangePasswordMainContentMessages.newPasswordFieldLabel)}
              type="password"
              isFullWidth
            />
          )}
        />
        <Controller
          name="verifyPassword"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={fieldState.error?.message}
              leftIconClassName="fa-solid fa-lock"
              label={intl.formatMessage(ChangePasswordMainContentMessages.verifyPasswordFieldLabel)}
              type="password"
              isFullWidth
            />
          )}
        />
        <Button type="submit" isLoading={isChangePasswordPending} isDisabled={isChangePasswordPending} isFullWidth>
          {intl.formatMessage(ChangePasswordMainContentMessages.changePasswordButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
