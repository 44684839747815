import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import SaladCloudVerifiedSrc from './assets/SaladCloudVerified.svg'
import { SaladCloudVerifiedMessages } from './messages'

export const SaladCloudVerified: FunctionComponent = () => {
  const intl = useIntl()

  return (
    <span className="flex">
      <img
        height={16}
        width={13.79}
        src={SaladCloudVerifiedSrc}
        alt={intl.formatMessage(SaladCloudVerifiedMessages.SaladCloudVerifiedAltTag)}
      />
      <p className="ml-2 font-medium text-green-80">
        {intl.formatMessage(SaladCloudVerifiedMessages.SaladCloudVerifiedLabel)}
      </p>
    </span>
  )
}
