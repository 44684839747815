import { defineMessages } from 'react-intl'

export const createOrganizationWizardPaymentSummaryGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to confirm your payment. Try again and if this issue persists {contact_support}.',
    id: '3+3qfQ5p',
    description:
      'The description shown in a toast notification message when we encounter an error on trying to confirm the payment summary.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'y7h+Ph1h',
    description: 'The text shown for the contact support link in the payment summary error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to confirm payment',
    id: 'Kes0cn2m',
    description:
      'The toast notification title shown when we encounter an error on trying to confirm the payment summary.',
  },
})
