import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { CreateContainerGroupMainContentMessages, QuayInputFieldsMessages } from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface QuayInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const QuayInputFields: FunctionComponent<QuayInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={CreateContainerGroupField.QUAY_USERNAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(QuayInputFieldsMessages.usernameLabel)}
            placeholder={intl.formatMessage(QuayInputFieldsMessages.usernamePlaceholderText)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={CreateContainerGroupField.QUAY_PASSWORD}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(QuayInputFieldsMessages.passwordLabel)}
            placeholder={intl.formatMessage(QuayInputFieldsMessages.passwordPlaceholderText)}
            type="password"
            isFullWidth
          />
        )}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(QuayInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/products/sce/registries/quay" variant="blue-filled-light">
          {intl.formatMessage(CreateContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
