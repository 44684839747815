import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { AWSElasticInputFieldsMessages, CreateContainerGroupMainContentMessages } from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface AWSElasticInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const AWSElasticInputFields: FunctionComponent<AWSElasticInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={CreateContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(AWSElasticInputFieldsMessages.accessKeyIDLabel)}
            placeholder={intl.formatMessage(AWSElasticInputFieldsMessages.accessKeyIDPlaceholderText)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={CreateContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(AWSElasticInputFieldsMessages.secretAccessKeyLabel)}
            placeholder={intl.formatMessage(AWSElasticInputFieldsMessages.secretAccessKeyPlaceholderText)}
            type="password"
            isFullWidth
          />
        )}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(AWSElasticInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/products/sce/registries/aws-ecr" variant="blue-filled-light">
          {intl.formatMessage(CreateContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
