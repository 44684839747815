import {
  GenerateInstanceLogStreamAuthTokenProblemType,
  ResponseError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, defer, delay, filter, from, mergeMap, of, switchMap } from 'rxjs'
import { ContainerGroupsAPI, assertUnreachable } from '../apiMethods'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { fetchTerminalToken, setTerminalWebSocketUrl } from '../features/terminal/terminalSlice'
import {
  getContainerGroupInstanceTerminalGeneralErrorMessages,
  getContainerGroupInstanceTerminalNodeDoesNotSupportLogStreamingErrorMessages,
  getContainerGroupInstanceTerminalNodeNotScheduledErrorMessages,
} from '../notifications/clientToastNotificationContent/instanceDetails'
import type { AppEpic } from '../store'

export const onFetchTerminalToken: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(fetchTerminalToken.match),
    switchMap((action) =>
      concat(
        of(setRequestStatus({ request: 'fetchTerminalToken', status: 'pending' })),
        defer(() =>
          from(
            ContainerGroupsAPI.generateInstanceLogStreamAuthToken({
              organizationName: action.payload.organizationName,
              projectName: action.payload.projectName,
              containerGroupName: action.payload.containerGroupName,
              containerGroupInstanceId: action.payload.machineId,
            }),
          ),
        ).pipe(
          mergeMap((response) => {
            return of(
              setTerminalWebSocketUrl({
                url: `${response.origin}/exec?commands=%5B%5B%22%2Fbin%2Fbash%22%5D,%5B%22%2Fbin%2Fash%22%5D,%5B%22%2Fbin%2Fsh%22%5D%5D&token=${response.token}`,
              }),
              setRequestStatus({ request: 'fetchTerminalToken', status: 'succeeded' }),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as GenerateInstanceLogStreamAuthTokenProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case GenerateInstanceLogStreamAuthTokenProblemType.NodeDoesNotSupportLogStreaming:
                          return concat(
                            of(
                              showToastNotification(
                                getContainerGroupInstanceTerminalNodeDoesNotSupportLogStreamingErrorMessages(intl),
                              ),
                              setRequestStatus({ request: 'fetchTerminalToken', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'fetchTerminalToken', status: 'idle' })).pipe(delay(1)),
                          )
                        case GenerateInstanceLogStreamAuthTokenProblemType.NodeNotScheduled:
                          return concat(
                            of(
                              showToastNotification(
                                getContainerGroupInstanceTerminalNodeNotScheduledErrorMessages(intl),
                              ),
                              setRequestStatus({ request: 'fetchTerminalToken', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'fetchTerminalToken', status: 'idle' })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getContainerGroupInstanceTerminalGeneralErrorMessages(intl)),
                          setRequestStatus({ request: 'fetchTerminalToken', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'fetchTerminalToken', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getContainerGroupInstanceTerminalGeneralErrorMessages(intl)),
                    setRequestStatus({ request: 'fetchTerminalToken', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'fetchTerminalToken', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
