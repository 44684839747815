import { CreateInferenceEndpointJobProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  EMPTY,
  catchError,
  concat,
  defer,
  delay,
  filter,
  from,
  mergeMap,
  of,
  repeat,
  switchMap,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs'
import { InferenceEndpointsAPI } from '../apiMethods'
import { trackMixpanelEvent } from '../features/analytics/analyticsSlice'
import { selectLatestInferenceEndpointJob } from '../features/inferenceEndpointJobs/inferenceEndpointJobsSelectors'
import {
  createInferenceEndpointJob,
  inferenceEndpointJobAdded,
  startPollingInferenceEndpointJob,
  stopPollingInferenceEndpointJob,
} from '../features/inferenceEndpointJobs/inferenceEndpointJobsSlice'
import { configuresInferenceEndpointJobsEntityId } from '../features/inferenceEndpointJobs/utils'
import { setInferenceEndpointIsExhaustedFreeTrialModalShowing } from '../features/inferenceEndpoints/inferenceEndpointsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getCreateInferenceEndpointJobGeneralErrorContent,
  getCreateInferenceEndpointJobInferenceEndpointDisabledErrorContent,
  getCreateInferenceEndpointJobInferenceEndpointNotFoundErrorContent,
  getCreateInferenceEndpointJobInvalidInputErrorContent,
  getCreateInferenceEndpointJobInvalidMetadataErrorContent,
  getInferenceEndpointDetailsGeneralErrorContent,
} from '../notifications/clientToastNotificationContent/inferenceEndpoints'
import {
  hasInferenceEndpointJobReachedFinalStatus,
  isInferenceEndpointJobTerminal,
} from '../pages/InferenceEndpointDetails/utils'
import type { AppEpic } from '../store'

export const onCreateInferenceEndpointJob: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createInferenceEndpointJob.match),
    mergeMap(({ payload: { createInferenceEndpointJob, inferenceEndpointName, organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'pending' })),
        from(
          InferenceEndpointsAPI.createInferenceEndpointJob({
            organizationName,
            inferenceEndpointName,
            createInferenceEndpointJob,
          }),
        ).pipe(
          mergeMap((inferenceEndpointJob) =>
            concat(
              of(
                setRequestStatus({ request: 'createInferenceEndpointJob', status: 'succeeded' }),
                inferenceEndpointJobAdded({ inferenceEndpointJob, inferenceEndpointName, organizationName }),
                startPollingInferenceEndpointJob({
                  organizationName,
                  inferenceEndpointName,
                  inferenceEndpointJobId: inferenceEndpointJob.id,
                }),
                trackMixpanelEvent({
                  event: 'Job Created',
                  properties: {
                    organizationName: organizationName,
                    inferenceEndpointName: inferenceEndpointName,
                    jobId: inferenceEndpointJob.id,
                    resource: 'Inference Endpoint',
                    source: 'SIE Playground',
                  },
                }),
              ),
              of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            concat(
              error instanceof ResponseError
                ? from(error.response.json()).pipe(
                    mergeMap((errorResponse) => {
                      const errorType = errorResponse.type as CreateInferenceEndpointJobProblemType | null
                      switch (errorType) {
                        case CreateInferenceEndpointJobProblemType.InferenceEndpointDisabled:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              showToastNotification(
                                getCreateInferenceEndpointJobInferenceEndpointDisabledErrorContent(intl),
                              ),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateInferenceEndpointJobProblemType.InferenceEndpointJobComplete:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              showToastNotification(getCreateInferenceEndpointJobGeneralErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateInferenceEndpointJobProblemType.InferenceEndpointJobDuplicate:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              showToastNotification(getCreateInferenceEndpointJobGeneralErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateInferenceEndpointJobProblemType.InferenceEndpointJobInvalidInput:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              showToastNotification(getCreateInferenceEndpointJobInvalidInputErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateInferenceEndpointJobProblemType.InferenceEndpointJobInvalidMetadata:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              showToastNotification(getCreateInferenceEndpointJobInvalidMetadataErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateInferenceEndpointJobProblemType.InferenceEndpointNotFound:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              showToastNotification(
                                getCreateInferenceEndpointJobInferenceEndpointNotFoundErrorContent(intl),
                              ),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        case CreateInferenceEndpointJobProblemType.PaymentMethodRequired:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              setInferenceEndpointIsExhaustedFreeTrialModalShowing({
                                organizationName,
                                isExhaustedFreeTrialModalShowing: true,
                              }),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                        default:
                          return concat(
                            of(
                              setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                              showToastNotification(getCreateInferenceEndpointJobGeneralErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(
                              delay(1),
                            ),
                          )
                      }
                    }),
                  )
                : concat(
                    of(
                      setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                      showToastNotification(getCreateInferenceEndpointJobGeneralErrorContent(intl)),
                    ),
                    of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(delay(1)),
                  ),
            ),
          ),
        ),
      ),
    ),
  )

export const onPollInferenceEndpointJob: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(startPollingInferenceEndpointJob.match),
    switchMap(({ payload: { inferenceEndpointJobId, inferenceEndpointName, organizationName } }) =>
      defer(() =>
        from(
          InferenceEndpointsAPI.getInferenceEndpointJob({
            organizationName,
            inferenceEndpointName,
            inferenceEndpointJobId,
          }),
        ),
      ).pipe(
        repeat({
          delay: () => {
            const twentySecondsInMS = 20000
            return timer(twentySecondsInMS)
          },
        }),
        withLatestFrom(state$),
        mergeMap(([inferenceEndpointJob, state]) => {
          return selectLatestInferenceEndpointJob(
            state,
            configuresInferenceEndpointJobsEntityId(organizationName, inferenceEndpointName),
          )?.status !== inferenceEndpointJob.status
            ? concat(
                of(
                  setRequestStatus({ request: 'getInferenceEndpointJob', status: 'succeeded' }),
                  inferenceEndpointJobAdded({ inferenceEndpointJob, inferenceEndpointName, organizationName }),
                  setRequestStatus({ request: 'getInferenceEndpointJob', status: 'idle' }),
                ).pipe(delay(1)),
                hasInferenceEndpointJobReachedFinalStatus(inferenceEndpointJob.status)
                  ? concat(
                      isInferenceEndpointJobTerminal(inferenceEndpointJob.status)
                        ? of(
                            showToastNotification(getInferenceEndpointDetailsGeneralErrorContent(intl)),
                            stopPollingInferenceEndpointJob(),
                          )
                        : EMPTY,
                      of(stopPollingInferenceEndpointJob()),
                    )
                  : EMPTY,
              )
            : EMPTY
        }),
        catchError(() =>
          concat(
            of(
              setRequestStatus({ request: 'getInferenceEndpointJob', status: 'failed' }),
              showToastNotification(getInferenceEndpointDetailsGeneralErrorContent(intl)),
            ),
            of(setRequestStatus({ request: 'getInferenceEndpointJob', status: 'idle' })).pipe(delay(1)),
          ),
        ),
        takeUntil(action$.pipe(filter(stopPollingInferenceEndpointJob.match))),
      ),
    ),
  )
