import type { WorkloadError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../../components/Button'
import { Link } from '../../../../../components/base'
import { WorkloadErrorsTable } from '../../../../../components/deployments/WorkloadErrorsTable/WorkloadErrorsTable'
import { onDownloadWorkloadErrors } from '../../../../../components/deployments/WorkloadErrorsTable/utils'
import { WorkloadErrorsMessages } from './WorkloadErrorsMessages'

interface WorkloadErrorsProps {
  /** The name of the container group */
  containerGroupDisplayName: string
  /** The workload errors for the container group */
  workloadErrors: WorkloadError[]
}

export const WorkloadErrors: FunctionComponent<WorkloadErrorsProps> = ({
  containerGroupDisplayName,
  workloadErrors,
}) => {
  const intl = useIntl()
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const isDownloadDisabled = workloadErrors.length === 0

  const handleOnDownloadWorkloadErrors = () =>
    onDownloadWorkloadErrors(workloadErrors, containerGroupDisplayName, setIsDownloadLoading, intl)
  return (
    <>
      <div className="mt-10">
        <div className="flex justify-between">
          <h2 className="text-base font-bold">
            {intl.formatMessage(WorkloadErrorsMessages.recentWorkloadErrorsTitle)}
          </h2>
          <Button
            iconClassName="fa-solid fa-download"
            isLoading={isDownloadLoading}
            isDisabled={isDownloadDisabled}
            onClick={handleOnDownloadWorkloadErrors}
            variant="green-filled-light"
          >
            {intl.formatMessage(WorkloadErrorsMessages.downloadButtonLabel)}
          </Button>
        </div>
      </div>
      <div className="mt-4">
        {intl.formatMessage(WorkloadErrorsMessages.recentWorkloadErrorsDescription, {
          documentation_link: (
            <Link url="https://docs.salad.com/products/sce/workload-logs">
              {intl.formatMessage(WorkloadErrorsMessages.documentationLinkText)}
            </Link>
          ),
        })}
      </div>
      <div className="mt-10">
        <WorkloadErrorsTable workloadErrors={workloadErrors} />
      </div>
    </>
  )
}
