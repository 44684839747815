import { defineMessages } from 'react-intl'

export const SaladCloudVerifiedMessages = defineMessages({
  SaladCloudVerifiedLabel: {
    defaultMessage: 'SaladCloud Verified',
    id: 'qxThrI30',
    description: "The label for our SaladCloud Logo that's shown on inference endpoint cards.",
  },
  SaladCloudVerifiedAltTag: {
    defaultMessage: 'SaladCloud Logo',
    id: 'Jq/ZQk10',
    description: "The alt tag for our SaladCloud Logo that's shown on inference endpoint cards.",
  },
})
