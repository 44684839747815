import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { InferenceEndpoint } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface InferenceEndpointsProps {
  organizationName: string
  inferenceEndpoints: InferenceEndpoint[]
  isBillingInformationMissingModalShowing?: boolean
  isExhaustedFreeTrialModalShowing?: boolean
}

export const inferenceEndpointsAdapter = createEntityAdapter<InferenceEndpointsProps, string>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const inferenceEndpointsSlice = createSlice({
  name: 'inferenceEndpoints',
  initialState: inferenceEndpointsAdapter.getInitialState(),
  reducers: {
    getInferenceEndPointDetailsPageData(
      _state,
      _action: PayloadAction<{
        organizationName: string
        inferenceEndpointName: string
      }>,
    ) {},
    getInferenceEndpointsMarketplacePageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    inferenceEndpointsAddedToOrganization: inferenceEndpointsAdapter.upsertOne,
    inferenceEndpointAddedToOrganization(
      state,
      action: PayloadAction<{ organizationName: string; inferenceEndpoint: InferenceEndpoint }>,
    ) {
      const { inferenceEndpoint, organizationName } = action.payload
      const inferenceEndpoints = state.entities[organizationName]?.inferenceEndpoints || []

      const updatedInferenceEndpoints = inferenceEndpoints.filter((ie) => ie.name !== inferenceEndpoint.name)
      updatedInferenceEndpoints.push(inferenceEndpoint)

      inferenceEndpointsAdapter.upsertOne(state, {
        organizationName,
        inferenceEndpoints: updatedInferenceEndpoints,
      })
    },
    setInferenceEndpointIsBillingInformationMissingModalShowing(
      state,
      action: PayloadAction<{ organizationName: string; isBillingInformationMissingModalShowing: boolean }>,
    ) {
      const { isBillingInformationMissingModalShowing, organizationName } = action.payload

      inferenceEndpointsAdapter.updateOne(state, {
        id: organizationName,
        changes: {
          isBillingInformationMissingModalShowing,
        },
      })
    },
    setInferenceEndpointIsExhaustedFreeTrialModalShowing(
      state,
      action: PayloadAction<{ organizationName: string; isExhaustedFreeTrialModalShowing: boolean }>,
    ) {
      const { isExhaustedFreeTrialModalShowing, organizationName } = action.payload

      inferenceEndpointsAdapter.updateOne(state, {
        id: organizationName,
        changes: {
          isExhaustedFreeTrialModalShowing,
        },
      })
    },
  },
})

export const {
  getInferenceEndPointDetailsPageData,
  getInferenceEndpointsMarketplacePageData,
  inferenceEndpointAddedToOrganization,
  inferenceEndpointsAddedToOrganization,
  setInferenceEndpointIsBillingInformationMissingModalShowing,
  setInferenceEndpointIsExhaustedFreeTrialModalShowing,
} = inferenceEndpointsSlice.actions
