import trim from 'lodash/trim'

/**
 * The Portal API base URL. This is required, and the application will fail to run if it is `undefined`.
 *
 * @example `https://portal-api.salad.com/api/portal`
 */
export const API_URL = required(normalize(process.env['REACT_APP_API_URL']), 'API_URL')

/**
 * The Help Scout Beacon identifier. This may be `undefined` to disable the Help Scout Beacon.
 *
 * @example `6ae23a26-7feb-4831-87e5-67c79f54afcf`
 */
export const HELP_SCOUT_BEACON_ID = normalize(process.env['REACT_APP_HELP_SCOUT_BEACON_ID'])

/**
 * The Mixpanel API base URL. This may be `undefined` to use the default Mixpanel API server.
 *
 * @example `https://app-api.salad.com/api/v2/mixpanel`
 */
export const MIXPANEL_API_URL = normalize(process.env['REACT_APP_MIXPANEL_API_URL'])

/**
 * The Mixpanel user project token. This may be `undefined` to disable Mixpanel.
 *
 * @example `19d3c46327408e71cc887b121a537328`
 */
export const MIXPANEL_USER_PROJECT_TOKEN = normalize(process.env['REACT_APP_MIXPANEL_USER_PROJECT_TOKEN'])

/**
 * The Mixpanel project token specifically for the organization project.
 *
 * @example `19d3c46327408e71cc887b121a537328`
 */
export const MIXPANEL_ORGANIZATION_PROJECT_TOKEN = normalize(
  process.env['REACT_APP_MIXPANEL_ORGANIZATION_PROJECT_TOKEN'],
)

/**
 * The Novu application identifier. This may be `undefined` to disable Novu.
 *
 * @example `gto_jZaguM4c`
 */
export const REACT_APP_NOVU_APP_ID = normalize(process.env['REACT_APP_NOVU_APP_ID'])

/**
 * The Novu backend base URL. This may be `undefined` to use the default Novu backend server.
 *
 * @example `https://api.novu.co`
 */
export const REACT_APP_NOVU_BACKEND_URL = normalize(process.env['REACT_APP_NOVU_BACKEND_URL'])

/**
 * The Novu websocket base URL. This may be `undefined` to use the default Novu websocket server.
 *
 * @example `https://ws.novu.co`
 */
export const REACT_APP_NOVU_WEBSOCKET_URL = normalize(process.env['REACT_APP_NOVU_WEBSOCKET_URL'])

/**
 * The Sentry DSN. This may be `undefined` to disable Sentry.
 *
 * @example `https://789782fe97f34e458e201e71d9f265ef@o236248.ingest.sentry.io/4504330844372992`
 */
export const SENTRY_DSN = normalize(process.env['REACT_APP_SENTRY_DSN'])

/**
 * The Sentry environment. This is required, and the application will fail to run if it is `undefined`.
 *
 * @example `production`
 */
export const SENTRY_ENVIRONMENT = required(normalize(process.env['REACT_APP_SENTRY_ENVIRONMENT']), 'SENTRY_ENVIRONMENT')

/**
 * The Sentry release identifier. This may be `undefined` to not associate the current runtime with uploaded source
 * maps.
 *
 * @example `da39a3ee5e6b4b0d3255bfef95601890afd80709`
 */
export const SENTRY_RELEASE = normalize(process.env['REACT_APP_SENTRY_RELEASE'])

/**
 * The Unleash application name. This is required, and the application will fail to run if it is `undefined`.
 *
 * @example `saladcloud-portal`
 */
export const UNLEASH_APP_NAME = required(normalize(process.env['REACT_APP_UNLEASH_APP_NAME']), 'UNLEASH_APP_NAME')

/**
 * The Unleash client key. This may be `undefined` to disable Unleash.
 *
 * @example `3hV0ZDadGDx7I4soIsojIBMoSNEixliH`
 */
export const UNLEASH_CLIENT_KEY = normalize(process.env['REACT_APP_UNLEASH_CLIENT_KEY'])

/**
 * The collection of static development toggles resolvable by Unleash. When defined, this disables fetching feature
 * flags from the Unleash proxy API. This should be `undefined` to enable fetching feature flags from the Unleash proxy
 * API.
 *
 * @example
 * `[{"name":"portal_sentry","enabled":true,"variant":{"name":"default","enabled":true,"payload":{"type":"json","value":"{\"errorSampleRate\":1,\"transactionSampleRate\":1}"}},"impressionData":false}]`
 */
export const UNLEASH_DEVELOPMENT_TOGGLES = normalize(process.env['REACT_APP_UNLEASH_DEVELOPMENT_TOGGLES'])

/**
 * The Stripe environment. This is required, and the Stripe will fail to run if it is `undefined`.
 *
 * @example `https://dashboard.stripe.com/test/apikeys`
 */
export const STRIPE_API_KEY = normalize(process.env['REACT_APP_STRIPE_API_KEY'])

/**
 * The Stripe key used for the storybook environment by prefixing the key with `STORYBOOK_` we can read environmental
 * variables in storybook
 *
 * @example `https://dashboard.stripe.com/test/apikeys`
 */
export const STORYBOOK_STRIPE_API_KEY = normalize(process.env['STORYBOOK_STRIPE_STRIPE_API_KEY'])

/**
 * The Unleash environment. This is required, and the application will fail to run if it is `undefined`.
 *
 * @example `production`
 */
export const UNLEASH_ENVIRONMENT = required(
  normalize(process.env['REACT_APP_UNLEASH_ENVIRONMENT']),
  'UNLEASH_ENVIRONMENT',
)

/**
 * The Unleash proxy API URL. This may be `undefined` to disable Unleash.
 *
 * @example `https://features.salad.com/proxy`
 */
export const UNLEASH_PROXY_URL = normalize(process.env['REACT_APP_UNLEASH_PROXY_URL'])

function normalize(value: string | undefined): string | undefined {
  value = trim(value)
  return value.length > 0 ? value : undefined
}

function required(value: string | undefined, name: string): string {
  if (value === undefined) {
    throw new Error(`The ${name} configuration value is required.`)
  }

  return value
}

/**
 * The Turnstile token.
 *
 * @example `1x00000000000000000000AA`
 */
export const TURNSTILE_TOKEN = normalize(process.env['REACT_APP_TURNSTILE_TOKEN'])
