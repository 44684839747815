import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import {
  HealthProbeFailureThresholdInput,
  HealthProbePortInput,
  HealthProbeServiceInput,
  HealthProbeSuccessThresholdInput,
  HealthProbeTimeoutSecondsInput,
  HealthProbesInitialDelaySecondsInput,
  HealthProbesPeriodSecondsInput,
} from '.'
import { Button } from '../../../../../components/Button'
import { Checkbox } from '../../../../../components/Checkbox'
import { Link } from '../../../../../components/base'
import { SidePanelModal } from '../../../../../components/block/SidePanelModal'
import { HealthProbeDetails } from '../../../../../components/containerGroups/HealthProbeDetails'
import { FormStateCard } from '../../../../../components/forms/FormStateCard'
import { Select } from '../../../../../components/forms/Select'
import { HealthProbeFieldsMessages } from '../../../messages'
import type {
  CreateContainerGroupFormSectionIdAttributes,
  CreateContainerGroupValues,
  HealthProbeType,
} from '../../../models'
import { ProtocolOptions } from '../../../models'
import { checkIfHealthProbeFormHasErrors, configureHealthProbeCommand, configureHealthProbeHeaders } from '../utils'
import { HealthProbeCommandSidePanelModal } from './HealthProbeCommandSidePanelModal'
import { HealthProbeHeadersSidePanelModal } from './HealthProbeHeadersSidePanelModal'
import { HealthProbePathInput } from './HealthProbePathInput'

interface HealthProbeFieldsProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The calculated time for the health probe. */
  calculatedTime?: number
  /** The name for the command input field. */
  commandFieldName: string
  /** The name for the command arguments input field. */
  commandArgumentsFieldName: string
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The name for the enabled input field. */
  enabledFieldName: string
  /** The name for the failure threshold input field. */
  failureThresholdFieldName: string
  /** The ID for the form. */
  formID: string
  /** The name for the headers input field. */
  headersFieldName: string
  /** The health probe the command is being added to for the side panel modal. */
  healthProbe: HealthProbeType
  /** The helper text for the health probe `FormStateCard`. */
  helperText?: string | ReactNode
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The name for the initial delay seconds input field. */
  initialDelaySecondsFieldName: string
  /** The flag indicating that the health probe is disabled. */
  isDisabled?: boolean
  /** The flag indicating whether the side panel is open. */
  isReadinessProbeSidePanelOpen?: boolean
  /** The name for the path input field. */
  pathFieldName: string
  /** The name for the period seconds input field. */
  periodSecondsFieldName: string
  /** The name for the port input field. */
  portFieldName: string
  /** The name for the protocol input field. */
  protocolFieldName: string
  /** The name for the service input field. */
  serviceFieldName: string
  /** The function to set the side panel open state. */
  setReadinessProbeSidePanelOpen?: (isOpen: boolean) => void
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: UseFormSetValue<FieldValues>
  /** The optional subtitle for the health probe fields. */
  subtitle?: ReactNode | string
  /** The name for the success threshold input field. */
  successThresholdFieldName: string
  /** The name for the timeout seconds input field. */
  timeoutSecondsFieldName: string
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const HealthProbeFields: FunctionComponent<HealthProbeFieldsProps> = ({
  calculatedTime,
  clearErrors,
  commandArgumentsFieldName,
  commandFieldName,
  control,
  enabledFieldName,
  failureThresholdFieldName,
  formID,
  headersFieldName,
  healthProbe,
  helperText,
  id,
  initialDelaySecondsFieldName,
  isDisabled,
  isReadinessProbeSidePanelOpen: propIsReadinessProbeSidePanelOpen,
  pathFieldName,
  periodSecondsFieldName,
  portFieldName,
  protocolFieldName,
  serviceFieldName,
  setReadinessProbeSidePanelOpen: propSetReadinessProbeSidePanelOpen,
  setValue,
  subtitle,
  successThresholdFieldName,
  timeoutSecondsFieldName,
  trigger,
}) => {
  const [isLocalReadinessProbeSidePanelOpen, setLocalReadinessProbeSidePanelOpen] = useState<boolean>(false)
  const isReadinessProbeSidePanelOpen = propIsReadinessProbeSidePanelOpen ?? isLocalReadinessProbeSidePanelOpen
  const setReadinessProbeSidePanelOpen = propSetReadinessProbeSidePanelOpen ?? setLocalReadinessProbeSidePanelOpen
  const [isCommandSidePanelModalShowing, setIsCommandSidePanelShown] = useState(false)
  const [isHeadersSidePanelModalShowing, setIsHeadersSidePanelShown] = useState(false)
  const defaultProtocol = ProtocolOptions.TCP
  const [localProtocol, setLocalProtocol] = useState<ProtocolOptions>(defaultProtocol)
  const intl = useIntl()

  const fields: string[] = [
    commandFieldName,
    commandArgumentsFieldName,
    enabledFieldName,
    failureThresholdFieldName,
    headersFieldName,
    initialDelaySecondsFieldName,
    pathFieldName,
    periodSecondsFieldName,
    portFieldName,
    protocolFieldName,
    serviceFieldName,
    successThresholdFieldName,
    timeoutSecondsFieldName,
  ]

  const isEnabled = useWatch({ control, name: enabledFieldName })
  const path = useWatch({ control, name: pathFieldName })
  const port = useWatch({ control, name: portFieldName })
  const protocol = useWatch({ control, name: protocolFieldName })
  const service = useWatch({ control, name: serviceFieldName })
  const { errors } = useFormState({ control })
  const commandFieldsValues = useWatch({ control, name: [commandFieldName, commandArgumentsFieldName] })
  const configuredHealthProbeCommand = commandFieldsValues[0]
    ? configureHealthProbeCommand(commandFieldsValues[0], commandFieldsValues[1])
    : undefined
  const headersFieldValues = useWatch({ control, name: headersFieldName })
  const configuredHealthProbeHeaders = configureHealthProbeHeaders(headersFieldValues)
  const hasErrors = checkIfHealthProbeFormHasErrors(errors, fields)

  const handleSetLocalProtocol = (protocol: ProtocolOptions) => {
    switch (protocol) {
      case ProtocolOptions.EXEC:
        clearErrors([pathFieldName, portFieldName, headersFieldName, serviceFieldName])
        break
      case ProtocolOptions.GRPC:
        clearErrors([commandFieldName, commandArgumentsFieldName, pathFieldName, portFieldName, headersFieldName])
        break
      case ProtocolOptions.HTTP1X:
        clearErrors([commandFieldName, commandArgumentsFieldName, portFieldName, serviceFieldName])
        break
      case ProtocolOptions.TCP:
        clearErrors([commandFieldName, commandArgumentsFieldName, pathFieldName, headersFieldName, serviceFieldName])
        break
    }

    setLocalProtocol(protocol)
  }

  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(fields).then((isValid) => {
      if (isValid) {
        setReadinessProbeSidePanelOpen(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        isDisabled={isDisabled}
        hasError={hasErrors}
        helperText={helperText}
        onEditForm={() => setReadinessProbeSidePanelOpen(true)}
        title={intl.formatMessage(HealthProbeFieldsMessages.title, {
          health_probe_type: healthProbe,
        })}
      >
        <HealthProbeDetails
          command={configuredHealthProbeCommand}
          failureThreshold={null}
          headers={configuredHealthProbeHeaders}
          initialDelaySeconds={null}
          isEnabled={!isDisabled && isEnabled}
          path={path}
          periodSeconds={null}
          port={port}
          protocol={protocol}
          service={service}
          successThreshold={null}
          timeoutSeconds={null}
        />
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <div className="w-full">
            <Button variant="green-filled" form={formID} isFullWidth type="submit">
              {intl.formatMessage(HealthProbeFieldsMessages.configureButtonLabel)}
            </Button>
            {isEnabled && (
              <p className={classNames('mt-2 h-16 text-sm')}>
                {intl.formatMessage(HealthProbeFieldsMessages.configureButtonHelperText, {
                  calculated_time: calculatedTime,
                })}
              </p>
            )}
          </div>
        }
        isShown={isReadinessProbeSidePanelOpen}
        onClose={() => setReadinessProbeSidePanelOpen(false)}
        title={intl.formatMessage(HealthProbeFieldsMessages.title, {
          health_probe_type: healthProbe,
        })}
      >
        <form onSubmit={handleValidateFieldsBeforeClose} id={formID}>
          <div className="my-12 px-10 pb-12">
            <div className="mb-10">
              <h2 className="text-3xl font-bold">
                {intl.formatMessage(HealthProbeFieldsMessages.title, {
                  health_probe_type: healthProbe,
                })}
              </h2>
              {subtitle && <p className="mt-2">{subtitle}</p>}
            </div>
            <div className="relative mb-10">
              <Controller
                name={enabledFieldName}
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...fieldState}
                      id={`${healthProbe}-probe-enabled-checkbox`}
                      isChecked={field.value as boolean}
                      onChange={(value) => {
                        if (value === true) {
                          setValue(protocolFieldName, localProtocol ?? defaultProtocol)
                        } else {
                          clearErrors(fields)
                        }
                        field.onChange(value)
                      }}
                      label={intl.formatMessage(HealthProbeFieldsMessages.enableProbeLabel, {
                        health_probe_type: healthProbe,
                      })}
                    />
                  )
                }}
              />
            </div>
            <div className="mb-10">
              <Controller
                name={protocolFieldName}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      {...fieldState}
                      defaultSelectedValue={field.value}
                      isFullWidth
                      labelText={intl.formatMessage(HealthProbeFieldsMessages.protocolLabel)}
                      onChange={(value) => {
                        field.onChange(value)
                        // This is needed to trigger the UI updating for the protocol field
                        handleSetLocalProtocol(value as ProtocolOptions)
                      }}
                      isDisabled={!isEnabled}
                      isPlaceholderOptionDisabled
                      options={[
                        {
                          label: ProtocolOptions.TCP,
                          value: ProtocolOptions.TCP,
                        },
                        {
                          label: ProtocolOptions.EXEC,
                          value: ProtocolOptions.EXEC,
                        },
                        {
                          label: ProtocolOptions.GRPC,
                          value: ProtocolOptions.GRPC,
                        },
                        {
                          label: ProtocolOptions.HTTP1X,
                          value: ProtocolOptions.HTTP1X,
                        },
                      ]}
                    />
                  )
                }}
              />
            </div>

            {protocol === ProtocolOptions.HTTP1X && (
              <div className="mb-10">
                <HealthProbePathInput
                  control={control}
                  hasError={errors[pathFieldName] !== undefined}
                  isDisabled={!isEnabled}
                  name={pathFieldName}
                />
              </div>
            )}

            {protocol === ProtocolOptions.EXEC && (
              <div className="mb-10">
                <FormStateCard
                  hasError={errors[commandFieldName] !== undefined || errors[commandArgumentsFieldName] !== undefined}
                  helperText={intl.formatMessage(HealthProbeFieldsMessages.commandHelperText, {
                    read_documentation_link: (
                      <Link url="https://docs.salad.com/products/sce/health-probes#probe-configuration">
                        {intl.formatMessage(HealthProbeFieldsMessages.commandReadDocumentationLinkText)}
                      </Link>
                    ),
                  })}
                  isDisabled={!isEnabled}
                  onEditForm={() => setIsCommandSidePanelShown(true)}
                  title={intl.formatMessage(HealthProbeFieldsMessages.commandLabel)}
                >
                  <div>
                    {configuredHealthProbeCommand ? (
                      <p
                        className="cursor-pointer truncate text-blue-90 underline"
                        onClick={() => setIsCommandSidePanelShown(true)}
                      >
                        {configuredHealthProbeCommand}
                      </p>
                    ) : (
                      <p>{intl.formatMessage(HealthProbeFieldsMessages.commandPlaceholder)}</p>
                    )}
                  </div>
                </FormStateCard>
              </div>
            )}

            {protocol === ProtocolOptions.GRPC && (
              <div className="mb-10">
                <HealthProbeServiceInput
                  control={control}
                  hasError={errors[serviceFieldName] !== undefined}
                  isDisabled={!isEnabled}
                  name={serviceFieldName}
                />
              </div>
            )}

            {protocol !== ProtocolOptions.EXEC && (
              <div className="mb-10">
                <HealthProbePortInput
                  control={control}
                  defaultValue={protocol === ProtocolOptions.HTTP1X ? 80 : 1}
                  hasError={errors[portFieldName] !== undefined}
                  isDisabled={!isEnabled}
                  name={portFieldName}
                />
              </div>
            )}

            {protocol === ProtocolOptions.HTTP1X && (
              <div className="mb-10">
                <FormStateCard
                  hasError={errors[headersFieldName] !== undefined}
                  isDisabled={!isEnabled}
                  onEditForm={() => setIsHeadersSidePanelShown(true)}
                  title={intl.formatMessage(HealthProbeFieldsMessages.headersLabel)}
                >
                  <div>
                    {configuredHealthProbeHeaders ? (
                      <p
                        className="cursor-pointer truncate text-blue-90 underline"
                        onClick={() => setIsHeadersSidePanelShown(true)}
                      >
                        {configuredHealthProbeHeaders}
                      </p>
                    ) : (
                      <p>{intl.formatMessage(HealthProbeFieldsMessages.headersPlaceholder)}</p>
                    )}
                  </div>
                </FormStateCard>
              </div>
            )}

            <HealthProbesInitialDelaySecondsInput
              control={control}
              hasError={errors[initialDelaySecondsFieldName] !== undefined}
              isDisabled={!isEnabled}
              name={initialDelaySecondsFieldName}
            />

            <HealthProbesPeriodSecondsInput
              control={control}
              hasError={errors[periodSecondsFieldName] !== undefined}
              isDisabled={!isEnabled}
              name={periodSecondsFieldName}
            />

            <HealthProbeTimeoutSecondsInput
              control={control}
              hasError={errors[timeoutSecondsFieldName] !== undefined}
              isDisabled={!isEnabled}
              name={timeoutSecondsFieldName}
            />

            <HealthProbeSuccessThresholdInput
              control={control}
              hasError={errors[successThresholdFieldName] !== undefined}
              isDisabled={!isEnabled}
              name={successThresholdFieldName}
            />

            <HealthProbeFailureThresholdInput
              control={control}
              hasError={errors[failureThresholdFieldName] !== undefined}
              isDisabled={!isEnabled}
              name={failureThresholdFieldName}
            />
          </div>
        </form>
      </SidePanelModal>

      <HealthProbeCommandSidePanelModal
        argumentFieldName={commandArgumentsFieldName}
        clearErrors={clearErrors}
        commandFieldName={commandFieldName}
        control={control}
        formID={formID}
        healthProbe={healthProbe}
        isShown={isCommandSidePanelModalShowing}
        onClose={() => setIsCommandSidePanelShown(false)}
        trigger={trigger}
      />

      <HealthProbeHeadersSidePanelModal
        control={control}
        formID={formID}
        headerFieldName={headersFieldName}
        healthProbe={healthProbe}
        isShown={isHeadersSidePanelModalShowing}
        onClose={() => setIsHeadersSidePanelShown(false)}
        trigger={trigger}
      />
    </div>
  )
}
