import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Project } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface ProjectsProps {
  organizationName: string
  projects: Project[]
}

export const projectsAdapter = createEntityAdapter<ProjectsProps, string>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: projectsAdapter.getInitialState(),
  reducers: {
    deleteProject(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    getOrganizationWithProjects(_state, _action: PayloadAction<{ organizationName: string }>) {},
    projectsAddedToOrganization: projectsAdapter.upsertOne,
    projectsReceived(state, action: PayloadAction<{ projects: ProjectsProps[] }>) {
      projectsAdapter.setAll(state, action.payload.projects)
    },
  },
})

export const { deleteProject, getOrganizationWithProjects, projectsAddedToOrganization, projectsReceived } =
  projectsSlice.actions
