import type { FunctionComponent } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { HealthProbeFieldsMessages } from '../../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../../models'
import { CreateContainerGroupField } from '../../../models'
import { HealthProbeFields } from '../components'

interface LivenessProbeProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: UseFormSetValue<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const LivenessProbeFields: FunctionComponent<LivenessProbeProps> = ({
  clearErrors,
  control,
  id,
  setValue,
  trigger,
}) => {
  const intl = useIntl()
  const subtitle = intl.formatMessage(HealthProbeFieldsMessages.livenessSubtitle, {
    learn_more_link_text: (
      <a
        href="https://docs.salad.com/products/sce/health-probes/liveness-probes"
        rel="noreferrer"
        target="_blank"
        className="text-blue-90 underline"
      >
        {intl.formatMessage(HealthProbeFieldsMessages.learMoreLinkText)}
      </a>
    ),
  })
  return (
    <HealthProbeFields
      clearErrors={clearErrors}
      commandFieldName={CreateContainerGroupField.LIVENESS_PROBE_COMMAND}
      commandArgumentsFieldName={CreateContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS}
      control={control}
      enabledFieldName={CreateContainerGroupField.LIVENESS_PROBE_ENABLED}
      failureThresholdFieldName={CreateContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD}
      formID="livenessProbeFieldsForm"
      headersFieldName={CreateContainerGroupField.LIVENESS_PROBE_HEADERS}
      healthProbe="Liveness"
      id={id}
      initialDelaySecondsFieldName={CreateContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS}
      pathFieldName={CreateContainerGroupField.LIVENESS_PROBE_PATH}
      periodSecondsFieldName={CreateContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS}
      portFieldName={CreateContainerGroupField.LIVENESS_PROBE_PORT}
      protocolFieldName={CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL}
      serviceFieldName={CreateContainerGroupField.LIVENESS_PROBE_SERVICE}
      setValue={setValue}
      subtitle={subtitle}
      successThresholdFieldName={CreateContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD}
      timeoutSecondsFieldName={CreateContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS}
      trigger={trigger}
    />
  )
}
