import { createAction } from '@reduxjs/toolkit'
import { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { DateTime } from 'luxon'
import { EMPTY, filter, from, ignoreElements, map, Observable, retry, switchMap, takeUntil, tap, timer } from 'rxjs'
import { HelpScoutIntegrationAPI } from '../apiMethods'
import { HELP_SCOUT_BEACON_ID } from '../config'
import { logout } from '../features/logout/logoutSlice'
import { setProfile } from '../features/profile/profileSlice'
import { router } from '../routes/Routes'
import type { AppEpic } from '../store'

export const openBeacon = createAction('support/open-beacon')

let previousRoute = ''

export const onStartup: AppEpic = (_action$, _state$) => {
  // This dynamically injects the Help Scout beacon script if and only if the HELP_SCOUT_BEACON_ID is defined (and has
  // not already been injected).
  installBeacon()
  // This triggers status check to finish initialization on unauthenticated routes.
  window.Beacon('info')

  // This subscribes to route changes and updates the beacon-tracked breadcrumbs and documentation suggestions.
  return new Observable<any>((subscriber) =>
    router.subscribe((routerState) => {
      if (routerState.location.pathname !== previousRoute) {
        previousRoute = routerState.location.pathname
        subscriber.next(routerState)
      }
    }),
  ).pipe(
    tap({
      next: () => {
        const beacon = window.Beacon
        if (beacon !== undefined) {
          beacon('event', {
            type: 'page-viewed',
            url: document.location.href,
            title: document.title || 'SaladCloud Portal',
          })
          beacon('suggest')
        }
      },
    }),
    ignoreElements(),
  )
}

export const onAuthenticated: AppEpic = (action$) =>
  action$.pipe(
    filter(setProfile.match),
    switchMap((action) => {
      if (typeof HELP_SCOUT_BEACON_ID !== 'string' || !(HELP_SCOUT_BEACON_ID.length > 0)) {
        return EMPTY
      }

      return from(
        HelpScoutIntegrationAPI.getHelpScoutSignature({
          createHelpScoutSignature: {
            beaconId: HELP_SCOUT_BEACON_ID,
          },
        }),
      ).pipe(
        map((response) => ({
          avatar: action.payload.profile.gravatarUrl,
          email: action.payload.profile.email,
          signature: response.signature,
          'create-date': DateTime.fromJSDate(action.payload.profile.createTime, { zone: 'utc' })
            .setZone('America/Denver')
            .toISODate()!,
          'saladcloud-user-id': action.payload.profile.id,
        })),
        retry({
          delay: (error, retryCount) => {
            if (error instanceof ResponseError && error.response.status > 400 && error.response.status <= 499) {
              return EMPTY
            }

            return timer(Math.min(Math.pow(2, retryCount) * 1000 + Math.floor(Math.random() * 1000), 30000))
          },
        }),
        takeUntil(action$.pipe(filter(logout.match))),
      )
    }),
    tap({
      next: (options) => {
        const beacon = window.Beacon
        if (beacon !== undefined) {
          beacon('identify', options)
        }
      },
    }),
    ignoreElements(),
  )

export const onLogout: AppEpic = (action$) =>
  action$.pipe(
    filter(logout.match),
    tap({
      next: () => {
        const beacon = window.Beacon
        if (beacon !== undefined) {
          beacon('close')
          beacon('reset')
          beacon('logout', { endActiveChat: true })
        }
      },
    }),
    ignoreElements(),
  )

export const onOpenBeacon: AppEpic = (action$) =>
  action$.pipe(
    filter(openBeacon.match),
    tap({
      next: () => {
        const beacon = window.Beacon
        if (beacon !== undefined) {
          beacon('open')
        }
      },
    }),
    ignoreElements(),
  )

let installed = false
function installBeacon() {
  if (typeof HELP_SCOUT_BEACON_ID !== 'string' || !(HELP_SCOUT_BEACON_ID.length > 0)) {
    return
  }

  if (installed) {
    return
  }

  installed = true

  /* eslint-disable @typescript-eslint/no-unused-expressions -- Below is the official Help Scout snippet for ease of maintenance. */
  /* eslint-disable no-sequences -- Below is the official Help Scout snippet for ease of maintenance. */
  /* prettier-ignore */
  /* @ts-ignore */
  !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  /* prettier-ignore */
  /* @ts-ignore */
  window.Beacon('init', HELP_SCOUT_BEACON_ID);
  /* eslint-enable @typescript-eslint/no-unused-expressions -- Above is the official Help Scout snippet for ease of maintenance. */
  /* eslint-enable no-sequences -- Above is the official Help Scout snippet for ease of maintenance. */
}
