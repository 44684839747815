import { defineMessages } from 'react-intl'

export const EditJobQueueFieldsMessages = defineMessages({
  autoscalingEnabledLabel: {
    defaultMessage: 'Enable Autoscaling',
    id: 'FaxI8b1b',
    description: 'The label for the job queue autoscaler enabled checkbox.',
  },
  createJobQueueVButtonLabel: {
    defaultMessage: 'Create New Job Queue',
    id: 'QByn7nPX',
    description: 'The label for the button that takes the user to create a new job queue.',
  },
  description: {
    defaultMessage: 'Allows you to send requests (jobs) to a static endpoint. {documentation_link}.',
    id: 'YghcVEn0',
    description: 'The description for the container group job queue fields.',
  },
  jobQueueDisabledLabel: {
    defaultMessage: 'Job Queue Disabled',
    id: 'x3kvvy13',
    description: 'The label for the job queue disabled state.',
  },
  jobQueuesDisabledContainerGatewayEnabledLabel: {
    defaultMessage: 'Job Queues are always disabled when Container Gateway is enabled',
    id: '+8HHzTMT',
    description: 'The label for the job queue disabled state when Container Gateway is enabled.',
  },
  learnMoreHereLinkText: {
    defaultMessage: 'Learn more here',
    id: '19F7k3Jx',
    description: 'The description documentation link text for the container group job queue fields.',
  },
  pathHelperText: {
    defaultMessage: 'Must be a valid path. Should not begin with /',
    id: 'rxjWxbfw',
    description: 'The helper text for the job queue path input field.',
  },
  pathLabel: {
    defaultMessage: 'Path',
    id: 'kp+dn0mV',
    description: 'The label for the job queue path input field.',
  },
  pathPlaceholderText: {
    defaultMessage: 'path',
    id: 'zaqn2ZIs',
    description: 'The placeholder text for the job queue path input field.',
  },
  portHelperText: {
    defaultMessage: 'Must be a number between 1 to 65535',
    id: 'Yzlngmev',
    description: 'The helper text for the job queue port input field.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'h5UTRfnZ',
    description: 'The label for the job queue port input field.',
  },
  sectionTitle: {
    defaultMessage: 'Job Queues',
    id: 'gYY9+X1E',
    description:
      'The title for the dialog box that opens when a user wants to edit the job queue fields on the create container group page.',
  },
  selectJobQueueLabel: {
    defaultMessage: 'Select a Job Queue',
    id: '/FlH0jp5',
    description: 'The label for the job queue select field.',
  },
  selectJobQueuePlaceholderLabel: {
    defaultMessage: 'Please select an option',
    id: 'OrLl0odE',
    description: 'The placeholder label for the job queue select field.',
  },
})
