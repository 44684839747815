import { combineEpics } from 'redux-observable'
import {
  onTrackMixpanelElementClickedEvent,
  onTrackMixpanelEvent,
  onTrackMixpanelPageViewEvent,
} from './analyticsEpics'
import { onGetApiKey, onUpdateApiKey } from './apiKeyEpics'
import { onAppStartup } from './appStartupEpics'
import {
  onGetBillingCreditsDashboardEmbedUrl,
  onGetInvoiceDashboardEmbedUrl,
  onGetUsageDashboardEmbedUrl,
} from './billingDashboardsEpics'
import {
  onCreatePaymentMethodSetupIntent,
  onFetchPaymentMethod,
  onGetBillingCustomerPortal,
  onGetBillingPageData,
  onGetPaymentMethodSetupStatus,
  onGetPaymentMethodSetupStatusAfterProcessingState,
  onRemovePaymentMethod,
  onStripeFormError,
} from './billingEpics'
import { onChangePassword } from './changePasswordEpics'
import { onGetContainerGroupAndInstances, onGetContainerGroupLogs } from './containerGroupDetailsEpics'
import { onGetContainerGroupInstanceDetailsPageData } from './containerGroupInstanceDetailPageDataEpics'
import {
  onReallocateContainerGroupInstance,
  onRecreateContainerGroupInstance,
  onRestartContainerGroupInstance,
} from './containerGroupInstanceEpics'
import {
  onDeleteContainerGroup,
  onGetContainerGroupDetailsPageData,
  onGetContainerGroupInstances,
  onStartContainerGroup,
  onStopContainerGroup,
} from './containerGroupsEpics'
import { onGetContainerGroupsPageData, onPollContainerGroups } from './containerGroupsPageDataEpics'
import {
  onCreateContainerGroup,
  onGetCreateContainerGroupWizardData,
  onShowRequiredActionModalForCreateContainerGroupPage,
} from './createContainerGroupEpics'
import { onCreateJobQueue } from './createJobQueueEpics'
import {
  onCreateNewOrganizationOfCreateNewOrganizationAndProject,
  onCreateNewProjectOfCreateNewOrganizationAndProject,
} from './createOrganizationAndProjectEpics'
import {
  onCheckOrganizationNameAvailability,
  onCreateOrganizationWizard,
  onEditCreateOrganizationWizard,
} from './createOrganizationWizardEpics'
import { onDeleteAccount } from './deleteAccountEpics'
import { onDeleteJobQueue } from './deleteJobQueueEpics'
import { onEditContainerGroup, onGetEditContainerGroupPageData } from './editContainerGroupEpics'
import { onEditJobQueue, onGetEditJobQueuePageData } from './editJobQueueEpics'
import { onEditOrganizationDisplayName } from './editOrganizationEpics'
import { onGetInferenceEndpointDetailsPageData } from './inferenceEndpointDetailsEpics'
import { onCreateInferenceEndpointJob, onPollInferenceEndpointJob } from './inferenceEndpointJobsEpics'
import { onGetInferenceEndpointMarketplacePageData } from './inferenceEndpointsMarketplaceEpics'
import { onAcceptInvitation, onDeclineInvitation } from './invitationsEpics'
import { onGetInviteTeamMemberPageData, onInviteTeamMember } from './inviteTeamMemberEpics'
import { onGetJobQueueDetailsPageData } from './jobQueueDetailsEpics'
import { onGetJobQueues, onGetJobQueuesPageData } from './jobQueuesEpics'
import { onLogin } from './loginEpics'
import { onLogout } from './logoutEpics'
import { onNavigate } from './navigationEpic'
import { onShowToastNotification } from './notificationEpics'
import { onGetNovuSignature } from './novuEpics'
import { onDeleteOrganization, onGetOrganization } from './organizationEpics'
import { onGetOrganizationsAndOrganizationInvitations } from './organizationsEpics'
import { onCreateNewProject, onEditProject, onGetProjectToEdit } from './projectEpics'
import { onDeleteProject, onGetEditProjectsPageData, onGetOrganizationWithProjects } from './projectsEpics'
import { onConfirmRegistration, onRegisterAccount, onResendRegistrationEmail } from './registrationEpics'
import { onConfirmPasswordReset, onSendResetPasswordEmail } from './resetPasswordEpics'
import { onAuthenticated, onLogout as onLogoutForHelpScout, onOpenBeacon, onStartup } from './supportEpics'
import {
  onGetOrganizationWithMembersAndInvitations,
  onRemoveMemberFromOrganization,
  onResendInvitation,
  onRevokeInvitation,
} from './teamEpics'
import { onFetchTerminalToken } from './terminalEpics'
import { onGetWebhookSecretKey, onUpdateWebhookSecretKey } from './webhookSecretKeyEpics'

export const rootEpic = combineEpics(
  onAcceptInvitation,
  onAppStartup,
  onAuthenticated,
  onChangePassword,
  onCheckOrganizationNameAvailability,
  onConfirmPasswordReset,
  onConfirmRegistration,
  onCreateContainerGroup,
  onCreateInferenceEndpointJob,
  onCreateJobQueue,
  onCreateNewOrganizationOfCreateNewOrganizationAndProject,
  onCreateNewProject,
  onCreateNewProjectOfCreateNewOrganizationAndProject,
  onCreateOrganizationWizard,
  onCreatePaymentMethodSetupIntent,
  onDeclineInvitation,
  onDeleteAccount,
  onDeleteContainerGroup,
  onDeleteJobQueue,
  onDeleteOrganization,
  onDeleteProject,
  onEditContainerGroup,
  onEditCreateOrganizationWizard,
  onEditJobQueue,
  onEditOrganizationDisplayName,
  onEditProject,
  onFetchPaymentMethod,
  onFetchTerminalToken,
  onGetApiKey,
  onGetBillingCreditsDashboardEmbedUrl,
  onGetBillingCustomerPortal,
  onGetBillingPageData,
  onGetContainerGroupAndInstances,
  onGetContainerGroupDetailsPageData,
  onGetContainerGroupInstanceDetailsPageData,
  onGetContainerGroupInstances,
  onGetContainerGroupLogs,
  onGetContainerGroupsPageData,
  onGetCreateContainerGroupWizardData,
  onGetEditContainerGroupPageData,
  onGetEditJobQueuePageData,
  onGetEditProjectsPageData,
  onGetInferenceEndpointDetailsPageData,
  onGetInferenceEndpointMarketplacePageData,
  onGetInviteTeamMemberPageData,
  onGetInvoiceDashboardEmbedUrl,
  onGetJobQueueDetailsPageData,
  onGetJobQueues,
  onGetJobQueuesPageData,
  onGetNovuSignature,
  onGetOrganization,
  onGetOrganizationWithMembersAndInvitations,
  onGetOrganizationWithProjects,
  onGetOrganizationsAndOrganizationInvitations,
  onGetPaymentMethodSetupStatus,
  onGetPaymentMethodSetupStatusAfterProcessingState,
  onGetProjectToEdit,
  onGetUsageDashboardEmbedUrl,
  onGetWebhookSecretKey,
  onInviteTeamMember,
  onLogin,
  onLogout,
  onLogoutForHelpScout,
  onNavigate,
  onOpenBeacon,
  onPollContainerGroups,
  onPollInferenceEndpointJob,
  onReallocateContainerGroupInstance,
  onRecreateContainerGroupInstance,
  onRegisterAccount,
  onRemoveMemberFromOrganization,
  onRemovePaymentMethod,
  onResendInvitation,
  onResendRegistrationEmail,
  onRestartContainerGroupInstance,
  onRevokeInvitation,
  onSendResetPasswordEmail,
  onShowRequiredActionModalForCreateContainerGroupPage,
  onShowToastNotification,
  onStartContainerGroup,
  onStartup,
  onStopContainerGroup,
  onStripeFormError,
  onTrackMixpanelElementClickedEvent,
  onTrackMixpanelEvent,
  onTrackMixpanelPageViewEvent,
  onUpdateApiKey,
  onUpdateWebhookSecretKey,
)
