import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  ContainerGroupLogsItemsInner,
  QueryContainerGroupLogsRequest,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { configuresContainerGroupLogsEntityId } from './utils'

export interface ContainerGroupLogsProps {
  containerGroupName: string
  organizationName: string
  projectName: string
  containerGroupLogs: ContainerGroupLogsItemsInner[]
}

export const containerGroupLogsAdapter = createEntityAdapter<ContainerGroupLogsProps, string>({
  selectId: (item) =>
    configuresContainerGroupLogsEntityId(item.containerGroupName, item.organizationName, item.projectName),
  sortComparer: (a, b) => a.containerGroupName.localeCompare(b.containerGroupName),
})

export const containerGroupLogsSlice = createSlice({
  name: 'containerGroupLogsDetails',
  initialState: containerGroupLogsAdapter.getInitialState(),
  reducers: {
    getContainerGroupLogs(_state, _action: PayloadAction<QueryContainerGroupLogsRequest>) {},
    containerGroupLogsAdded: containerGroupLogsAdapter.upsertOne,
  },
})

export const { containerGroupLogsAdded, getContainerGroupLogs } = containerGroupLogsSlice.actions
