import type { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { authenticationPageRoutePath } from '../../routes/routePaths'
import { ResetPasswordSucceeded } from './components/ResetPasswordSucceeded'

export const ConnectedResetPasswordSucceeded: FunctionComponent = () => {
  const navigate = useNavigate()

  return <ResetPasswordSucceeded onViewLoginPage={() => navigate(authenticationPageRoutePath)} />
}
