import type { IntlShape } from 'react-intl'
import { Link } from '../../components/base'
import { ContainerGroupDetailsMainContentMessages } from './components/ContainerGroupDetailsMainContent/ContainerGroupDetailsMainContentMessages'
import { ContainerGroupLogsFormValues } from './models'

/**
 * A function that generates the `Failed to Pull Image` message which details why the container group failed along with
 * a link for more documentation.
 *
 * @param intl The intl object used to format and configure messages.
 */
export const getFailedToPullImageMessage = (intl: IntlShape) => {
  return intl.formatMessage(ContainerGroupDetailsMainContentMessages.failedToPullImageDescription, {
    learn_more_here_link: (
      <Link url="https://docs.salad.com/products/sce/container-registries#specifying-the-container-image-source">
        {intl.formatMessage(ContainerGroupDetailsMainContentMessages.learnMoreHereLinkText)}
      </Link>
    ),
  })
}

/**
 * A function that determines if the container group failed to pull the image based on the description provided.
 *
 * @param description The container group description pulled from the `currentState` object.
 */
export const detectFailedToPullImageDescription = (description: string | undefined | null) => {
  return description?.toUpperCase() === 'FAILED TO PULL IMAGE'
}

/**
 * A function that configures the default values for the container log form. Defaults to one hour
 *
 * @param newDefaults The new default values to be applied.
 * @returns The configured container log form values.
 */
export const configureContainerGroupLogDefaultValues = (
  newDefaults: Partial<ContainerGroupLogsFormValues> = {},
): ContainerGroupLogsFormValues => {
  const oneHourInMilliseconds = 60 * 60 * 1000
  return {
    filterBy: '',
    machineId: '',
    dateRange: {
      startDate: new Date(Date.now() - oneHourInMilliseconds),
      endDate: new Date(),
      ...newDefaults.dateRange,
    },
    ...newDefaults,
  }
}
