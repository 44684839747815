import { useCallback, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { matchRoutes, useLocation, useParams } from 'react-router'
import { trackMixpanelElementClickedEvent, trackMixpanelEvent } from '../../../features/analytics/analyticsSlice'
import { setIsHelpScoutBeaconFailedToOpenModalOpen } from '../../../features/helpScout/helpScoutSlice'
import { routes } from '../../../routes/Routes'
import { contactSupportViaHelpScoutAnswers } from '../../../utils/helpScout'
import { Button } from '../../Button'
import { HelpScoutSupportButtonMessages } from './messages'

export interface HelpScoutSupportButtonProps {
  /** An optional text passed down to mixpanel event on success helpscout trigger. */
  eventLabel?: string
  /** An optional text passed down to mixpanel event on helpscout failed initialization. */
  failedEventLabel?: string
}

export const HelpScoutSupportButton: FunctionComponent<HelpScoutSupportButtonProps> = (props) => {
  let isStorybook = false
  if (window.location.port === '6006' || window.location.host === 'preview-portal-ui.salad.com') {
    isStorybook = true
  }

  return <>{isStorybook ? <StorybookHelpScoutSupportButton {...props} /> : <AppHelpScoutSupportButton {...props} />}</>
}

const StorybookHelpScoutSupportButton: FunctionComponent<HelpScoutSupportButtonProps> = () => {
  const intl = useIntl()

  return (
    <Button isFullWidth variant="green-outlined">
      {intl.formatMessage(HelpScoutSupportButtonMessages.helpScoutSupportContactSupportButtonLabel)}
    </Button>
  )
}

const AppHelpScoutSupportButton: FunctionComponent<HelpScoutSupportButtonProps> = ({
  eventLabel,
  failedEventLabel,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const matchedRoutes = matchRoutes(routes, location)
  const currentRouteId = matchedRoutes && matchedRoutes.length > 0 ? matchedRoutes[0]?.route.id : undefined
  const { containerGroupName, inferenceEndpointName, jobQueueName, organizationName, projectName } = useParams()

  const setIsHelpScoutBeaconFailedToLoadModalOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsHelpScoutBeaconFailedToOpenModalOpen({ isOpen }))
    },
    [dispatch],
  )

  const handleHelpScoutFailedToOpenMixpanelEventTrack = useCallback(() => {
    currentRouteId &&
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label:
              failedEventLabel ??
              HelpScoutSupportButtonMessages.helpScoutWidgetFailedViaContactSupportMixpanelEventLabel.defaultMessage,
            path: currentRouteId,
            organizationName,
            projectName,
            resourceName: containerGroupName,
            jobQueueName,
            inferenceEndpointName,
          },
        }),
      )
  }, [
    failedEventLabel,
    containerGroupName,
    dispatch,
    jobQueueName,
    organizationName,
    currentRouteId,
    projectName,
    inferenceEndpointName,
  ])

  const handleTrackMixpanelClickEventTrack = useCallback(() => {
    currentRouteId &&
      dispatch(
        trackMixpanelElementClickedEvent({
          label:
            eventLabel ?? HelpScoutSupportButtonMessages.helpScoutSupportButtonClickedMixpanelEventLabel.defaultMessage,
          path: currentRouteId,
          organizationName,
          projectName,
          resourceName: containerGroupName,
          jobQueueName,
        }),
      )
  }, [eventLabel, containerGroupName, currentRouteId, dispatch, jobQueueName, organizationName, projectName])

  const handleContactSupportClick = () => {
    contactSupportViaHelpScoutAnswers(
      setIsHelpScoutBeaconFailedToLoadModalOpen,
      currentRouteId,
      handleHelpScoutFailedToOpenMixpanelEventTrack,
      handleTrackMixpanelClickEventTrack,
    )
  }

  return (
    <Button isFullWidth variant="green-outlined" onClick={handleContactSupportClick}>
      {intl.formatMessage(HelpScoutSupportButtonMessages.helpScoutSupportContactSupportButtonLabel)}
    </Button>
  )
}
