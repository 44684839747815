import type { FunctionComponent, ReactNode } from 'react'
import { SaladLogo } from '../../components/SaladLogo'
import { TriangleBackgroundImages, ValuePropositionList } from './components'

export interface ValuePropositionFormLayoutProps {
  /** The form component that is displayed to the right of the value propositions. */
  Form: ReactNode
}

/**
 * A layout component that takes a form and renders it to the right of SaladCloud's value propositions. These value
 * propositions are hidden on smaller views.
 */
export const ValuePropositionFormLayout: FunctionComponent<ValuePropositionFormLayoutProps> = ({ Form }) => (
  <main className="relative h-screen w-screen overflow-auto bg-neutral-100">
    <TriangleBackgroundImages />

    <div className="relative flex items-center justify-center sm:size-full">
      <div className="flex items-center justify-center gap-16 md:gap-24">
        <div className="hidden sm:block">
          <SaladLogo className="mb-12 w-52 pl-8" height="100%" width={208} />
          <ValuePropositionList />
        </div>
        <div className="max-sm:flex max-sm:flex-col max-sm:items-center">
          <SaladLogo className="mb-6 mt-16 w-52 sm:hidden" height="100%" width={208} />
          {Form}
        </div>
      </div>
    </div>
  </main>
)
