import { defineMessages } from 'react-intl'

export const turnstileGenericErrorMessages = defineMessages({
  bodyText: {
    defaultMessage:
      'Verification failed. Please try again or {contact_support}. (Cloudflare Turnstile Error {error_code})',
    description:
      'The body text for the Turnstile widget error toast notification, including the error code and contact support link.',
    id: 'TxnlAdIo',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    description: 'The text shown for the contact support link in the generic error toast notification.',
    id: 'IeDKbSCs',
  },
  turnstileErrorTitle: {
    defaultMessage: 'Turnstile Widget Error',
    description: 'The title for the Turnstile widget error toast notification.',
    id: 'cVGO5JPE',
  },
})
