import { ContainerGroupLogsItemsInner, ContainerGroupLogsQuery } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../../components/Button'
import { Link } from '../../../../../components/base'
import { Table } from '../../../../../components/block/Table'
import { TableColumnWidth, TableSortingOrder } from '../../../../../components/block/Table/models'
import { FeaturePreviewPill } from '../../../../../components/featurePreview/FeaturePreviewPill'
import { DatePickerRange } from '../../../../../components/forms/DatePickerRange'
import { SearchInput } from '../../../../../components/search'
import { useFixedForm } from '../../../../../hooks'
import { formatToDateTime } from '../../../../../utils'
import type { ContainerGroupLogsFormValues } from '../../../models'
import { ContainerGroupLogsField } from '../../../models'
import { configureContainerGroupLogDefaultValues } from '../../../utils'
import { ContainerGroupLogsMessages } from './ContainerGroupLogsMessages'

interface ContainerGroupLogsProps {
  /** The container logs array of items */
  containerGroupLogs: ContainerGroupLogsItemsInner[]
  /** The initial form values to be passed in */
  initialFormValues?: Partial<ContainerGroupLogsFormValues>
  /** The status of the container group logs request if pending */
  isGetContainerGroupLogsPending: boolean
  /** The function to query container group logs */
  onQueryContainerGroupLogs: (containerGroupLogsQuery: ContainerGroupLogsQuery) => void
}

export const ContainerGroupLogs: FunctionComponent<ContainerGroupLogsProps> = ({
  containerGroupLogs,
  initialFormValues,
  isGetContainerGroupLogsPending,
  onQueryContainerGroupLogs,
}) => {
  const intl = useIntl()
  const defaultFormValues = useMemo(
    () => configureContainerGroupLogDefaultValues(initialFormValues),
    [initialFormValues],
  )
  const { control, handleSubmit } = useFixedForm<ContainerGroupLogsFormValues>({
    defaultValues: defaultFormValues,
    onSubmit: (data) => {
      queryContainerGroupLogs(data)
    },
  })

  const headers = [
    {
      key: ContainerGroupLogsMessages.messageLabel.id,
      label: intl.formatMessage(ContainerGroupLogsMessages.messageLabel),
      columnWidth: TableColumnWidth.Half,
    },
    {
      key: ContainerGroupLogsMessages.machineIdLabel.id,
      label: intl.formatMessage(ContainerGroupLogsMessages.machineIdLabel),
      columnWidth: TableColumnWidth.ThreeTwelfths,
    },
    {
      key: ContainerGroupLogsMessages.createTimeLabel.id,
      label: intl.formatMessage(ContainerGroupLogsMessages.createTimeLabel),
      initialSort: true,
      direction: TableSortingOrder.Ascending,
      columnWidth: TableColumnWidth.ThreeTwelfths,
    },
  ]

  const queryContainerGroupLogs = useCallback(
    (formData: ContainerGroupLogsFormValues) => {
      const { dateRange, filterBy, machineId } = formData
      const { endDate: endTime, startDate: startTime } = dateRange || { startDate: null, endDate: null }
      const queryContainerGroupLogs: ContainerGroupLogsQuery = {
        filterBy,
        machineId,
        startTime,
        endTime,
      }
      onQueryContainerGroupLogs(queryContainerGroupLogs)
    },
    [onQueryContainerGroupLogs],
  )

  const formattedContainerGroupLogs = useMemo(() => {
    return containerGroupLogs?.map((log) => ({
      [ContainerGroupLogsMessages.messageLabel.id]: {
        value: log.message,
      },
      [ContainerGroupLogsMessages.machineIdLabel.id]: {
        value: log.machineId,
      },
      [ContainerGroupLogsMessages.createTimeLabel.id]: {
        sortWith: new Date(log.createTime),
        value: formatToDateTime(intl, log.createTime as Date),
      },
    }))
  }, [containerGroupLogs, intl])

  useEffect(() => {
    queryContainerGroupLogs(defaultFormValues)
  }, [queryContainerGroupLogs, defaultFormValues])

  return (
    <>
      <div className="mt-4">
        <FeaturePreviewPill />
      </div>
      <div className="my-4">
        {intl.formatMessage(ContainerGroupLogsMessages.containerGroupLogsDescriptionText, {
          learn_more_link: (
            <Link url="https://docs.salad.com/products/sce/container-logs">
              {intl.formatMessage(ContainerGroupLogsMessages.learMoreLinkText)}
            </Link>
          ),
        })}
      </div>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 md:grid-cols-12">
        <div className="md:col-span-12">
          <Controller
            name={ContainerGroupLogsField.Filterby}
            control={control}
            render={({ field }) => (
              <SearchInput
                {...field}
                defaultValue={field.value}
                placeholder={intl.formatMessage(ContainerGroupLogsMessages.filterPlaceholder)}
                isFullWidth
              />
            )}
          />
        </div>
        <div className="md:col-span-4">
          <Controller
            name={ContainerGroupLogsField.MachineId}
            control={control}
            render={({ field }) => (
              <SearchInput
                {...field}
                defaultValue={field.value}
                placeholder={intl.formatMessage(ContainerGroupLogsMessages.filterByMachineIdPlaceholder)}
                isFullWidth
              />
            )}
          />
        </div>
        <div className="md:col-span-6">
          <Controller
            name={ContainerGroupLogsField.DateRange}
            control={control}
            render={({ field }) => {
              const { onChange, value } = field
              const { endDate, startDate } = value || {
                startDate: defaultFormValues.dateRange.startDate,
                endDate: defaultFormValues.dateRange.endDate,
              }

              return (
                <DatePickerRange
                  id="log-date-range"
                  isFullWidth
                  hasTimeSelect
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  onChange={(newStartDate, newEndDate) => onChange({ startDate: newStartDate, endDate: newEndDate })}
                />
              )
            }}
          />
        </div>
        <div className="md:col-span-2">
          <Button type="submit" isLoading={isGetContainerGroupLogsPending} isFullWidth>
            {intl.formatMessage(ContainerGroupLogsMessages.queryButtonLabel)}
          </Button>
        </div>
      </form>
      <div className="py-2">
        <Table isLoading={isGetContainerGroupLogsPending} items={formattedContainerGroupLogs} headers={headers} />
      </div>
    </>
  )
}
