import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { TimeFrameBasedEstimatedCostMessages } from '../messages'

interface TimeFrameBasedEstimatedCostProps {
  /** The cost per hour. */
  costPerHour: number
  /** Whether the cost is per replica. */
  disclaimer?: string
  /** The flag indicating if the cost has a range */
  hasCostRange?: boolean
}

type TimeFrame = 'hourly' | 'monthly'

export const TimeFrameBasedEstimatedCost: FunctionComponent<TimeFrameBasedEstimatedCostProps> = ({
  costPerHour,
  disclaimer,
  hasCostRange,
}) => {
  const intl = useIntl()
  const [timeFrame, setHourlyRate] = useState<TimeFrame>('hourly')
  const [estimatedCostBasedOnTimeFrame, setEstimatedCostBasedOnTimeFrame] = useState<number>(costPerHour)
  const updateCostPerHourBasedOnRate = (costPerHour: number, timeFrame: TimeFrame) => {
    if (timeFrame === 'hourly') {
      setEstimatedCostBasedOnTimeFrame(costPerHour)
    } else {
      setEstimatedCostBasedOnTimeFrame(costPerHour * 730.5)
    }
  }
  const handleSetHourlyRate = (timeFrame: TimeFrame) => {
    setHourlyRate(timeFrame)
    updateCostPerHourBasedOnRate(costPerHour, timeFrame)
  }
  useEffect(() => {
    updateCostPerHourBasedOnRate(costPerHour, timeFrame)
  }, [costPerHour, timeFrame])
  const formattedEstimatedCost = `${intl.formatNumber(estimatedCostBasedOnTimeFrame, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  })}`
  return (
    <div className="inline-flex flex-col items-end">
      <p className="text-3xl font-bold text-green-70">
        {hasCostRange && intl.formatMessage(TimeFrameBasedEstimatedCostMessages.upToPriceTag)}
        {timeFrame === 'hourly'
          ? intl.formatMessage(TimeFrameBasedEstimatedCostMessages.estimatedCostPerHour, {
              estimated_cost: formattedEstimatedCost,
            })
          : intl.formatMessage(TimeFrameBasedEstimatedCostMessages.estimatedCostPerMonth, {
              estimated_cost: formattedEstimatedCost,
            })}
      </p>
      {disclaimer && <p className="text-sm">{disclaimer}</p>}
      <div className="flex">
        <button
          className={classNames(
            'flex items-center justify-center rounded-sm border-b-2 border-neutral-40 px-4 py-2 text-base font-bold outline-none hover:border-blue-80 focus:border-blue-80',
            {
              'text-neutral-100': timeFrame !== 'hourly',
              'border-blue-80 font-bold text-blue-80': timeFrame === 'hourly',
            },
          )}
          onClick={() => handleSetHourlyRate('hourly')}
        >
          {intl.formatMessage(TimeFrameBasedEstimatedCostMessages.hourlyTabLabel)}
        </button>
        <button
          onClick={() => handleSetHourlyRate('monthly')}
          className={classNames(
            'flex items-center justify-center rounded-sm border-b-2 border-neutral-40 px-4 py-2 text-base font-bold outline-none hover:border-blue-80 focus:border-blue-80',
            {
              'text-neutral-100': timeFrame !== 'monthly',
              'border-blue-80 font-bold text-blue-80': timeFrame === 'monthly',
            },
          )}
        >
          {intl.formatMessage(TimeFrameBasedEstimatedCostMessages.monthlyTabLabel)}
        </button>
      </div>
    </div>
  )
}
