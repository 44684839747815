import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjects } from '../../features/projects/projectsSelectors'
import {
  selectRequestStatusIsPending,
  selectRequestStatusIsSucceeded,
} from '../../features/requestStatus/requestStatusSelectors'
import { selectWebhookSecretKey } from '../../features/webhookSecretKey/webhookSecretKeySelectors'
import { getWebhookSecretKey, updateWebhookSecretKey } from '../../features/webhookSecretKey/webhookSecretKeySlice'
import { webhookSecretKeyRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { WebhookSecretKeyPage } from './WebhookSecretKeyPage'

export const ConnectedWebhookSecretKeyPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const webhookSecretKey = useAppSelector(selectWebhookSecretKey)
  const isGetWebhookSecretKeyRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getWebhookSecretKey'),
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isUpdateWebhookSecretKeyRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'updateWebhookSecretKey'),
  )
  const isUpdateWebhookSecretKeyRequestSuccessful = useAppSelector((state) =>
    selectRequestStatusIsSucceeded(state, 'updateWebhookSecretKey'),
  )
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: false,
    }
  })

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onUpdateWebhookSecretKey = useCallback(() => {
    dispatch(updateWebhookSecretKey({ organizationName }))
  }, [dispatch, organizationName])

  const onGetWebhookSecretKey = useCallback(() => {
    dispatch(getWebhookSecretKey({ organizationName }))
  }, [dispatch, organizationName])

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  useEffect(() => {
    onGetWebhookSecretKey()
    dispatch(trackMixpanelPageViewedEvent({ path: webhookSecretKeyRoutePath, organizationName }))
  }, [dispatch, onGetWebhookSecretKey, organizationName])

  return isGetWebhookSecretKeyRequestPending ? (
    <LoadingPage />
  ) : (
    <WebhookSecretKeyPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      isChangeWebhookSecretKeyRequestPending={isUpdateWebhookSecretKeyRequestPending}
      isChangeWebhookSecretKeyRequestSuccessful={isUpdateWebhookSecretKeyRequestSuccessful}
      isLeftColumnOpen={isLeftColumnOpen}
      onChangeWebhookSecretKey={onUpdateWebhookSecretKey}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onProjectChange={handleProjectChange}
      projects={projectSelectOptions}
      webhookSecretKey={webhookSecretKey}
    />
  )
}
