import { defineMessages } from 'react-intl'

export const EditContainerGroupMainContentMessages = defineMessages({
  backToContainerGroupDetails: {
    defaultMessage: 'Back to {container_group_name}',
    id: 'cJ86JwLI',
    description: 'Back to Container Group Details link',
  },
  configureButtonLabel: {
    defaultMessage: 'Configure',
    id: 'n6dx4XwL',
    description:
      'The button label for the create container group side panel modal that is shown when updating a field.',
  },
  editInputText: {
    defaultMessage: 'Edit',
    id: 'mvmccOpV',
    description:
      'The text used for any input field on the create container group page that takes the user to update the input field.',
  },
  hardwareResourceCostPerHourLabel: {
    defaultMessage: '{cost_per_hour} per hour',
    id: 'nTA6A1Np',
    description:
      'The hardware resource cost per hour label shown for allocated resource option input fields on the create container group page.',
  },
  healthCheckProbesSectionHeader: {
    defaultMessage: 'Health Check Probes',
    id: '7uyQ2pp9',
    description: 'The header for the Health Check Probes section of the Edit Container Group page.',
  },
  highDemandLabel: {
    defaultMessage: 'High Demand',
    id: 'NJGPUnsB',
    description: 'The label for the high demand option for the GPU input field.',
  },
  imageSourceBannerBody: {
    defaultMessage: `If the remote image at {image_url} has changed, select 'edit' to re-input the image source and we will pull the new image. For private images, you will need to re-enter your credentials.`,
    id: 'OtC1/kFn',
    description: 'The body text for the image source inline banner notification.',
  },
  imageSourceBannerTitle: {
    defaultMessage: 'Currently using cached image version',
    id: 'q9r1uLId',
    description: 'The title for the image source inline banner notification.',
  },
  imageSourceSectionHeader: {
    defaultMessage: 'Image Source',
    id: 'SWTAAI4a',
    description: 'The header for the Image Source section of the Edit Container Group page.',
  },
  learnMoreButtonLabel: {
    defaultMessage: 'Learn More',
    id: 'W95KE7iV',
    description:
      'The button label for any learn more button shown below input fields for the create container group form.',
  },
  loggingSectionHeader: {
    defaultMessage: 'External Logging Services',
    id: 'x3HJRHZf',
    description: 'The header for the External Logging Services section of the Edit Container Group page.',
  },
  optionalSettingsSectionHeader: {
    defaultMessage: 'Optional Settings',
    id: 'VBY/GBMc',
    description: 'The header for the Optional Settings section of the Edit Container Group page.',
  },
  permanentNameText: {
    defaultMessage: 'permanent name',
    id: 'fCuOGbde',
    description: 'The text used for the permanent name text in the subtitle that will be bolded.',
  },
  resourceAllocationSectionHeader: {
    defaultMessage: 'Resource Allocation',
    id: 'xmoOlCvQ',
    description: 'The header for the Resource Allocation section of the Edit Container Group page.',
  },
  saveButtonLabel: {
    defaultMessage: 'Save',
    id: 'MgjmT61x',
    description: 'The button label for the edit container group wizard form.',
  },
  subtitle: {
    defaultMessage: 'Changes to this deployment will be applied to any new nodes that are assigned to your workload.',
    id: 'CNSP82S3',
    description: 'The subtitle for the Edit Container Group page.',
  },
  title: {
    defaultMessage: 'Edit {container_group_name}',
    id: 'J0LDzLrF',
    description: 'The title for the Edit Container Group page.',
  },
  containerNameDescription: {
    defaultMessage:
      'This is the permanent name for your deployment used in API calls. You can change the name used in the Portal by editing the display name below.',
    id: '6RP0mqxz',
    description: 'The description about the container deployment name.',
  },
  containerNameTitle: {
    defaultMessage: 'Container Deployment Name',
    id: 't+8zwNtV',
    description: 'The header for the container deployment name overview section.',
  },
})
