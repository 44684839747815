import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { InputHelperText } from '../InputHelperText'
import { InputLabel } from '../InputLabel'
import { Spinner } from '../Spinner'

export interface TextFieldProps {
  /** The default value. */
  defaultValue?: string | number
  /** The flag that will remove the default height from the Text Field. */
  hasNoDefaultHeight?: boolean
  /** The flag indicating if the Text Field should have no margin bottom. */
  hasNoDefaultMarginBottom?: boolean
  /** The text to be shown under the Text Field. */
  helperText?: string | ReactNode
  /** The flag indicating whether or not to hide the label shown above the input field. */
  hideLabelText?: boolean
  /** A value indicating the Text Field error state */
  invalid?: boolean
  /** The flag indicating the Text Field is disabled. */
  isDisabled?: boolean
  /**
   * If we have helper text that is 3 lines instead of our regular 1, we want to check this as true so we have enough
   * space to accommodate it.
   */
  isExtraHeight?: boolean
  /** The flag indicating if the Text Field should occupy all available width provided by the parent element. */
  isFullWidth?: boolean
  /** The flag indicating if the helper text should be green. */
  isHelperTextGreen?: boolean
  /**
   * The flag indicating if the Text Field is in a loading state. This will show a loading spinner inside the Text
   * Field.
   */
  isLoading?: boolean
  /** The flag indicating if the Text Field should be a `<textarea/>` instead of an `<input/>` element. */
  isTextarea?: boolean
  /** The label for the Text Field, which is shown above the input. */
  label?: string
  /**
   * A Font Awesome icon class name (e.g. `fa-envelope`) to be shown inside the Text Field on the left side of the
   * field, before the value.
   */
  leftIconClassName?: string
  /** A tailwind class name to be applied to the left icon. If none is provided, a neutral color will be used. */
  leftIconColor?: string
  /** The handler to be invoked once the Text Field value changed */
  onChange?: (value: string | number) => void
  /** A callback executed when the left icon is clicked. */
  onLeftIconClick?: () => void
  /** A callback executed when the right icon is clicked. */
  onRightIconClick?: () => void
  /** The Text Field input placeholder. */
  placeholder?: string
  /** A flag indicating if the text field should be read only. */
  readonly?: boolean
  /**
   * A Font Awesome icon class name (e.g. `fa-envelope`) to be shown inside the Text Field on the right side of the
   * field, after the value.
   */
  rightIconClassName?: string
  /** A tailwind class name to be applied to the right icon. If none is provided, a neutral color will be used. */
  rightIconColor?: string
  /** A value that defines how many `<textarea/>` rows should be displayed. */
  rows?: number
  /** The min attribute specifies the minimum value for an <input> element. */
  min?: number | string
  /** The max attribute specifies the maximum value for an <input> element. */
  max?: number | string
  /** The input type. */
  type?: React.InputHTMLAttributes<HTMLInputElement>['type']
}

export const TextField: FunctionComponent<TextFieldProps> = forwardRef<
  HTMLInputElement & HTMLTextAreaElement,
  TextFieldProps
>(
  (
    {
      defaultValue,
      hasNoDefaultHeight,
      hasNoDefaultMarginBottom,
      helperText,
      hideLabelText,
      invalid = false,
      isDisabled = false,
      isExtraHeight = false,
      isFullWidth = false,
      isHelperTextGreen = false,
      isLoading = false,
      isTextarea = false,
      label,
      leftIconClassName,
      leftIconColor,
      max,
      min,
      onChange,
      onLeftIconClick,
      onRightIconClick,
      placeholder,
      readonly = false,
      rightIconClassName,
      rightIconColor,
      rows = 3,
      type = 'text',
    },
    ref,
  ) => {
    const [value, setValue] = useState(defaultValue ?? '')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value

      if (type === 'number') {
        const onlyWholePositiveNumberRegEx = /^\d+$/
        if (value === '' || onlyWholePositiveNumberRegEx.test(value)) {
          setValue(value)
          onChange && onChange(value)
        }
        return
      }

      setValue(value)
      onChange && onChange(value)
    }

    const Control = isTextarea ? 'textarea' : 'input'

    useEffect(() => {
      setValue(defaultValue ?? '')
    }, [defaultValue])

    return (
      <div
        className={classNames('relative flex w-80 flex-col leading-3', {
          'w-full': isFullWidth,
          'h-[7.5rem]': isExtraHeight && !hasNoDefaultHeight,
          'h-[5.5rem]': !isExtraHeight && !hasNoDefaultHeight,
          'h-auto': isTextarea,
          [hasNoDefaultMarginBottom ? 'mb-0' : 'mb-10']: true,
        })}
      >
        {label && !hideLabelText && <InputLabel hasError={invalid} label={label} />}
        <div className="relative">
          <Control
            aria-label={label}
            disabled={isDisabled}
            id={label}
            max={max}
            min={min}
            ref={ref}
            rows={rows}
            type={type}
            value={value}
            className={classNames(
              'w-full flex-1 resize-y overflow-hidden rounded-md py-2 text-base outline-none placeholder:text-neutral-40',
              {
                'border border-neutral-40 hover:border-green-30 focus:border-green-40 focus:shadow focus:shadow-green-40 focus-visible:border-green-40':
                  !isDisabled && !invalid,
                'border border-neutral-40 bg-neutral-20 text-neutral-40': isDisabled,
                'border border-red-70 shadow-sm shadow-red-50': invalid,
                'px-4': !leftIconClassName && !rightIconClassName,
                'pl-4 pr-10': rightIconClassName && !leftIconClassName,
                'pl-4 pr-12': !leftIconClassName && isLoading,
                'pl-4 pr-20': !leftIconClassName && rightIconClassName && isLoading,
                'pl-10': leftIconClassName && !rightIconClassName,
                'px-10':
                  (leftIconClassName && rightIconClassName) || (leftIconClassName && !rightIconClassName && isLoading),
                'pl-10 pr-20': leftIconClassName && rightIconClassName && isLoading,
                'h-10': !isTextarea,
                'h-72': isTextarea && isExtraHeight,
              },
            )}
            name={label}
            placeholder={placeholder}
            onChange={handleChange}
            readOnly={readonly}
          />
          {leftIconClassName && (
            <div
              className={classNames(
                leftIconClassName,
                `absolute left-4 top-3 ${leftIconColor ?? 'text-neutral-60'} ${onLeftIconClick && 'cursor-pointer'}`,
              )}
              onClick={onLeftIconClick}
            />
          )}
          {rightIconClassName && (
            <div
              className={classNames(
                rightIconClassName,
                `absolute right-4 top-3 ${rightIconColor ?? 'text-neutral-60'} ${onRightIconClick && 'cursor-pointer'}`,
              )}
              onClick={onRightIconClick}
            />
          )}
          {isLoading && (
            <div
              className={classNames('absolute top-2', {
                'right-10': rightIconClassName,
                'right-4': !rightIconClassName,
              })}
            >
              <Spinner />
            </div>
          )}
        </div>

        {helperText && (
          <InputHelperText hasError={invalid} helperText={helperText} isHelperTextGreen={isHelperTextGreen} />
        )}
      </div>
    )
  },
)

TextField.displayName = 'TextField'
