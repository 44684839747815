import {
  CreateCreditsDashboardSessionProblemType,
  CreateInvoiceDashboardSessionProblemType,
  CreateUsageDashboardSessionProblemType,
  ResponseError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  EMPTY,
  catchError,
  concat,
  defer,
  delay,
  filter,
  from,
  ignoreElements,
  mergeMap,
  of,
  retry,
  takeUntil,
  tap,
  timer,
} from 'rxjs'
import { BillingAPI, assertUnreachable } from '../apiMethods'
import { unsubscribeFromBilling } from '../features/billing/billingSlice'
import { selectBillingInformationNotReadyErrorNotificationSeen } from '../features/billingDashboards/billingDashboardsSelectors'
import {
  billingDashboardsEmbedUrlAdded,
  getBillingCreditsDashboardEmbedUrl,
  getBillingInvoiceDashboardEmbedUrl,
  getBillingUsageDashboardEmbedUrl,
} from '../features/billingDashboards/billingDashboardsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getBillingInformationNotReadyError,
  getLoadCreditDashboardGeneralError,
  getLoadCustomBillingPortalOrganizationNotFoundError,
  getLoadInvoiceDashboardErrorContent,
  getLoadUsageDashboardErrorContent,
} from '../notifications/clientToastNotificationContent/billing'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetBillingCreditsDashboardEmbedUrl: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(getBillingCreditsDashboardEmbedUrl.match),
    mergeMap(({ payload: { organizationName } }) => {
      return concat(
        of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'pending' })),
        defer(() =>
          from(
            BillingAPI.createCreditsDashboardSession({
              organizationName,
            }),
          ).pipe(
            mergeMap(({ embedUrl }) => {
              return concat(
                of(
                  billingDashboardsEmbedUrlAdded({
                    organizationName,
                    creditsEmbedUrl: embedUrl,
                  }),
                  setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'idle' })).pipe(delay(1)),
              )
            }),
          ),
        ),
      ).pipe(
        catchError((error) =>
          error instanceof ResponseError && error.response.status === 400
            ? from(error.response.json()).pipe(
                mergeMap((errorResponse) => {
                  const errorType = errorResponse.type as CreateCreditsDashboardSessionProblemType | null
                  if (errorType != null) {
                    switch (errorType) {
                      case CreateCreditsDashboardSessionProblemType.CustomerInformationNotReadyYet:
                        return concat(
                          of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'failed' })),
                          selectBillingInformationNotReadyErrorNotificationSeen(state$.value, organizationName)
                            ? EMPTY
                            : of(showToastNotification(getBillingInformationNotReadyError(intl))),
                          of(
                            billingDashboardsEmbedUrlAdded({
                              organizationName,
                              creditsEmbedUrl: undefined,
                              hasSeenInformationNotReadyErrorNotification: true,
                            }),
                          ),
                          of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                          of(null).pipe(
                            tap(() => {
                              throw error
                            }),
                            ignoreElements(),
                          ),
                        )
                      case CreateCreditsDashboardSessionProblemType.NotAuthorizedForThisOrganization:
                        return concat(
                          of(
                            setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'failed' }),
                            showToastNotification(getLoadCreditDashboardGeneralError(intl)),
                          ),
                          of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                        )
                      case CreateCreditsDashboardSessionProblemType.OrganizationNotFound:
                        return concat(
                          of(
                            setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'failed' }),
                            navigateTo({ path: '/organizations' }),
                          ),
                          of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                        )
                      default:
                        assertUnreachable(errorType)
                    }
                  } else {
                    return concat(
                      of(
                        setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'failed' }),
                        showToastNotification(getLoadCreditDashboardGeneralError(intl)),
                      ),
                      of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'idle' })).pipe(
                        delay(1),
                      ),
                    )
                  }
                }),
              )
            : error instanceof ResponseError && error.response.status === 404
              ? concat(
                  of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'failed' })),
                  selectBillingInformationNotReadyErrorNotificationSeen(state$.value, organizationName)
                    ? EMPTY
                    : of(showToastNotification(getBillingInformationNotReadyError(intl))),
                  of(
                    billingDashboardsEmbedUrlAdded({
                      organizationName,
                      creditsEmbedUrl: undefined,
                      hasSeenInformationNotReadyErrorNotification: true,
                    }),
                  ),
                  of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'idle' })).pipe(
                    delay(1),
                  ),
                  of(null).pipe(
                    tap(() => {
                      throw error
                    }),
                    ignoreElements(),
                  ),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'failed' }),
                    showToastNotification(getLoadCreditDashboardGeneralError(intl)),
                  ),
                  of(setRequestStatus({ request: 'getBillingCreditsDashboardEmbedUrl', status: 'idle' })).pipe(
                    delay(1),
                  ),
                ),
        ),
        retry({ count: 50, delay: (_, count) => timer(count * 3000) }),
        takeUntil(action$.pipe(filter(unsubscribeFromBilling.match))),
      )
    }),
  )

export const onGetInvoiceDashboardEmbedUrl: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(getBillingInvoiceDashboardEmbedUrl.match),
    mergeMap(({ payload: { organizationName } }) => {
      return concat(
        of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'pending' })),
        defer(() =>
          from(
            BillingAPI.createInvoiceDashboardSession({
              organizationName,
            }),
          ).pipe(
            mergeMap(({ embedUrl }) => {
              return concat(
                of(
                  billingDashboardsEmbedUrlAdded({
                    organizationName,
                    invoiceEmbedUrl: embedUrl,
                  }),
                  setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'idle' })).pipe(delay(1)),
              )
            }),
          ),
        ),
      ).pipe(
        catchError((error) =>
          error instanceof ResponseError && error.response.status === 400
            ? from(error.response.json()).pipe(
                mergeMap((errorResponse) => {
                  const errorType = errorResponse.type as CreateInvoiceDashboardSessionProblemType | null
                  if (errorType != null) {
                    switch (errorType) {
                      case CreateInvoiceDashboardSessionProblemType.CustomerInformationNotReadyYet:
                        return concat(
                          of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'failed' })),
                          selectBillingInformationNotReadyErrorNotificationSeen(state$.value, organizationName)
                            ? EMPTY
                            : of(showToastNotification(getBillingInformationNotReadyError(intl))),
                          of(
                            billingDashboardsEmbedUrlAdded({
                              organizationName,
                              invoiceEmbedUrl: undefined,
                              hasSeenInformationNotReadyErrorNotification: true,
                            }),
                          ),
                          of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                          of(null).pipe(
                            tap(() => {
                              throw error
                            }),
                            ignoreElements(),
                          ),
                        )
                      case CreateInvoiceDashboardSessionProblemType.NotAuthorizedForThisOrganization:
                        return concat(
                          of(
                            setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'failed' }),
                            showToastNotification(getLoadCustomBillingPortalOrganizationNotFoundError(intl)),
                          ),
                          of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                        )
                      case CreateInvoiceDashboardSessionProblemType.OrganizationNotFound:
                        return concat(
                          of(
                            setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'failed' }),
                            navigateTo({ path: '/organizations' }),
                          ),
                          of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                        )
                      default:
                        assertUnreachable(errorType)
                    }
                  } else {
                    return concat(
                      of(
                        setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'failed' }),
                        showToastNotification(getLoadInvoiceDashboardErrorContent(intl)),
                      ),
                      of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'idle' })).pipe(
                        delay(1),
                      ),
                    )
                  }
                }),
              )
            : error instanceof ResponseError && error.response.status === 404
              ? concat(
                  of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'failed' })),
                  selectBillingInformationNotReadyErrorNotificationSeen(state$.value, organizationName)
                    ? EMPTY
                    : of(showToastNotification(getBillingInformationNotReadyError(intl))),
                  of(
                    billingDashboardsEmbedUrlAdded({
                      organizationName,
                      invoiceEmbedUrl: undefined,
                      hasSeenInformationNotReadyErrorNotification: true,
                    }),
                  ),
                  of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'idle' })).pipe(
                    delay(1),
                  ),
                  of(null).pipe(
                    tap(() => {
                      throw error
                    }),
                    ignoreElements(),
                  ),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'failed' }),
                    showToastNotification(getLoadInvoiceDashboardErrorContent(intl)),
                  ),
                  of(setRequestStatus({ request: 'getBillingInvoiceDashboardEmbedUrl', status: 'idle' })).pipe(
                    delay(1),
                  ),
                ),
        ),
        retry({ count: 50, delay: (_, count) => timer(count * 3000) }),
        takeUntil(action$.pipe(filter(unsubscribeFromBilling.match))),
      )
    }),
  )

export const onGetUsageDashboardEmbedUrl: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(getBillingUsageDashboardEmbedUrl.match),
    mergeMap(({ payload: { organizationName } }) => {
      return concat(
        of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'pending' })),
        defer(() =>
          from(
            BillingAPI.createUsageDashboardSession({
              organizationName,
            }),
          ).pipe(
            mergeMap(({ embedUrl }) => {
              return concat(
                of(
                  billingDashboardsEmbedUrlAdded({
                    organizationName,
                    usageEmbedUrl: embedUrl,
                  }),
                  setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'idle' })).pipe(delay(1)),
              )
            }),
          ),
        ),
      ).pipe(
        catchError((error) =>
          error instanceof ResponseError && error.response.status === 400
            ? from(error.response.json()).pipe(
                mergeMap((errorResponse) => {
                  const errorType = errorResponse.type as CreateUsageDashboardSessionProblemType | null
                  if (errorType != null) {
                    switch (errorType) {
                      case CreateUsageDashboardSessionProblemType.CustomerInformationNotReadyYet:
                        return concat(
                          of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'failed' })),
                          selectBillingInformationNotReadyErrorNotificationSeen(state$.value, organizationName)
                            ? EMPTY
                            : of(showToastNotification(getBillingInformationNotReadyError(intl))),
                          of(
                            billingDashboardsEmbedUrlAdded({
                              organizationName,
                              usageEmbedUrl: undefined,
                              hasSeenInformationNotReadyErrorNotification: true,
                            }),
                          ),
                          of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                          of(null).pipe(
                            tap(() => {
                              throw error
                            }),
                            ignoreElements(),
                          ),
                        )
                      case CreateUsageDashboardSessionProblemType.NotAuthorizedForThisOrganization:
                        return concat(
                          of(
                            setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'failed' }),
                            showToastNotification(getLoadCustomBillingPortalOrganizationNotFoundError(intl)),
                          ),
                          of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                        )
                      case CreateUsageDashboardSessionProblemType.OrganizationNotFound:
                        return concat(
                          of(
                            setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'failed' }),
                            navigateTo({ path: '/organizations' }),
                          ),
                          of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'idle' })).pipe(
                            delay(1),
                          ),
                        )
                      default:
                        assertUnreachable(errorType)
                    }
                  } else {
                    return concat(
                      of(
                        setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'failed' }),
                        showToastNotification(getLoadUsageDashboardErrorContent(intl)),
                      ),
                      of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'idle' })).pipe(
                        delay(1),
                      ),
                    )
                  }
                }),
              )
            : error instanceof ResponseError && error.response.status === 404
              ? concat(
                  of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'failed' })),
                  selectBillingInformationNotReadyErrorNotificationSeen(state$.value, organizationName)
                    ? EMPTY
                    : of(showToastNotification(getBillingInformationNotReadyError(intl))),
                  of(
                    billingDashboardsEmbedUrlAdded({
                      organizationName,
                      usageEmbedUrl: undefined,
                      hasSeenInformationNotReadyErrorNotification: true,
                    }),
                  ),
                  of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'idle' })).pipe(delay(1)),
                  of(null).pipe(
                    tap(() => {
                      throw error
                    }),
                    ignoreElements(),
                  ),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'failed' }),
                    showToastNotification(getLoadUsageDashboardErrorContent(intl)),
                  ),
                  of(setRequestStatus({ request: 'getBillingUsageDashboardEmbedUrl', status: 'idle' })).pipe(delay(1)),
                ),
        ),
        retry({ count: 50, delay: (_, count) => timer(count * 3000) }),
        takeUntil(action$.pipe(filter(unsubscribeFromBilling.match))),
      )
    }),
  )
