import type { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, first, from, mergeMap, of } from 'rxjs'
import { UserAccountsAPI } from '../apiMethods'
import { setAuthenticatedState } from '../features/authentication/authenticationSlice'
import { setFeatureFlags } from '../features/featureFlags/featureFlagsSlice'
import { setProfile } from '../features/profile/profileSlice'
import type { AppEpic } from '../store'

// TODO: how many retries should we be doing here, what if we fail
// get stuck on loading page when there is a 520 Status code
export const onAppStartup: AppEpic = (_action$, state$) =>
  state$.pipe(
    first(),
    mergeMap(() =>
      concat(
        from(UserAccountsAPI.getProfile()).pipe(
          mergeMap((profile) =>
            of(setProfile({ profile }), setFeatureFlags(), setAuthenticatedState({ authenticated: true })),
          ),
          catchError((error: ResponseError) => {
            if (error.response && (error.response.status === 401 || error.response.status === 404)) {
              return of(setAuthenticatedState({ authenticated: false }))
            }

            // TODO: might be wider server issues, might need to add retry logic?
            return of(setAuthenticatedState({ authenticated: false }))
          }),
        ),
      ),
    ),
  )
