import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import {
  EditLoggingInputFieldMessages,
  EditNewRelicInputFieldsMessages,
  EditSelectExternalLoggingServiceMessages,
} from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditNewRelicInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
}

export const EditNewRelicInputFields: FunctionComponent<EditNewRelicInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={EditContainerGroupField.NEW_RELIC_HOST}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message || intl.formatMessage(EditNewRelicInputFieldsMessages.hostHelperText)}
            label={intl.formatMessage(EditNewRelicInputFieldsMessages.hostLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.NEW_RELIC_INGESTION_KEY}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditNewRelicInputFieldsMessages.ingestionKeyLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <div>
        <p className="mb-2">
          {intl.formatMessage(EditLoggingInputFieldMessages.needHelpText, {
            external_logging_provider: intl.formatMessage(EditSelectExternalLoggingServiceMessages.newRelicLabel),
          })}
        </p>
        <ButtonLink url="https://docs.salad.com/products/sce/external-logging/new-relic" variant="blue-filled-light">
          {intl.formatMessage(EditLoggingInputFieldMessages.learnMoreText)}
        </ButtonLink>
      </div>
    </>
  )
}
