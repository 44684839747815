import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  userId: '798dbbc2-2188-4513-8e78-25aa3220880b',
  email: 'luke.skywalker@salad.com',
  isLeftColumnShowing: false,
  organization: 'Salad Technologies',
}

export const mockNavigationBarSlice = createSlice({
  name: 'navigationBar',
  initialState,
  reducers: {
    setLeftColumnShowing(state, action: PayloadAction<{ showing: boolean }>) {
      state.isLeftColumnShowing = action.payload.showing
    },
  },
})

export const { setLeftColumnShowing } = mockNavigationBarSlice.actions
