import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateOrganizationPageMainContent } from './components/CreateOrganizationMainContent'

export interface CreateOrganizationPageProps {
  /** The flag indicating that the request to create an organization is pending. */
  isCreateOrganizationPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed creates an organization. */
  onCreateOrganization: (organizationName: string, projectName: string) => void
}

export const CreateOrganizationPage: FunctionComponent<CreateOrganizationPageProps> = ({
  isCreateOrganizationPending,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onCreateOrganization,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <CreateOrganizationPageMainContent
          isSubmitPending={isCreateOrganizationPending}
          onCreateOrganization={onCreateOrganization}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
