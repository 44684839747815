import { defineMessages } from 'react-intl'

export const APIKeyMainContentMessages = defineMessages({
  copyAPIKeyButtonLabel: {
    defaultMessage: 'Copy API Key',
    id: 'KnBY1JNF',
    description: 'The label for the copy API Key button.',
  },
  currentAPIKeyLabel: {
    defaultMessage: 'Current API Key',
    id: 'CRlLcytK',
    description: 'The label for the current API Key text field.',
  },
  description: {
    defaultMessage: "Use this key to connect your work to SaladCloud's API",
    id: 'Xa2KGDDP',
    description: 'The description for the API Key page.',
  },
  generateAPIKeyButtonLabel: {
    defaultMessage: 'Change API Key',
    id: 'VWtK/4dH',
    description: 'The label for the Change API Key button.',
  },
  title: {
    defaultMessage: 'API Access',
    id: '373qUsiX',
    description: 'The title for the API Key page.',
  },
})
