import {
  CreateOrganizationProblemType,
  CreateProjectProblemType,
  ResponseError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { OrganizationsAPI, ProjectsAPI, assertUnreachable } from '../apiMethods'
import {
  createNewOrganizationOfCreateNewOrganizationAndProject,
  createNewProjectOfCreateNewOrganizationAndProject,
} from '../features/createNewOrganization/createNewOrganizationSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getCreateOrganizationGeneralErrorContent,
  getCreateOrganizationWithAlreadyUsedReferralCodeContent,
  getCreateOrganizationWithInvalidReferralCodeContent,
  getCreateOrganizationWithNonUniqueNameContent,
} from '../notifications/clientToastNotificationContent/organizations'
import {
  getCreateProjectGeneralErrorContent,
  getCreateProjectWithNonUniqueNameContent,
} from '../notifications/clientToastNotificationContent/projects'
import { getSelectProductPagePath } from '../routes/routes-utils'
import { initializeAndIdentifyMixpanelOrganizationProject } from '../services'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

/**
 * In our new flow, once an organization is successfully made, we make a project in the same organization. This epic
 * handles the first part of the process, the creation of the organization.
 *
 * If the API returns an error, we will show a toast notification with the relevant error message and possibly a error
 * message below the input field.
 */

export const onCreateNewOrganizationOfCreateNewOrganizationAndProject: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createNewOrganizationOfCreateNewOrganizationAndProject.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'pending' })),
        from(
          OrganizationsAPI.createOrganization({
            createOrganization: {
              name: action.payload.organizationName,
            },
          }),
        ).pipe(
          mergeMap(({ id: organizationId }) => {
            initializeAndIdentifyMixpanelOrganizationProject(organizationId)
            return of(
              createNewProjectOfCreateNewOrganizationAndProject({
                organizationName: action.payload.organizationName,
                projectName: action.payload.projectName,
              }),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateOrganizationProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case CreateOrganizationProblemType.InvalidReferralCode:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationWithInvalidReferralCodeContent(intl))),
                          )
                        case CreateOrganizationProblemType.NameConflict:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWithNonUniqueNameContent(intl, action.payload.organizationName),
                              ),
                            ),
                          )
                        case CreateOrganizationProblemType.ReferralCodeAlreadyUsed:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationWithAlreadyUsedReferralCodeContent(intl))),
                          )
                        case CreateOrganizationProblemType.ReferralCodeExpired:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationWithInvalidReferralCodeContent(intl))),
                          )
                        case CreateOrganizationProblemType.ReferralCodeInvalidEmail:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                        of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                      )
                    }
                  }),
                )
              : concat(
                  of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                  of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(delay(1)),
                  of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                )
          }),
        ),
      ),
    ),
  )

/**
 * In our new flow, an organization is successfully made first and then we make a project in the same organization. This
 * epic handles the second half of the flow and creates the project. If the project creation is successful, we will
 * navigate users to the project resources page
 *
 * If the API returns an error, we will show a toast notification with the relevant error message and possibly a error
 * message below the input field.
 */

export const onCreateNewProjectOfCreateNewOrganizationAndProject: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createNewProjectOfCreateNewOrganizationAndProject.match),
    mergeMap((action) =>
      concat(
        from(
          ProjectsAPI.createProject({
            organizationName: action.payload.organizationName,
            createProject: {
              name: action.payload.projectName,
            },
          }),
        ).pipe(
          mergeMap((createProjectResponse) =>
            concat(
              of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'succeeded' })),
              of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(delay(1)),
              of(
                navigateTo({
                  path: getSelectProductPagePath(action.payload.organizationName, createProjectResponse.name),
                }),
              ),
            ),
          ),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateProjectProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case CreateProjectProblemType.NameConflict:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateProjectWithNonUniqueNameContent(intl, action.payload.projectName),
                              ),
                            ),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                        of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(showToastNotification(getCreateProjectGeneralErrorContent(intl))),
                      )
                    }
                  }),
                )
              : concat(
                  of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                  of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(delay(1)),
                  of(showToastNotification(getCreateProjectGeneralErrorContent(intl))),
                )
          }),
        ),
      ),
    ),
  )
