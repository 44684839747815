import type { SyntheticEvent } from 'react'
import { getHelpScoutArticleIds } from './utils'

export const contactSupportViaHelpScoutAnswers = (
  setIsHelpScoutBeaconFailedToLoadModalOpen: (isModalOpen: boolean) => void,
  route?: string,
  trackHelpScoutFailedToOpenMixpanelEvent?: () => void,
  trackHelpIconClickedMixpanelEvent?: () => void,
  event?: SyntheticEvent,
) => {
  event?.preventDefault()
  trackHelpIconClickedMixpanelEvent && trackHelpIconClickedMixpanelEvent()
  const beacon = window.Beacon
  if (beacon && beacon('info') !== undefined) {
    beacon('suggest', getHelpScoutArticleIds(route))
    beacon('navigate', '/answers/')
    beacon('open')
  } else {
    trackHelpScoutFailedToOpenMixpanelEvent && trackHelpScoutFailedToOpenMixpanelEvent()
    setIsHelpScoutBeaconFailedToLoadModalOpen(true)
  }
}
