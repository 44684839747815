import { defineMessages } from 'react-intl'

export const JobQueueDetailsMessages = defineMessages({
  addJobQueueLabel: {
    defaultMessage: 'Add Job Queue',
    id: '10sv3CsK',
    description: 'The label for the add job queue button.',
  },
  disabledLabel: {
    defaultMessage: 'Disabled',
    id: 'XObt4hwS',
    description: 'The label for the job queue details if none is selected.',
  },
  queueNameLabel: {
    defaultMessage: 'Queue: {queue}',
    id: 'VD/lK/EU',
    description: 'The label for the job queue name.',
  },
  queuePathLabel: {
    defaultMessage: 'Path: {path}',
    id: 'W3AUtW5g',
    description: 'The label for the job queue path.',
  },
  queuePortLabel: {
    defaultMessage: 'Port: {port}',
    id: 'D0O84+YU',
    description: 'The label for the job queue port.',
  },
  autoscalingEnabledLabel: {
    defaultMessage: 'Autoscaling Enabled: {enabled}',
    id: 'G04cwni7',
    description: 'The label for whether autoscaling is enabled.',
  },
  autoscalingDesiredQueueLengthLabel: {
    defaultMessage: 'Desired Queue Length: {length}',
    id: 'Lz7l8/9R',
    description: 'The label for the desired queue length for autoscaling.',
  },
  autoscalingMinimumReplicasLabel: {
    defaultMessage: 'Minimum Replicas: {min_replicas}',
    id: 'DqMVgngz',
    description: 'The label for the minimum number of replicas for autoscaling.',
  },
  autoscalingMaximumReplicasLabel: {
    defaultMessage: 'Maximum Replicas: {max_replicas}',
    id: 'Vi5pXy2P',
    description: 'The label for the maximum number of replicas for autoscaling.',
  },
  autoscalingPeriodLabel: {
    defaultMessage: 'Autoscaling Period: {period}',
    id: '0Yk6e3qp',
    description: 'The label for the autoscaling period.',
  },
  autoscalingMaximumUpscalePerMinuteLabel: {
    defaultMessage: 'Maximum Upscale Per Minute: {max_upscale}',
    id: 'urJmKnZb',
    description: 'The label for the maximum upscale per minute for autoscaling.',
  },
  autoscalingMaximumDownscalePerMinuteLabel: {
    defaultMessage: 'Maximum Downscale Per Minute: {max_downscale}',
    id: 'ylxJ+hoM',
    description: 'The label for the maximum downscale per minute for autoscaling.',
  },
})
