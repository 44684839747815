import type { ReactNode } from 'react'
import { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../components/block/HelpScoutSupportLink'
import type { ToastNotificationType } from '../models'
import { UnauthenticatedRequestFailedToastContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown on unauthenticated user flows, where the
 * error message is generic and points the user to contact support via helpscout widget.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getUnauthenticatedRequestFailedContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(UnauthenticatedRequestFailedToastContentMessages.body, {
      contact_support: (
        <HelpScoutSupportLink
          classNames="text-red-70 underline"
          isContactSupportViaHelpScoutAnswers
          linkedText={UnauthenticatedRequestFailedToastContentMessages.contactSupportLinkText.defaultMessage}
        />
      ),
    }),
    type: 'error',
    title: UnauthenticatedRequestFailedToastContentMessages.title.defaultMessage,
  }
}
