import type { AppState } from '../../store'
import { quotasAdapter } from './quotasSlice'

const quotasSelectors = quotasAdapter.getSelectors()

export const selectQuotas = (state: AppState, organizationName: string) =>
  quotasSelectors.selectById(state.quotas, organizationName)

export const selectMaxCreatedContainerGroupQuota = (state: AppState, organizationName: string) =>
  quotasSelectors.selectById(state.quotas, organizationName)?.quotas.containerGroupsQuotas.maxCreatedContainerGroups ??
  0

export const selectContainerGroupInstanceQuota = (state: AppState, organizationName: string) =>
  quotasSelectors.selectById(state.quotas, organizationName)?.quotas.containerGroupsQuotas.containerInstanceQuota ?? 0
