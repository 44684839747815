import { ResponseError, UpdateContainerGroupProblemType } from '@saladtechnologies/openapi-cloud-portal-browser'
import { EMPTY, catchError, concat, delay, filter, from, mergeMap, of, switchMap } from 'rxjs'
import {
  ContainerGroupsAPI,
  OrganizationDataAPI,
  OrganizationOptionsAPI,
  OrganizationsAPI,
  ProjectsAPI,
  QueuesAPI,
  QuotasAPI,
  assertUnreachable,
} from '../apiMethods'
import {
  containerGroupAddedToOrganization,
  editContainerGroup,
  getEditContainerGroupPageData,
} from '../features/containerGroups/containerGroupsSlice'
import { setIsContainerGroupInstancesQuotaExceededModalShowing } from '../features/editContainerGroup/editContainerGroupSlice'
import type { gpuClassesProps } from '../features/gpuClasses/gpuClassesSlice'
import { gpuClassesReceived } from '../features/gpuClasses/gpuClassesSlice'
import { configureGpuClassesResponse } from '../features/gpuClasses/utils'
import { jobQueuesReceived } from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { quotasAddedToOrganization, quotasRemovedFromOrganization } from '../features/quotas/quotasSlice'
import { ramOptionsReceived } from '../features/ramOptions/ramOptionsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { storageOptionsReceived } from '../features/storageOptions/storageOptionsSlice'
import {
  getCreateContainerGroupHostInvalidErrorContent,
  getEditContainerGroupGeneralErrorContent,
  getEditContainerGroupQueueConnectionNotSetErrorContent,
  getEditContainerGroupUpdateInProgressErrorContent,
  getUnableToRetrieveContainerCountLimitError,
} from '../notifications/clientToastNotificationContent/containerGroups'
import { getEditContainerGroupSucceedContent } from '../notifications/clientToastNotificationContent/containerGroups/getEditContainerGroupSucceedContent'
import { getContainerGroupDetailsPagePath, getContainerGroupsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

/**
 * An epic that retrieves the container group the user wants to edit, along with the container count instance quota.
 *
 * If we are unable to retrieve the container group, we navigate the user back to the list of containers for the current
 * project they are viewing.
 *
 * If we are able to retrieve the container group, but unable to get the container count instance quota, then we display
 * an error message under that field in the form.
 */
export const onGetEditContainerGroupPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getEditContainerGroupPageData.match),
    switchMap(({ payload: { containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getEditContainerGroupPageData', status: 'pending' })),
        from(
          Promise.allSettled([
            OrganizationsAPI.getOrganization({
              organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName,
            }),
            QuotasAPI.getQuotas({
              organizationName,
            }),
            OrganizationDataAPI.listGpuClasses({
              organizationName,
            }),
            OrganizationOptionsAPI.listStorageOptions({
              organizationName,
            }),
            OrganizationOptionsAPI.listRamOptions({
              organizationName,
            }),
            ContainerGroupsAPI.getContainerGroup({
              containerGroupName,
              organizationName,
              projectName,
            }),
            QueuesAPI.listQueues({
              organizationName,
              projectName,
            }),
          ]),
        ).pipe(
          switchMap(
            ([
              organizationResponse,
              projectsResponse,
              quotasResponse,
              gpuClassesResponse,
              storageOptionsResponse,
              ramOptionsResponse,
              containerGroupResponse,
              jobQueuesResponse,
            ]) => {
              const hasAnyRequiredDataRequestsFailed =
                gpuClassesResponse.status === 'rejected' ||
                organizationResponse.status === 'rejected' ||
                projectsResponse.status === 'rejected' ||
                containerGroupResponse.status === 'rejected' ||
                ramOptionsResponse.status === 'rejected' ||
                storageOptionsResponse.status === 'rejected'

              if (hasAnyRequiredDataRequestsFailed) {
                const projectContainersRoute = getContainerGroupsPagePath(organizationName, projectName)

                return concat(
                  of(
                    setRequestStatus({ request: 'getEditContainerGroupPageData', status: 'failed' }),
                    navigateTo({ path: projectContainersRoute }),
                    showToastNotification(getEditContainerGroupGeneralErrorContent(intl)),
                  ),
                  of(setRequestStatus({ request: 'getEditContainerGroupPageData', status: 'idle' })).pipe(delay(1)),
                )
              } else {
                const gpuClasses: gpuClassesProps[] = [
                  configureGpuClassesResponse(
                    organizationName,
                    gpuClassesResponse.status === 'fulfilled' ? gpuClassesResponse.value : undefined,
                  ),
                ]

                return concat(
                  of(
                    organizationAdded(organizationResponse.value),
                    projectsAddedToOrganization({
                      organizationName: organizationResponse.value.name,
                      projects: projectsResponse.value.items,
                    }),
                    containerGroupAddedToOrganization({
                      organizationName,
                      projectName,
                      containerGroup: containerGroupResponse.value,
                    }),
                    gpuClassesReceived({
                      gpuClasses,
                    }),
                  ),
                  jobQueuesResponse.status === 'fulfilled'
                    ? of(
                        jobQueuesReceived({
                          organizationName,
                          projectName,
                          jobQueues: jobQueuesResponse.value.items,
                        }),
                      )
                    : EMPTY,
                  ramOptionsResponse.status === 'fulfilled'
                    ? of(ramOptionsReceived({ ramOptions: ramOptionsResponse.value.items }))
                    : EMPTY,
                  storageOptionsResponse.status === 'fulfilled'
                    ? of(storageOptionsReceived({ storageOptions: storageOptionsResponse.value.items }))
                    : EMPTY,
                  quotasResponse.status === 'fulfilled'
                    ? of(
                        quotasAddedToOrganization({
                          organizationName: organizationName,
                          quotas: quotasResponse.value,
                        }),
                        setIsContainerGroupInstancesQuotaExceededModalShowing({
                          showing: quotasResponse.value.containerGroupsQuotas.containerInstanceQuota === 0,
                        }),
                      )
                    : of(
                        showToastNotification(getUnableToRetrieveContainerCountLimitError(intl)),
                        quotasRemovedFromOrganization(organizationName),
                      ),
                  of(setRequestStatus({ request: 'getEditContainerGroupPageData', status: 'succeeded' })),
                  of(setRequestStatus({ request: 'getEditContainerGroupPageData', status: 'idle' })).pipe(delay(1)),
                )
              }
            },
          ),
        ),
      ),
    ),
  )

/**
 * An epic that edits the container group with the values provided by the Edit Container Group form. If the changes
 * requested succeed, we navigate the user to the Container Group Detail page.
 *
 * If we are unable to edit the container group, we show the user an error notification explaining why.
 */
export const onEditContainerGroup: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editContainerGroup.match),
    mergeMap(({ payload: { containerGroupName, organizationName, projectName, updateContainerGroup } }) =>
      concat(
        of(setRequestStatus({ request: 'editContainerGroup', status: 'pending' })),
        from(
          ContainerGroupsAPI.updateContainerGroup({
            containerGroupName: containerGroupName,
            organizationName: organizationName,
            projectName: projectName,
            updateContainerGroup: updateContainerGroup,
          }),
        ).pipe(
          mergeMap((_response) => {
            return concat(
              of(
                setRequestStatus({ request: 'editContainerGroup', status: 'succeeded' }),
                showToastNotification(getEditContainerGroupSucceedContent(intl)),
                navigateTo({
                  path: getContainerGroupDetailsPagePath(organizationName, projectName, containerGroupName),
                }),
              ),
              of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as UpdateContainerGroupProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case UpdateContainerGroupProblemType.UpdatedInstanceQuotaExceeded:
                          return concat(
                            of(
                              setRequestStatus({ request: 'editContainerGroup', status: 'failed' }),
                              setIsContainerGroupInstancesQuotaExceededModalShowing({ showing: true }),
                            ),
                            of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case UpdateContainerGroupProblemType.PendingUpdateInProgress:
                          return concat(
                            of(
                              setRequestStatus({ request: 'editContainerGroup', status: 'failed' }),
                              showToastNotification(getEditContainerGroupUpdateInProgressErrorContent(intl)),
                              navigateTo({
                                path: getContainerGroupDetailsPagePath(
                                  organizationName,
                                  projectName,
                                  containerGroupName,
                                ),
                              }),
                            ),
                            of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case UpdateContainerGroupProblemType.InvalidHost:
                          return concat(
                            of(
                              setRequestStatus({ request: 'editContainerGroup', status: 'failed' }),
                              showToastNotification(getCreateContainerGroupHostInvalidErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case UpdateContainerGroupProblemType.ContainerGroupUpdateException:
                        case UpdateContainerGroupProblemType.ContainerGroupNotFound:
                        case UpdateContainerGroupProblemType.UnexpectedError:
                          return concat(
                            of(
                              setRequestStatus({ request: 'editContainerGroup', status: 'failed' }),
                              showToastNotification(getEditContainerGroupGeneralErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case UpdateContainerGroupProblemType.QueueConnectionNotSet:
                          return concat(
                            of(
                              setRequestStatus({ request: 'editContainerGroup', status: 'failed' }),
                              showToastNotification(getEditContainerGroupQueueConnectionNotSetErrorContent(intl)),
                            ),
                            of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setRequestStatus({ request: 'editContainerGroup', status: 'failed' }),
                          showToastNotification(getEditContainerGroupGeneralErrorContent(intl)),
                        ),
                        of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'editContainerGroup', status: 'failed' }),
                    showToastNotification(getEditContainerGroupGeneralErrorContent(intl)),
                  ),
                  of(setRequestStatus({ request: 'editContainerGroup', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
