import { defineMessages } from 'react-intl'

export const turnstileInitializationProblemsErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'Verification tool failed to load. Please refresh your screen and if this continues, {contact_support}. (Cloudflare Turnstile Error {error_code})',
    id: 'mPN5fRy3',
    description:
      'The body text for the Turnstile widget failed error toast notification, including the error code and contact support link.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'ye8euzzH',
    description: 'The text shown for the contact support link in the widget failed error toast notification.',
  },
  title: {
    defaultMessage: 'Turnstile Widget Error',
    id: 'xH+yfGSg',
    description: 'The toast notification title shown when the Turnstile widget fails to load.',
  },
})
