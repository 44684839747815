import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { CreateQueue, Queue, UpdateQueue } from '@saladtechnologies/openapi-cloud-portal-browser'
import { configuresJobQueuesEntityId } from './utils'

interface JobQueuesProps {
  organizationName: string
  projectName: string
  jobQueues: Queue[]
}

export const jobQueuesAdapter = createEntityAdapter<JobQueuesProps, string>({
  selectId: (item) => configuresJobQueuesEntityId(item.organizationName, item.projectName),
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const jobQueuesSlice = createSlice({
  name: 'jobQueues',
  initialState: jobQueuesAdapter.getInitialState(),
  reducers: {
    createJobQueue(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; createQueue: CreateQueue }>,
    ) {},
    deleteJobQueue(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        queueName: string
      }>,
    ) {},
    editJobQueue(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        updatedQueue: UpdateQueue
        queueName: string
      }>,
    ) {},
    getEditJobQueuePageData(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; jobQueueName: string }>,
    ) {},
    getJobQueueDetailsPageData(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; jobQueueName: string }>,
    ) {},
    getJobQueues(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    getJobQueuesPageData(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    jobQueueAdded(state, action: PayloadAction<{ organizationName: string; projectName: string; jobQueue: Queue }>) {
      const { jobQueue, organizationName, projectName } = action.payload
      const jobQueues = state.entities[configuresJobQueuesEntityId(organizationName, projectName)]?.jobQueues || []

      const updatedJobQueues = jobQueues.filter((cg) => cg.name !== jobQueue.name)
      updatedJobQueues.push(jobQueue)

      jobQueuesAdapter.upsertOne(state, {
        organizationName,
        projectName,
        jobQueues: updatedJobQueues,
      })
    },
    jobQueuesReceived: jobQueuesAdapter.upsertOne,
  },
})

export const {
  createJobQueue,
  deleteJobQueue,
  editJobQueue,
  getEditJobQueuePageData,
  getJobQueueDetailsPageData,
  getJobQueues,
  getJobQueuesPageData,
  jobQueueAdded,
  jobQueuesReceived,
} = jobQueuesSlice.actions
