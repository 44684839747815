import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface NovuState {
  signature?: string
}

const initialState: NovuState = {
  signature: undefined,
}

export const novuSlice = createSlice({
  name: 'novu',
  initialState,
  reducers: {
    setNovuSignature(state, action: PayloadAction<{ signature: string }>) {
      state.signature = action.payload.signature
    },
  },
})

export const { setNovuSignature } = novuSlice.actions
