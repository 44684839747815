import { defineMessages } from 'react-intl'

export const createOrganizationWizardOrganizationNonUniqueNameContentMessages = defineMessages({
  body: {
    defaultMessage: 'This organization name has already been used. Please choose another organization name.',
    id: 'UfoE1G7E',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an organization with a name that has already been taken.',
  },
  title: {
    defaultMessage: 'Please choose another organization name',
    id: 'MXe0IEJE',
    description:
      'The toast notification title shown when we encounter an error on their request to create an organization with a name that has already been taken.',
  },
})
