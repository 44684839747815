import type { HttpHeadersInner } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FieldErrors, FieldValues } from 'react-hook-form'
import { EditContainerGroupField } from '../../models'

/**
 * Checks if the form has any errors.
 *
 * @param errors The react-hook-form error object for the form.
 * @param fields The list of fields for the form.
 */
export const checkIfHealthProbeFormHasErrors = (errors: FieldErrors<FieldValues>, fields: string[]): boolean => {
  return fields.some((field) => errors[field] !== undefined)
}

/**
 * A function that handles taking the values from the health probe command and arguments fields and configuring them to
 * be one string.
 *
 * @param command The health probe command.
 * @param args The optional arguments for the health probe command.
 */
export const configureHealthProbeCommand = (command: string, args?: { argument: string }[]) => {
  if (!args || args.length === 0) return command

  return command + ' ' + args.map((argument) => argument.argument).join(' ')
}

/**
 * A function that handles taking the values from the health probe headers fields and configuring them to be one string.
 *
 * @param headers The headers for the health probe.
 */
export const configureHealthProbeHeaders = (headers: HttpHeadersInner[]): string | undefined => {
  const hasHeaders = headers && headers.length > 0 && headers[0] && headers[0].name !== '' && headers[0].value !== ''
  if (hasHeaders) {
    return headers
      .map((header, index) => {
        const isLastItem = index === headers.length - 1
        return `${header.name}:${header.value}${isLastItem ? '' : ', '}`
      })
      .join('')
  }

  return undefined
}

export const editStartupProbeFieldsList = [
  EditContainerGroupField.STARTUP_PROBE_COMMAND,
  EditContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS,
  EditContainerGroupField.STARTUP_PROBE_ENABLED,
  EditContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD,
  EditContainerGroupField.STARTUP_PROBE_HEADERS,
  EditContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS,
  EditContainerGroupField.STARTUP_PROBE_PATH,
  EditContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS,
  EditContainerGroupField.STARTUP_PROBE_PORT,
  EditContainerGroupField.STARTUP_PROBE_PROTOCOL,
  EditContainerGroupField.STARTUP_PROBE_SERVICE,
  EditContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD,
  EditContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS,
]

export const editLivenessProbeFieldsList = [
  EditContainerGroupField.LIVENESS_PROBE_COMMAND,
  EditContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS,
  EditContainerGroupField.LIVENESS_PROBE_ENABLED,
  EditContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD,
  EditContainerGroupField.LIVENESS_PROBE_HEADERS,
  EditContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS,
  EditContainerGroupField.LIVENESS_PROBE_PATH,
  EditContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS,
  EditContainerGroupField.LIVENESS_PROBE_PORT,
  EditContainerGroupField.LIVENESS_PROBE_PROTOCOL,
  EditContainerGroupField.LIVENESS_PROBE_SERVICE,
  EditContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD,
  EditContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS,
]

export const editReadinessProbeFieldsList = [
  EditContainerGroupField.READINESS_PROBE_COMMAND,
  EditContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS,
  EditContainerGroupField.READINESS_PROBE_ENABLED,
  EditContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD,
  EditContainerGroupField.READINESS_PROBE_HEADERS,
  EditContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS,
  EditContainerGroupField.READINESS_PROBE_PATH,
  EditContainerGroupField.READINESS_PROBE_PERIOD_SECONDS,
  EditContainerGroupField.READINESS_PROBE_PORT,
  EditContainerGroupField.READINESS_PROBE_PROTOCOL,
  EditContainerGroupField.READINESS_PROBE_SERVICE,
  EditContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD,
  EditContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS,
]

export const editAutoscalingFieldsList = [
  EditContainerGroupField.AUTOSCALING_DESIRED_QUEUE_LENGTH,
  EditContainerGroupField.AUTOSCALING_ENABLED,
  EditContainerGroupField.AUTOSCALING_MAXIMUM_DOWNSCALE_PER_MINUTE,
  EditContainerGroupField.AUTOSCALING_MAXIMUM_REPLICAS,
  EditContainerGroupField.AUTOSCALING_MAXIMUM_UPSCALE_PER_MINUTE,
  EditContainerGroupField.AUTOSCALING_MINIMUM_REPLICAS,
  EditContainerGroupField.AUTOSCALING_PERIOD,
]
