import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Tag } from '../../../../components/Tag'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import { Card } from '../../../../components/base/Card'
import { getOrganizationEditPagePath, getOrganizationTeamPagePath } from '../../../../routes/routes-utils'
import SaladCloudOrgIcon from '../../assets/SaladCloudOrgIcon.png'
import { OrganizationCardMessages } from '../../messages/OrganizationCardMessages'
import { DeleteOrganizationModal } from '../DeleteOrganizationModal/DeleteOrganizationModal'

export interface OrganizationCardProps {
  /** The organization created date. */
  createdDate: Date
  /** The flag for if the delete organization request is pending. */
  isDeleteOrganizationPending: boolean
  /** The flag for if the delete organization request was successful. */
  isDeleteOrganizationSuccessful?: boolean
  /**
   * The callback executed when the user deletes an organization.
   *
   * @param organizationName The name of the organization that is being deleted.
   */
  onDeleteOrganization: (organizationName: string) => void
  /** The organization display name. */
  organizationDisplayName: string
  /** The organization member count. */
  organizationMemberCount: number
  /** The organization name. */
  organizationName: string
  /** The organization project count. */
  organizationProjectCount: number
  /** The path for the Switch to this Organization button. */
  switchToOrgPath: string
}

export const OrganizationCard: FunctionComponent<OrganizationCardProps> = ({
  createdDate,
  isDeleteOrganizationPending,
  isDeleteOrganizationSuccessful,
  onDeleteOrganization,
  organizationDisplayName,
  organizationMemberCount,
  organizationName,
  organizationProjectCount,
  switchToOrgPath,
}) => {
  const intl = useIntl()
  const [isDeleteOrganizationModalOpen, setIsDeleteOrganizationModalOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsDeleteOrganizationModalOpen(false)
  }, [isDeleteOrganizationSuccessful])

  return (
    <div className="mb-4">
      <Card>
        <div className="flex flex-wrap">
          <div className="mr-2 flex flex-col">
            <img
              alt={intl.formatMessage(OrganizationCardMessages.saladCloudOrgIconAlt)}
              height={48}
              src={SaladCloudOrgIcon}
              width={48}
            />
          </div>
          <div>
            <div className="mb-1 flex flex-row">
              <p className="font-bold">{organizationDisplayName}</p>
            </div>
            <div className="mb-4 flex flex-row gap-2">
              <Tag color="gray">
                {organizationMemberCount === 1
                  ? intl.formatMessage(OrganizationCardMessages.oneMemberCountPill, {
                      organization_member_count: organizationMemberCount,
                    })
                  : intl.formatMessage(OrganizationCardMessages.memberCountPill, {
                      organization_member_count: organizationMemberCount,
                    })}
              </Tag>
              <Tag color="gray">
                {organizationProjectCount === 1
                  ? intl.formatMessage(OrganizationCardMessages.oneProjectCountPill, {
                      organization_project_count: organizationProjectCount,
                    })
                  : intl.formatMessage(OrganizationCardMessages.projectCountPill, {
                      organization_project_count: organizationProjectCount,
                    })}
              </Tag>
              <p>
                {intl.formatMessage(OrganizationCardMessages.createdDate, {
                  created_date: intl.formatDate(createdDate, { dateStyle: 'long' }),
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <ButtonLink url={switchToOrgPath} variant="green-filled-light">
            {intl.formatMessage(OrganizationCardMessages.switchButtonLabel)}
          </ButtonLink>
          <ButtonLink url={getOrganizationTeamPagePath(organizationName)} variant="blue-filled-light">
            {intl.formatMessage(OrganizationCardMessages.manageButtonLabel)}
          </ButtonLink>
          <ButtonLink url={getOrganizationEditPagePath(organizationName)} variant="blue-text">
            {intl.formatMessage(OrganizationCardMessages.editDisplayNameButtonLabel)}
          </ButtonLink>
          <Button variant="red-text" type="button" onClick={() => setIsDeleteOrganizationModalOpen(true)}>
            {intl.formatMessage(OrganizationCardMessages.deleteButtonLabel)}
          </Button>
        </div>
      </Card>
      {isDeleteOrganizationModalOpen && (
        <DeleteOrganizationModal
          isDeleteOrganizationPending={isDeleteOrganizationPending}
          onCancel={() => setIsDeleteOrganizationModalOpen(false)}
          onDeleteOrganization={onDeleteOrganization}
          organizationName={organizationName}
        />
      )}
    </div>
  )
}
