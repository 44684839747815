import type { FunctionComponent } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { EditHealthProbeFieldsMessages } from '../../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../../models'
import { EditContainerGroupField } from '../../../models'
import { EditHealthProbeFields } from '../components'

interface EditLivenessProbeProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the fields. */
  id: EditContainerGroupFormSectionIdAttributes
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: UseFormSetValue<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const EditLivenessProbeFields: FunctionComponent<EditLivenessProbeProps> = ({
  clearErrors,
  control,
  id,
  setValue,
  trigger,
}) => {
  const intl = useIntl()
  const subtitle = intl.formatMessage(EditHealthProbeFieldsMessages.livenessSubtitle, {
    learn_more_link_text: (
      <a
        href="https://docs.salad.com/products/sce/health-probes/liveness-probes"
        rel="noreferrer"
        target="_blank"
        className="text-blue-90 underline"
      >
        {intl.formatMessage(EditHealthProbeFieldsMessages.learMoreLinkText)}
      </a>
    ),
  })
  return (
    <EditHealthProbeFields
      clearErrors={clearErrors}
      commandFieldName={EditContainerGroupField.LIVENESS_PROBE_COMMAND}
      commandArgumentsFieldName={EditContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS}
      control={control}
      enabledFieldName={EditContainerGroupField.LIVENESS_PROBE_ENABLED}
      failureThresholdFieldName={EditContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD}
      formID="livenessProbeFieldsForm"
      headersFieldName={EditContainerGroupField.LIVENESS_PROBE_HEADERS}
      healthProbe="Liveness"
      id={id}
      initialDelaySecondsFieldName={EditContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS}
      pathFieldName={EditContainerGroupField.LIVENESS_PROBE_PATH}
      periodSecondsFieldName={EditContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS}
      portFieldName={EditContainerGroupField.LIVENESS_PROBE_PORT}
      protocolFieldName={EditContainerGroupField.LIVENESS_PROBE_PROTOCOL}
      serviceFieldName={EditContainerGroupField.LIVENESS_PROBE_SERVICE}
      setValue={setValue}
      subtitle={subtitle}
      successThresholdFieldName={EditContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD}
      timeoutSecondsFieldName={EditContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS}
      trigger={trigger}
    />
  )
}
