import { defineMessages } from 'react-intl'

export const FailedToLoadInferenceEndpointsMarketplaceMessages = defineMessages({
  message: {
    defaultMessage: 'Seems like we had a problem loading the API Endpoints marketplace',
    id: 'N8/tc3xb',
    description: 'The message shown to the user when we were unable to load the API Endpoints marketplace.',
  },
  tryAgainButtonLabel: {
    defaultMessage: 'Try Again',
    id: 'lVdmCs1p',
    description: 'The label for the try again button.',
  },
})
