import type { AppState } from '../../store'
import { FeatureFlag } from './models'

export const selectContainerGroupPriorityEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Container_Group_Priority] === true

export const selectJobQueueAutoscalerFeatureFlagEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Job_Queue_Autoscaler] === true

export const selectContainerGatewayOptionsEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Container_Gateway_Options] === true

export const selectInboxFeatureFlagEnabled = (state: AppState) => state.featureFlags[FeatureFlag.Inbox] === true

export const selectOrganizationOnboardingWizardEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Organization_Onboarding_Wizard] === true

export const selectTranscriptionFreeTrialEnabled = (state: AppState) =>
  state.featureFlags[FeatureFlag.Transcription_Free_Trial] === true
