import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { HelpScoutSupportButton } from '../../../components/block/HelpScoutSupportButton'
import { Button } from '../../../components/Button'
import { VerificationErrorMessages } from '../messages'

export interface VerificationErrorProps {
  /*
   The "Log In" button handler
  */
  onViewLoginPage: () => void
}

export const VerificationError: FunctionComponent<VerificationErrorProps> = ({ onViewLoginPage }) => {
  const intl = useIntl()

  return (
    <>
      <h1 className="text-2xl font-bold">{intl.formatMessage(VerificationErrorMessages.title)}</h1>
      <p className="text-base">{intl.formatMessage(VerificationErrorMessages.description)}</p>
      <div className="flex flex-row justify-start gap-3">
        <Button isFullWidth onClick={onViewLoginPage}>
          {intl.formatMessage(VerificationErrorMessages.logInButtonLabel)}
        </Button>
        <HelpScoutSupportButton
          eventLabel={VerificationErrorMessages.contactSupportButtonClickedMixpanelEventLabel.defaultMessage}
          failedEventLabel={
            VerificationErrorMessages.contactSupportHelpScoutWidgetFailedMixpanelEventLabel.defaultMessage
          }
        />
      </div>
    </>
  )
}
