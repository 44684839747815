import type { FunctionComponent, ReactNode } from 'react'
import { useCallback, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../components/page/LoadingPage'
import { selectAuthenticatedState } from '../features/authentication/authenticationSelectors'
import { selectIsHelpScoutBeaconFailedToOpenModalOpen } from '../features/helpScout/helpScoutSelectors'
import { setIsHelpScoutBeaconFailedToOpenModalOpen } from '../features/helpScout/helpScoutSlice'
import { lastViewedResourceAdded } from '../features/lastResourceViewed/lastResourceViewedSlice'
import { AppLayout } from '../layouts/app'
import { ToastContainer } from '../notifications'
import { useAppDispatch, useAppSelector } from '../store'
import { HelpScoutBeaconFailedToLoadModal } from '../utils/helpScout/components'

interface PrivateRouteProps {
  children: ReactNode
}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ children }) => {
  const { organizationName, projectName } = useParams()
  const dispatch = useAppDispatch()
  const authenticated = useAppSelector(selectAuthenticatedState)
  const isHelpScoutBeaconFailedToLoadModalOpen = useAppSelector(selectIsHelpScoutBeaconFailedToOpenModalOpen)

  const handleCloseHelpScoutBeaconFailedToLoadModal = useCallback(() => {
    dispatch(setIsHelpScoutBeaconFailedToOpenModalOpen({ isOpen: false }))
  }, [dispatch])

  const handleSetLastResourceViewed = useCallback(() => {
    if (organizationName) {
      dispatch(
        lastViewedResourceAdded({
          organizationName,
          projectName,
        }),
      )
    }
  }, [dispatch, organizationName, projectName])

  useEffect(() => {
    handleSetLastResourceViewed()
  }, [handleSetLastResourceViewed])

  if (authenticated === null) {
    return <LoadingPage />
  }

  if (authenticated === false) {
    return <Navigate to="/" />
  }

  return (
    <AppLayout>
      <ToastContainer />
      {children}
      {isHelpScoutBeaconFailedToLoadModalOpen && (
        <HelpScoutBeaconFailedToLoadModal onClose={handleCloseHelpScoutBeaconFailedToLoadModal} />
      )}
    </AppLayout>
  )
}

export default PrivateRoute
