import { defineMessages } from 'react-intl'

export const CreateInferenceEndpointJobGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'An error occurred while handling your API endpoint job request. Please retry, and if the issue continues, {contact_support} for further assistance.',
    id: 'ZWhR+31R',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an API endpoint job.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'DBiPt1ju',
    description: 'The text shown for the contact support link in the create API endpoint job error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating API endpoint job',
    id: 'RpvPJ3/o',
    description:
      'The toast notification title shown when we encounter an error on their request to create an API endpoint job.',
  },
})
