import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectContainerGroups } from '../../features/containerGroups/containerGroupsSelectors'
import { getContainerGroups, getContainerGroupsPageData } from '../../features/containerGroups/containerGroupsSlice'
import { configuresContainerGroupsEntityId } from '../../features/containerGroups/utils'
import { selectGpuClasses } from '../../features/gpuClasses/gpuClassesSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRamOptions } from '../../features/ramOptions/ramOptionsSelectors'
import { selectRequestStatus, selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { selectStorageOptions } from '../../features/storageOptions/storageOptionsSelectors'
import { containersPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { ContainerGroupsPage } from './ContainerGroupsPage'
import { getContainerGroupsListWithGpuClasses } from './utils'

export const ConnectedContainerGroupsPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { organizationName = '', projectName = '' } = useParams()

  const containerGroups = useAppSelector((state) =>
    selectContainerGroups(state.containerGroups, configuresContainerGroupsEntityId(organizationName, projectName)),
  )
  const availableGpuClasses = useAppSelector((state) => selectGpuClasses(state.gpuClasses, organizationName))
  const ramOptions = useAppSelector((state) => selectRamOptions(state.ramOptions))
  const storageOptions = useAppSelector((state) => selectStorageOptions(state.storageOptions))
  const containerGroupsWithGpuClasses = getContainerGroupsListWithGpuClasses(containerGroups, availableGpuClasses)
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isGetContainerGroupsPageDataPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getContainerGroupsPageData'),
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))

  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleGetContainerGroups = useCallback(() => {
    dispatch(
      getContainerGroups({
        organizationName,
        projectName,
      }),
    )
  }, [dispatch, organizationName, projectName])

  const handleGetContainerGroupsPageData = useCallback(() => {
    dispatch(
      getContainerGroupsPageData({
        organizationName,
        projectName,
      }),
    )
  }, [dispatch, organizationName, projectName])

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleLearnMore = () => {
    window.open('https://docs.salad.com/products/sce/salad-container-engine', '_blank')
  }

  useEffect(() => {
    handleGetContainerGroupsPageData()
    const intervalId = setInterval(handleGetContainerGroups, 15000)

    return () => clearInterval(intervalId)
  }, [handleGetContainerGroups, handleGetContainerGroupsPageData])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: containersPageRoutePath, organizationName, projectName }))
  }, [dispatch, organizationName, projectName])

  return isGetContainerGroupsPageDataPending ? (
    <LoadingPage />
  ) : (
    <ContainerGroupsPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isLeftColumnOpen={isLeftColumnOpen}
      containerGroups={containerGroupsWithGpuClasses}
      projects={projectSelectOptions}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onProjectChange={handleProjectChange}
      onLearnMore={handleLearnMore}
      ramOptions={ramOptions}
      storageOptions={storageOptions}
    />
  )
}
