import { defineMessages } from 'react-intl'

export const turnstileChallengeExecutionFailureErrorMessages = defineMessages({
  bodyText: {
    defaultMessage:
      'Verification failed due to suspicious activity. Please try the challenge again or {contact_support}. (Cloudflare Turnstile Error {error_code})',
    description:
      'The body text for the suspicious activity challenge error toast notification, including the error code and contact support link.',
    id: 'pReTCYQr',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    description:
      'The text shown for the contact support link in the suspicious activity challenge error toast notification.',
    id: '8zI4D9E+',
  },
  turnstileErrorTitle: {
    defaultMessage: 'Suspicious Activity Detected',
    description: 'The title for the suspicious activity challenge error toast notification.',
    id: 'EAIA3d9R',
  },
})
