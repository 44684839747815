import { defineMessages } from 'react-intl'

export const ValuePropositionMessages = defineMessages({
  gpuDrivenProcessingTitle: {
    defaultMessage: 'GPU Driven Processing',
    id: 'WjGAT9Jd',
    description: 'The title for the GPU driven processing benefit.',
  },
  gpuDrivenProcessingDescription: {
    defaultMessage:
      'Distribute data batch jobs, HPC workloads, and rendering queues to thousands of 3D-accelerated GPUs.',
    id: 'Bxmr3qR5',
    description: 'The description for the GPU driven processing benefit.',
  },
  onDemandElasticityTitle: {
    defaultMessage: 'On-Demand Elasticity',
    id: '/6HKeFy5',
    description: 'The title for the on-demand elasticity benefit.',
  },
  onDemandElasticityDescription: {
    defaultMessage:
      'Scale to 10,000 nodes on demand using our toolkits, or build a custom K8s integration with our simple API.',
    id: 'qCrfQ7Ju',
    description: 'The description for the on-demand elasticity benefit.',
  },
  reduceBudgetTitle: {
    defaultMessage: 'Reduce Budget Bloat',
    id: 'LMknDV6b',
    description: 'The title for the reduce budget benefit.',
  },
  reduceBudgetDescription: {
    defaultMessage:
      'Save up to 90% on orchestration services from big-box providers plus discounts on recurring plans.',
    id: 'qlGvF1ay',
    description: 'The description for the reduce budget benefit.',
  },
})
