import { defineMessages } from 'react-intl'

export const AddNewPaymentMethodAndPrepayChargedSuccessfullyMessages = defineMessages({
  body: {
    defaultMessage:
      'Credits have been applied to your SaladCloud account, and you are now permitted to deploy workloads to SaladCloud. Your payment method has been saved to your account. You will receive a receipt in your email inbox soon.',
    id: 'YctRyTR6',
    description:
      'The description shown in a toast notification message shown when the new payment method was successfully added and charged.',
  },
  title: {
    defaultMessage: 'Your payment method has been successfully charged. Thank you!',
    id: 'JkR39V62',
    description: 'The toast notification title shown when the new payment method was successfully added and charged.',
  },
})
