import {
  ConfirmPasswordResetProblemType,
  ResponseError,
  SendPasswordResetEmailProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { UserAccountsAPI, assertUnreachable } from '../apiMethods'
import { sendResetPasswordEmail, setForgotPasswordPage } from '../features/forgotPassword/forgotPasswordSlice'
import { ForgotPasswordPage } from '../features/forgotPassword/models'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { ResetPasswordPage } from '../features/resetPassword/models/ResetPasswordPage'
import { confirmPasswordReset, setResetPasswordPage } from '../features/resetPassword/resetPasswordSlice'
import {
  getSuccessfullySentEmailToastContent,
  getUnauthenticatedRequestFailedContent,
} from '../notifications/clientToastNotificationContent'
import { getEmailNotSentContent } from '../notifications/clientToastNotificationContent/registration'
import type { AppEpic } from '../store'

/**
 * An epic that makes a request to send a Reset Password email to the user. If the request is successful, we navigate
 * the user to the `Reset Password Email Sent` page, unless the payload given has a `resending` flag, then we just show
 * a `Toast Notification` indicating the next steps.
 */
export const onSendResetPasswordEmail: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(sendResetPasswordEmail.match),
    mergeMap((action) => {
      return concat(
        of(setRequestStatus({ request: 'sendResetPasswordEmail', status: 'pending' })),
        from(
          UserAccountsAPI.sendPasswordResetEmail({
            sendPasswordResetEmail: {
              email: action.payload.email,
            },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'sendResetPasswordEmail', status: 'succeeded' }),
                action.payload.resending
                  ? showToastNotification(getSuccessfullySentEmailToastContent())
                  : setForgotPasswordPage({ page: ForgotPasswordPage.ResetPasswordEmailSent }),
              ),
              of(setRequestStatus({ request: 'sendResetPasswordEmail', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const requestFailedToastNotificationContent = getUnauthenticatedRequestFailedContent(intl)
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as SendPasswordResetEmailProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case SendPasswordResetEmailProblemType.EmailNotSent:
                          return concat(
                            of(
                              showToastNotification(getEmailNotSentContent(intl, action.payload.email)),
                              setRequestStatus({ request: 'sendResetPasswordEmail', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'sendResetPasswordEmail', status: 'idle' })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(requestFailedToastNotificationContent),
                          setRequestStatus({ request: 'sendResetPasswordEmail', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'sendResetPasswordEmail', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(requestFailedToastNotificationContent),
                    setRequestStatus({ request: 'sendResetPasswordEmail', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'sendResetPasswordEmail', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      )
    }),
  )

/**
 * An epic that makes a request to confirm the new password the user has created. If the request succeeds, the user is
 * shown a `Reset Password Successful` page. If it fails due to the token being expired, they are shown the `Reset
 * Password Link Expired` page.
 */
export const onConfirmPasswordReset: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(confirmPasswordReset.match),
    mergeMap((action) => {
      return concat(
        of(setRequestStatus({ request: 'confirmPasswordReset', status: 'pending' })),
        from(
          UserAccountsAPI.confirmPasswordReset({
            confirmPasswordReset: {
              token: action.payload.token,
              newPassword: action.payload.newPassword,
            },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'confirmPasswordReset', status: 'succeeded' }),
                setResetPasswordPage({ page: ResetPasswordPage.ResetPasswordSuccessful }),
              ),
              of(setRequestStatus({ request: 'confirmPasswordReset', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as ConfirmPasswordResetProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setResetPasswordPage({ page: ResetPasswordPage.ResetPasswordLinkExpired }),
                          setRequestStatus({ request: 'confirmPasswordReset', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'confirmPasswordReset', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getUnauthenticatedRequestFailedContent(intl)),
                    setRequestStatus({ request: 'confirmPasswordReset', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'confirmPasswordReset', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      )
    }),
  )
