import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import {
  getContainerGroupsPagePath,
  getCreateContainerGroupPagePath,
  getInferenceEndpointsMarketplacePagePath,
} from '../../routes/routes-utils'
import { CreateProjectSteps } from '../CreateProject/components/CreateProjectSteps'
import { SelectProductMainContent } from './components/SelectProductMainContent'

export interface SelectProductPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The flag indicating if the left column has the standard left nav bar */
  hasStandardLeftNavBar?: boolean
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating if the transcription free trial feature flag is enabled */
  isTranscriptionFreeTrialFeatureFlagEnabled?: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectDisplayName?: string) => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
}

export const SelectProductPage: FunctionComponent<SelectProductPageProps> = ({
  currentOrganization,
  currentProject,
  hasStandardLeftNavBar,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isLeftColumnOpen,
  isTranscriptionFreeTrialFeatureFlagEnabled,
  onCloseLeftDrawer,
  onDeleteProject,
  onProjectChange,
  onRecordMixpanelElementClickedEvent,
  projects,
}) => (
  <ThreeColumnLayout
    isLeftColumnOpen={isLeftColumnOpen}
    LeftColumn={
      hasStandardLeftNavBar ? (
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          projects={projects}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
        />
      ) : (
        <CreateProjectSteps
          currentStep="selectProduct"
          organizationDisplayName={currentOrganization.displayName}
          projectDisplayName={currentProject.displayName}
        />
      )
    }
    onCloseLeftDrawer={onCloseLeftDrawer}
    MainColumn={
      <SelectProductMainContent
        containerGroupsPath={getContainerGroupsPagePath(currentOrganization.name, currentProject.name)}
        createContainerGroupPath={getCreateContainerGroupPagePath(currentOrganization.name, currentProject.name)}
        inferenceEndpointsMarketplacePath={getInferenceEndpointsMarketplacePagePath(currentOrganization.name)}
        isTranscriptionFreeTrialFeatureFlagEnabled={isTranscriptionFreeTrialFeatureFlagEnabled}
        onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
      />
    }
  />
)
