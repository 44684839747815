import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormResetField, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { ExternalLoggingDetails } from '../../../../components/containerGroups/ExternalLogging/'
import { ExternalLoggingService } from '../../../../components/containerGroups/models'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { KeyValuePairForm } from '../../../../components/forms/KeyValuePairForm'
import { Select } from '../../../../components/forms/Select'
import {
  CreateContainerGroupMainContentMessages,
  DatadogInputFieldsMessages,
  HTTPInputFieldsMessages,
  LoggingInputFieldMessages,
  SelectExternalLoggingServiceMessages,
} from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import { LoggingFieldsInputForms } from './LoggingFieldsInputForms'
import { checkIfExternalLoggingServiceFieldsHaveErrors, externalLoggingFieldsList } from './utils'

interface LoggingFieldsProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const LoggingFields: FunctionComponent<LoggingFieldsProps> = ({
  clearErrors,
  control,
  id,
  onRecordMixpanelElementClickedEvent,
  resetField,
  trigger,
}) => {
  const [isSidePanelOpen, setSidePanelOpenState] = useState<boolean>(false)
  const [localExternalLoggingService, setExternalLoggingService] = useState<string | undefined>(undefined)
  const [isHTTPHeadersSidePanelShown, setIsHTTPHeadersSidePanelShown] = useState<boolean>(false)
  const [isDatadogTagsSidePanelShown, setIsDatadogTagsSidePanelShown] = useState(false)
  const { errors } = useFormState({ control })
  const intl = useIntl()
  const axiomApiKey = useWatch({ control, name: CreateContainerGroupField.AXIOM_API_KEY })
  const axiomDatasetName = useWatch({ control, name: CreateContainerGroupField.AXIOM_DATASET_NAME })
  const axiomHost = useWatch({ control, name: CreateContainerGroupField.AXIOM_HOST })
  const datadogApiKey = useWatch({ control, name: CreateContainerGroupField.DATADOG_API })
  const datadogHost = useWatch({ control, name: CreateContainerGroupField.DATADOG_HOST })
  const datadogTags = useWatch({ control, name: CreateContainerGroupField.DATADOG_TAGS })
  const externalLoggingServiceFieldsHasError = checkIfExternalLoggingServiceFieldsHaveErrors(errors)
  const externalLoggingService = useWatch({ control, name: CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE })
  const httpCompression = useWatch({ control, name: CreateContainerGroupField.HTTP_COMPRESSION })
  const httpFormat = useWatch({ control, name: CreateContainerGroupField.HTTP_FORMAT })
  const httpHeaders = useWatch({ control, name: CreateContainerGroupField.HTTP_HEADERS })
  const httpHost = useWatch({ control, name: CreateContainerGroupField.HTTP_HOST })
  const httpPassword = useWatch({ control, name: CreateContainerGroupField.HTTP_PASSWORD })
  const httpPath = useWatch({ control, name: CreateContainerGroupField.HTTP_PATH })
  const httpPort = useWatch({ control, name: CreateContainerGroupField.HTTP_PORT })
  const httpUser = useWatch({ control, name: CreateContainerGroupField.HTTP_USER })
  const newRelicHost = useWatch({ control, name: CreateContainerGroupField.NEW_RELIC_HOST })
  const newRelicIngestionKey = useWatch({ control, name: CreateContainerGroupField.NEW_RELIC_INGESTION_KEY })
  const splunkHost = useWatch({ control, name: CreateContainerGroupField.SPLUNK_HOST })
  const splunkToken = useWatch({ control, name: CreateContainerGroupField.SPLUNK_TOKEN })
  const tcpHost = useWatch({ control, name: CreateContainerGroupField.TCP_HOST })
  const tcpPort = useWatch({ control, name: CreateContainerGroupField.TCP_PORT })

  const handleValidateFieldsBeforeClose = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    await trigger(externalLoggingFieldsList).then((isValid) => {
      if (isValid) {
        setSidePanelOpenState(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={externalLoggingServiceFieldsHasError}
        onEditForm={() => setSidePanelOpenState(true)}
        title={intl.formatMessage(CreateContainerGroupMainContentMessages.loggingSectionHeader)}
      >
        <ExternalLoggingDetails
          axiomApiKey={axiomApiKey}
          axiomDatasetName={axiomDatasetName}
          axiomHost={axiomHost}
          datadogApiKey={datadogApiKey}
          datadogHost={datadogHost}
          datadogTags={datadogTags}
          externalLoggingService={externalLoggingService}
          httpCompression={httpCompression}
          httpFormat={httpFormat}
          httpHeaders={httpHeaders}
          httpHost={httpHost}
          httpPassword={httpPassword}
          httpPath={httpPath}
          httpPort={httpPort}
          httpUser={httpUser}
          newRelicHost={newRelicHost}
          newRelicIngestionKey={newRelicIngestionKey}
          splunkHost={splunkHost}
          splunkToken={splunkToken}
          tcpHost={tcpHost}
          tcpPort={tcpPort}
        />
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button
            form="updateLoggingFieldsForm"
            isFullWidth
            onClick={() => handleValidateFieldsBeforeClose()}
            type="button"
            variant="green-filled"
          >
            {intl.formatMessage(CreateContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isSidePanelOpen}
        onClose={handleValidateFieldsBeforeClose}
        title={intl.formatMessage(LoggingInputFieldMessages.sidePanelTitle)}
      >
        <form onSubmit={handleValidateFieldsBeforeClose} id="updateLoggingFieldsForm">
          <div className="mt-12 px-10">
            <h2 className="mb-2 text-3xl font-bold">{intl.formatMessage(LoggingInputFieldMessages.sidePanelTitle)}</h2>
            <p className="mb-16">
              {intl.formatMessage(LoggingInputFieldMessages.loggingDescription, {
                learn_more: (
                  <Link url="https://docs.salad.com/products/sce/external-logging">
                    {intl.formatMessage(LoggingInputFieldMessages.learnMoreText)}
                  </Link>
                ),
              })}
            </p>
            <div className="mb-16">
              <Controller
                name={CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      {...fieldState}
                      defaultSelectedValue={field.value || localExternalLoggingService}
                      onChange={(value) => {
                        if (value === undefined) {
                          resetField(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE)
                          externalLoggingFieldsList.forEach((field) => {
                            resetField(field)
                          })

                          // This is needed to trigger the UI updating for the external logging service field
                          setExternalLoggingService(value)
                        }
                        field.onChange(value)
                      }}
                      labelText={intl.formatMessage(SelectExternalLoggingServiceMessages.label)}
                      options={[
                        {
                          label: intl.formatMessage(SelectExternalLoggingServiceMessages.axiomLabel),
                          value: ExternalLoggingService.AXIOM,
                        },
                        {
                          label: intl.formatMessage(SelectExternalLoggingServiceMessages.newRelicLabel),
                          value: ExternalLoggingService.NEW_RELIC,
                        },
                        {
                          label: intl.formatMessage(SelectExternalLoggingServiceMessages.splunkLabel),
                          value: ExternalLoggingService.SPLUNK,
                        },
                        {
                          label: intl.formatMessage(SelectExternalLoggingServiceMessages.tcpLabel),
                          value: ExternalLoggingService.TCP,
                        },
                        {
                          label: intl.formatMessage(SelectExternalLoggingServiceMessages.datadogLabel),
                          value: ExternalLoggingService.DATADOG,
                        },
                        {
                          label: intl.formatMessage(SelectExternalLoggingServiceMessages.httpLabel),
                          value: ExternalLoggingService.HTTP,
                        },
                      ]}
                      placeholderText={intl.formatMessage(SelectExternalLoggingServiceMessages.placeholderText)}
                      isFullWidth
                    />
                  )
                }}
              />
            </div>

            <LoggingFieldsInputForms
              clearErrors={clearErrors}
              control={control}
              externalLoggingService={externalLoggingService}
              onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
              onSetIsHTTPHeadersSidePanelShown={setIsHTTPHeadersSidePanelShown}
              onSetIsDatadogTagsSidePanelShown={setIsDatadogTagsSidePanelShown}
            />
          </div>
        </form>
      </SidePanelModal>
      <KeyValuePairForm
        addButtonLabel={intl.formatMessage(HTTPInputFieldsMessages.addHeaderButtonLabel)}
        clearErrors={clearErrors}
        control={control}
        controllerName={CreateContainerGroupField.HTTP_HEADERS}
        formId="updateHTTPHeadersForm"
        isSidePanelOpen={isHTTPHeadersSidePanelShown}
        isStacked
        keyLabel={intl.formatMessage(HTTPInputFieldsMessages.nameLabel)}
        keyValuePairValues={httpHeaders}
        onSetIsSidePanelOpen={setIsHTTPHeadersSidePanelShown}
        submitButtonLabel={intl.formatMessage(HTTPInputFieldsMessages.configureButtonLabel)}
        title={intl.formatMessage(HTTPInputFieldsMessages.addHeadersTitle)}
        trigger={trigger}
        valueLabel={intl.formatMessage(HTTPInputFieldsMessages.valueLabel)}
      />
      <KeyValuePairForm
        addButtonLabel={intl.formatMessage(DatadogInputFieldsMessages.addTagButtonLabel)}
        clearErrors={clearErrors}
        control={control}
        controllerName={CreateContainerGroupField.DATADOG_TAGS}
        formId="updateDatadogTagsForm"
        isSidePanelOpen={isDatadogTagsSidePanelShown}
        isStacked
        keyLabel={intl.formatMessage(DatadogInputFieldsMessages.nameLabel)}
        keyValuePairValues={datadogTags}
        onSetIsSidePanelOpen={setIsDatadogTagsSidePanelShown}
        submitButtonLabel={intl.formatMessage(DatadogInputFieldsMessages.configureButtonLabel)}
        title={intl.formatMessage(DatadogInputFieldsMessages.addTagsTitle)}
        trigger={trigger}
        valueLabel={intl.formatMessage(DatadogInputFieldsMessages.valueLabel)}
      />
    </div>
  )
}
