import { useCallback, type FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { matchRoutes, useLocation, useParams } from 'react-router'
import { trackMixpanelElementClickedEvent, trackMixpanelEvent } from '../../../features/analytics/analyticsSlice'
import { setIsHelpScoutBeaconFailedToOpenModalOpen } from '../../../features/helpScout/helpScoutSlice'
import { routes } from '../../../routes/Routes'
import { contactSupportViaHelpScoutAnswers, contactSupportViaHelpScoutAsks } from '../../../utils/helpScout'
import { HelpScoutSupportLinkMessages } from './messages'

export interface HelpScoutSupportLinkProps {
  /** An optional prop passed down to apply Tailwind classnames to the anchor element. */
  classNames?: string
  /** An optional prop passed down to set an ID attribute for an anchor element. */
  id?: string
  /** An optional prop passed down to determine if the Help Scout widget should be opened when the link is clicked. */
  isContactSupportViaHelpScoutAnswers?: boolean
  /** The text that will have applied link styles and will open Help Scout when clicked. */
  linkedText: string
}

export const HelpScoutSupportLink: FunctionComponent<HelpScoutSupportLinkProps> = (props) => {
  let isStorybook = false
  if (window.location.port === '6006' || window.location.host === 'preview-portal-ui.salad.com') {
    isStorybook = true
  }

  return <>{isStorybook ? <StorybookHelpScoutSupportLink {...props} /> : <AppHelpScoutSupportLink {...props} />}</>
}

const StorybookHelpScoutSupportLink: FunctionComponent<HelpScoutSupportLinkProps> = ({
  classNames,
  id,
  linkedText,
}) => {
  return (
    <a
      href="mailto:cloud@salad.com?subject=SaladCloud Support"
      id={id}
      className={`cursor-pointer underline ${classNames ?? ''}`}
    >
      {linkedText}
    </a>
  )
}

const AppHelpScoutSupportLink: FunctionComponent<HelpScoutSupportLinkProps> = ({
  classNames,
  id,
  isContactSupportViaHelpScoutAnswers,
  linkedText,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const matchedRoutes = matchRoutes(routes, location)
  const currentRouteId = matchedRoutes && matchedRoutes.length > 0 ? matchedRoutes[0]?.route.id : undefined
  const { containerGroupName, inferenceEndpointName, jobQueueName, organizationName, projectName } = useParams()

  const setIsHelpScoutBeaconFailedToLoadModalOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsHelpScoutBeaconFailedToOpenModalOpen({ isOpen }))
    },
    [dispatch],
  )

  const handleHelpScoutFailedToOpenMixpanelEventTrack = useCallback(() => {
    currentRouteId &&
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label: HelpScoutSupportLinkMessages.helpScoutWidgetFailedViaContactSupportMixpanelEventLabeL.defaultMessage,
            path: currentRouteId,
            organizationName,
            projectName,
            resourceName: containerGroupName,
            jobQueueName,
            inferenceEndpointName,
          },
        }),
      )
  }, [containerGroupName, dispatch, jobQueueName, organizationName, currentRouteId, projectName, inferenceEndpointName])

  const handleTrackMixpanelClickEventTrack = useCallback(() => {
    currentRouteId &&
      dispatch(
        trackMixpanelElementClickedEvent({
          label: HelpScoutSupportLinkMessages.helpScoutSupportLinkClickedMixpanelEventLabel.defaultMessage,
          path: currentRouteId,
          organizationName,
          projectName,
          resourceName: containerGroupName,
          jobQueueName,
        }),
      )
  }, [containerGroupName, currentRouteId, dispatch, jobQueueName, organizationName, projectName])

  const handleContactSupportClick = () => {
    if (isContactSupportViaHelpScoutAnswers) {
      contactSupportViaHelpScoutAnswers(
        setIsHelpScoutBeaconFailedToLoadModalOpen,
        currentRouteId,
        handleHelpScoutFailedToOpenMixpanelEventTrack,
        handleTrackMixpanelClickEventTrack,
      )
    } else {
      handleTrackMixpanelClickEventTrack()
      contactSupportViaHelpScoutAsks(
        setIsHelpScoutBeaconFailedToLoadModalOpen,
        handleHelpScoutFailedToOpenMixpanelEventTrack,
      )
    }
  }

  return (
    <span onClick={handleContactSupportClick} id={id} className={`cursor-pointer underline ${classNames ?? ''}`}>
      {linkedText}
    </span>
  )
}
