import { defineMessages } from 'react-intl'

export const turnstileTimeProblemErrorMessages = defineMessages({
  bodyText: {
    defaultMessage:
      'Verification failed due to system clock. Please try setting your system clock to the appropriate time or {contact_support}. (Cloudflare Turnstile Error {error_code})',
    description:
      'The body text for the system clock error toast notification, including the error code and contact support link.',
    id: 'faSI+TRe',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    description: 'The text shown for the contact support link in the system clock error toast notification.',
    id: 'q5BW+AlK',
  },
  turnstileErrorTitle: {
    defaultMessage: 'System Clock Error',
    description: 'The title for the system clock error toast notification.',
    id: 'jLwFs0HB',
  },
})
