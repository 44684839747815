import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import {
  EditAxiomInputFieldsMessages,
  EditLoggingInputFieldMessages,
  EditSelectExternalLoggingServiceMessages,
} from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditAxiomInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
}

export const EditAxiomInputFields: FunctionComponent<EditAxiomInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={EditContainerGroupField.AXIOM_HOST}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditAxiomInputFieldsMessages.hostLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.AXIOM_DATASET_NAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditAxiomInputFieldsMessages.datasetNameLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.AXIOM_API_KEY}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditAxiomInputFieldsMessages.apiKeyLabel)}
            type="text"
            isFullWidth
          />
        )}
      />

      <div>
        <p className="mb-2">
          {intl.formatMessage(EditLoggingInputFieldMessages.needHelpText, {
            external_logging_provider: intl.formatMessage(EditSelectExternalLoggingServiceMessages.axiomLabel),
          })}
        </p>
        <ButtonLink url="https://docs.salad.com/products/sce/external-logging/axiom" variant="blue-filled-light">
          {intl.formatMessage(EditLoggingInputFieldMessages.learnMoreText)}
        </ButtonLink>
      </div>
    </>
  )
}
