import {
  ResponseError,
  UpdateCreateOrganizationWizardProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { assertUnreachable, CreateOrganizationWizardAPI, OrganizationNameAvailabilityAPI } from '../apiMethods'
import {
  checkOrganizationNameAvailability,
  createOrganizationWizard,
  createOrganizationWizardAdded,
  editCreateOrganizationWizard,
} from '../features/createOrganizationWizard/createOrganizationWizardSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setIsOrganizationNameAvailable } from '../features/organizationInformation/organizationInformation'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getCreateOrganizationWizardConfigureCreditSettingsGeneralErrorContent,
  getCreateOrganizationWizardCreateOrganizationGeneralErrorContent,
  getCreateOrganizationWizardOrganizationNonUniqueNameContent,
  getUnableToContinueInCreateOrganizationWizardGenericErrorContent,
} from '../notifications/clientToastNotificationContent/createOrganizationWizard'
import { getCreateOrganizationWizardOrganizationInformationPagePath } from '../routes/routes-utils'
import { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onCheckOrganizationNameAvailability: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(checkOrganizationNameAvailability.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'pending' })),
        from(
          OrganizationNameAvailabilityAPI.organizationNameAvailability({
            checkOrganizationNameAvailability: { name: organizationName },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'succeeded' }),
                setIsOrganizationNameAvailable({ isOrganizationNameAvailable: true }),
              ),
              of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(delay(1)),
            ).pipe(
              catchError(() => {
                return concat(
                  of(
                    setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'failed' }),
                    setIsOrganizationNameAvailable({ isOrganizationNameAvailable: false }),
                  ),
                  of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(delay(1)),
                )
              }),
            ),
          ),
        ),
      ),
    ),
  )

export const onCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createOrganizationWizard.match),
    mergeMap(() => {
      return concat(
        of(
          setRequestStatus({
            request: 'createOrganizationWizard',
            status: 'pending',
          }),
        ),
        from(CreateOrganizationWizardAPI.createOrganizationWizard()).pipe(
          mergeMap((createOrganizationWizard) =>
            concat(
              of(
                createOrganizationWizardAdded({
                  createOrganizationWizard,
                }),
                setRequestStatus({
                  request: 'createOrganizationWizard',
                  status: 'succeeded',
                }),
                navigateTo({
                  path: getCreateOrganizationWizardOrganizationInformationPagePath(createOrganizationWizard.id),
                }),
              ),
              of(
                setRequestStatus({
                  request: 'createOrganizationWizard',
                  status: 'idle',
                }),
              ).pipe(delay(1)),
            ).pipe(
              catchError(() => {
                return concat(
                  of(
                    setRequestStatus({
                      request: 'createOrganizationWizard',
                      status: 'failed',
                    }),
                    showToastNotification(getCreateOrganizationWizardCreateOrganizationGeneralErrorContent(intl)),
                  ),
                  of(
                    setRequestStatus({
                      request: 'createOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              }),
            ),
          ),
        ),
      )
    }),
  )

export const onEditCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editCreateOrganizationWizard.match),
    mergeMap(({ payload: { createOrganizationWizard } }) =>
      concat(
        of(
          setRequestStatus({
            request: 'editCreateOrganizationWizard',
            status: 'pending',
          }),
        ),
        from(
          CreateOrganizationWizardAPI.updateOrganizationWizard({
            createOrganizationWizardId: createOrganizationWizard.id,
            updateCreateOrganizationWizard: createOrganizationWizard,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({
                  request: 'editCreateOrganizationWizard',
                  status: 'succeeded',
                }),
              ),
              // TODO: add in route handling on success
              of(
                setRequestStatus({
                  request: 'editCreateOrganizationWizard',
                  status: 'idle',
                }),
              ).pipe(delay(1)),
            ).pipe(
              catchError((error: unknown) => {
                return error instanceof ResponseError
                  ? from(error.response.json()).pipe(
                      mergeMap((errorResponse) => {
                        const errorType = errorResponse.type as UpdateCreateOrganizationWizardProblemType | null
                        if (errorType != null) {
                          switch (errorType) {
                            case UpdateCreateOrganizationWizardProblemType.CreateOrganizationInvalidAutomaticRechargeThresholdError:
                              return concat(
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                                  delay(1),
                                ),
                                of(
                                  showToastNotification(
                                    getCreateOrganizationWizardConfigureCreditSettingsGeneralErrorContent(intl), // TODO: update with more specific error message
                                  ),
                                ),
                              )
                            case UpdateCreateOrganizationWizardProblemType.CreateOrganizationInvalidInitialCreditError:
                              return concat(
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                                  delay(1),
                                ),
                                of(
                                  showToastNotification(
                                    getCreateOrganizationWizardConfigureCreditSettingsGeneralErrorContent(intl), // TODO: update with more specific error message
                                  ),
                                ),
                              )
                            case UpdateCreateOrganizationWizardProblemType.CreateOrganizationWizardInvalidAutomaticRechargeAmount:
                              return concat(
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                                  delay(1),
                                ),
                                of(
                                  showToastNotification(
                                    getCreateOrganizationWizardConfigureCreditSettingsGeneralErrorContent(intl), // TODO: update with more specific error message
                                  ),
                                ),
                              )
                            case UpdateCreateOrganizationWizardProblemType.OrganizationNameAlreadyReserved:
                              return concat(
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                                  delay(1),
                                ),
                                of(
                                  showToastNotification(
                                    getCreateOrganizationWizardOrganizationNonUniqueNameContent(intl),
                                  ),
                                ),
                              )
                            case UpdateCreateOrganizationWizardProblemType.UnexpectedError:
                              return concat(
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                                of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                                  delay(1),
                                ),
                                of(
                                  showToastNotification(
                                    getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl),
                                  ),
                                ),
                              )
                            default:
                              assertUnreachable(errorType)
                          }
                        } else {
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl),
                              ),
                            ),
                          )
                        }
                      }),
                    )
                  : concat(
                      of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                      of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
                      of(showToastNotification(getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl))),
                    )
              }),
            ),
          ),
        ),
      ),
    ),
  )
