import type {
  ContainerGroup,
  ContainerGroupLogsItemsInner,
  ContainerGroupLogsQuery,
  RamOption,
  StorageOption,
  WorkloadError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import type { Tab } from '../../../../components/Tabs'
import { Tabs } from '../../../../components/Tabs'
import { Tag } from '../../../../components/Tag'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import { InlineNotification } from '../../../../components/block/InlineNotification'
import { ContainerGroupsDeploymentTags } from '../../../../components/containerGroups/ContainerGroupsDeploymentTags'
import { DeploymentInstanceTable } from '../../../../components/deployments/DeploymentInstanceTable/DeploymentInstanceTable'
import { InstanceStatusesExplainerModal } from '../../../../components/deployments/InstanceStatusesExplainerModal'
import { isWorkloadErrorPillVisible } from '../../../../components/deployments/WorkloadErrorsTable/utils'
import type { DeploymentInstance } from '../../../../features/containerGroupInstanceTable/models'
import type { ClientGpuClass } from '../../../../features/gpuClasses/models'
import { createToastNotification } from '../../../../notifications'
import { getAccessDomainNameCopiedToKeyboardSucceededContent } from '../../../../notifications/clientToastNotificationContent/containerGroups/getAccessDomainNameCopiedToKeyboardSucceededContent'
import { getRamOptionIsHighDemand } from '../../../../utils/ramOptions'
import { getStorageOptionIsHighDemand } from '../../../../utils/storageOptions'
import { checkIfAllGpuClassesAreHighDemand } from '../../../CreateContainerGroup/components/GPUInput/utils'
import { ContainerGroupLogsFormValues } from '../../models'
import { detectFailedToPullImageDescription, getFailedToPullImageMessage } from '../../utils'
import { ContainerGroupDetailsMainContentMessages } from './ContainerGroupDetailsMainContentMessages'
import { ContainerGroupLogs } from './ContainerGroupLogs'
import { DeploymentDetails } from './DeploymentDetails'
import { WorkloadErrors } from './WorkloadErrors'

interface ContainerGroupDetailsMainContentProps {
  /** The Container Group */
  containerGroup: ContainerGroup
  /** The container group details page path */
  containerGroupDetailsPagePath: string
  /** The Container Group instances */
  containerGroupInstances: DeploymentInstance[]
  /** The path to navigate to container groups page */
  containerGroupsPagePath: string
  /** The container log array of items */
  containerGroupLogs: ContainerGroupLogsItemsInner[]
  /** The create container group page path */
  createContainerGroupPagePath: string
  /** The default form values for the container group logs query. */
  defaultContainerGroupLogsFormValues?: Partial<ContainerGroupLogsFormValues>
  /** The default tab index for the details page to open on. */
  defaultTabIndex: number
  /** The path to navigate to edit container groups page */
  editContainerGroupsPagePath: string
  /** The detailed data of the GPU classes */
  gpuClasses?: ClientGpuClass[]
  /** The flag indicating that the get container group logs request is pending. */
  isGetContainerGroupLogsPending: boolean
  /** Indicates whether refresh button is in loading state */
  isRefreshButtonLoading: boolean
  /** Indicates that start container group request is currently in progress. */
  isStartContainerGroupRequestPending: boolean
  /** Indicates that stop container group request is currently in progress. */
  isStopContainerGroupRequestPending: boolean
  /** The callback executed when the user clicks on "Back to Container Groups" link */
  onDeleteContainerGroup: () => void
  /** The callback executed when the user clicks on "Duplicate" button. */
  onDuplicateContainerGroup: () => void
  /** The callback executed when the user clicks the `Query` button on container logs. */
  onQueryContainerGroupLogs: (containerGroupLogsQuery: ContainerGroupLogsQuery) => void
  /**
   * The callback executed when the user reallocates a container group instance.
   *
   * @param machineId The machine ID of the container group instance to reallocate.
   */
  onReallocateContainerGroupInstance: (machineId: string) => void
  /**
   * A callback that when executed records a mixpanel event
   *
   * @param label The label of the element that was clicked
   */
  onTrackMixpanelEvent: (label: string, machineId?: string) => void
  /**
   * The callback executed when the user recreates a container group instance.
   *
   * @param machineId The machine ID of the container group instance to reallocate.
   */
  onRecreateContainerGroupInstance: (machineId: string) => void
  /**
   * The callback executed when the user restarts a container group instance.
   *
   * @param machineId The machine ID of the container group instance to reallocate.
   */
  onRestartContainerGroupInstance: (machineId: string) => void
  /** The callback executed when the user clicks on "Refresh" link */
  onRefreshContainerGroup: () => void
  /**
   * Sets the default form values to be applied to the Container Logs Query Form.
   *
   * @param formValues The container group logs form values.
   */
  onSetDefaultContainerLogsFormValues: (formValues: Partial<ContainerGroupLogsFormValues>) => void
  /** The callback executed when the user clicks the `Start` button. */
  onStartContainerGroup: () => void
  /** The callback executed when the user clicks the `Pause` button. */
  onStopContainerGroup: () => void
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
  /** The workload errors for the container group. */
  workloadErrors: WorkloadError[]
}

export const ContainerGroupDetailsMainContent: FunctionComponent<ContainerGroupDetailsMainContentProps> = ({
  containerGroup,
  containerGroupDetailsPagePath,
  containerGroupInstances,
  containerGroupLogs,
  containerGroupsPagePath,
  createContainerGroupPagePath,
  defaultContainerGroupLogsFormValues,
  defaultTabIndex,
  editContainerGroupsPagePath,
  gpuClasses,
  isGetContainerGroupLogsPending,
  isRefreshButtonLoading,
  isStartContainerGroupRequestPending,
  isStopContainerGroupRequestPending,
  onDeleteContainerGroup,
  onDuplicateContainerGroup,
  onQueryContainerGroupLogs,
  onReallocateContainerGroupInstance,
  onRecreateContainerGroupInstance,
  onRefreshContainerGroup,
  onRestartContainerGroupInstance,
  onSetDefaultContainerLogsFormValues,
  onStartContainerGroup,
  onStopContainerGroup,
  onTrackMixpanelEvent,
  ramOptions,
  storageOptions,
  workloadErrors,
}) => {
  const {
    autostartPolicy,
    container: { resources },
    createTime,
    currentState: {
      description,
      instanceStatusCounts: { runningCount },
      status,
    },
    displayName,
    networking: containerGateway,
    pendingChange,
    replicas,
    version,
  } = containerGroup
  const intl = useIntl()
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex)
  const [isInstanceStatusExplainerModalShowing, setInstanceStatusExplainerModalShowing] = useState(false)
  const ramSelectedIsInHighDemand = getRamOptionIsHighDemand(ramOptions, resources.memory)
  const storageSelectedIsInHighDemand = getStorageOptionIsHighDemand(storageOptions, resources.storageAmount)
  const resourcesSelectedAreInHighDemand =
    checkIfAllGpuClassesAreHighDemand(gpuClasses) || ramSelectedIsInHighDemand || storageSelectedIsInHighDemand
  const shouldShowStopButton = status === 'running' || status === 'deploying'
  const hasFailedToPullImage = detectFailedToPullImageDescription(description)
  const workloadErrorsCount = workloadErrors.length
  const showWorkloadErrorPill = isWorkloadErrorPillVisible(replicas, workloadErrorsCount)

  const handleCopyAccessDomainName = (accessDomainName: string) => {
    navigator.clipboard.writeText(accessDomainName)
    createToastNotification(getAccessDomainNameCopiedToKeyboardSucceededContent(intl))
  }

  const handleViewContainerGroupLogs = (formValues: Partial<ContainerGroupLogsFormValues>, tabIndex: number) => {
    onSetDefaultContainerLogsFormValues(formValues)
    setSelectedTabIndex(tabIndex)
  }

  let tabs: Tab[] = [
    {
      label: intl.formatMessage(ContainerGroupDetailsMainContentMessages.instances),
      content: (
        <div className="mb-5">
          <DeploymentInstanceTable
            deploymentInstances={containerGroupInstances}
            deploymentStatus={containerGroup.currentState.status}
            instanceDetailsPagePath={containerGroupDetailsPagePath}
            onReallocateDeploymentInstance={onReallocateContainerGroupInstance}
            onRecreateDeploymentInstance={onRecreateContainerGroupInstance}
            onRestartDeploymentInstance={onRestartContainerGroupInstance}
            onShowInstanceStatusExplainerModal={() => setInstanceStatusExplainerModalShowing(true)}
            onTrackMixpanelEvent={onTrackMixpanelEvent}
            onViewContainerGroupLogs={handleViewContainerGroupLogs}
          />
        </div>
      ),
    },
    {
      label: intl.formatMessage(ContainerGroupDetailsMainContentMessages.deploymentDetails),
      content: (
        <DeploymentDetails
          containerGroup={containerGroup}
          gpuClasses={gpuClasses}
          ramOptions={ramOptions}
          storageOptions={storageOptions}
        />
      ),
    },
    {
      label: (
        <div className="inline-flex">
          <p className="mr-1">{intl.formatMessage(ContainerGroupDetailsMainContentMessages.recentErrorsLabel)}</p>
          {showWorkloadErrorPill && <Tag color={'red'}>{workloadErrorsCount}</Tag>}
        </div>
      ),
      hasError: showWorkloadErrorPill,
      content: <WorkloadErrors containerGroupDisplayName={displayName} workloadErrors={workloadErrors} />,
    },
    {
      label: intl.formatMessage(ContainerGroupDetailsMainContentMessages.containerGroupLogsTabLabel),
      content: (
        <ContainerGroupLogs
          isGetContainerGroupLogsPending={isGetContainerGroupLogsPending}
          onQueryContainerGroupLogs={onQueryContainerGroupLogs}
          containerGroupLogs={containerGroupLogs}
          initialFormValues={defaultContainerGroupLogsFormValues}
        />
      ),
    },
  ]

  return (
    <div className="relative w-full">
      <div className="max-w-3xl">
        <div className="mb-4">
          <Link url={containerGroupsPagePath}>
            <span className={classNames('fa-solid fa-arrow-left mr-2')} />
            {intl.formatMessage(ContainerGroupDetailsMainContentMessages.backToContainersGroup)}
          </Link>
        </div>
        <span className="mt-1 text-xs text-neutral-70">
          {intl.formatMessage(ContainerGroupDetailsMainContentMessages.deployedOn, {
            date: intl.formatDate(createTime, { dateStyle: 'long', timeStyle: 'short' }),
          })}
        </span>
        <h1 className="text-2xl font-bold">{displayName}</h1>
        <div className="mt-2">
          <ContainerGroupsDeploymentTags
            // TODO: This component needs to be updated to take the actual status, and map desired names
            currentStatus={status}
            hasFailedToPullImage={hasFailedToPullImage}
            isAuthRequired={containerGateway?.auth}
            replicas={replicas}
            replicasRunning={runningCount}
            workloadErrorCount={workloadErrorsCount}
            version={version}
          />
        </div>
        {description && (
          <p className="mt-1 text-sm">{hasFailedToPullImage ? getFailedToPullImageMessage(intl) : description}</p>
        )}
        {status === 'pending' && autostartPolicy && (
          <p className="mt-1 text-sm">
            {intl.formatMessage(ContainerGroupDetailsMainContentMessages.containerGroupAutostartLabel)}
          </p>
        )}
        {/* Container Group Controls  */}
        <div className="my-4 flex flex-row gap-2">
          {shouldShowStopButton ? (
            <Button
              iconClassName="fa-solid fa-stop"
              variant="green-outlined"
              onClick={onStopContainerGroup}
              isLoading={isStopContainerGroupRequestPending}
            >
              {intl.formatMessage(ContainerGroupDetailsMainContentMessages.stop)}
            </Button>
          ) : (
            <Button
              iconClassName="fa-solid fa-play"
              variant="green-filled"
              onClick={onStartContainerGroup}
              isDisabled={status === 'pending' || status === 'failed'}
              isLoading={isStartContainerGroupRequestPending}
            >
              {intl.formatMessage(ContainerGroupDetailsMainContentMessages.start)}
            </Button>
          )}
          <Button
            iconClassName="fa-solid fa-arrows-rotate"
            variant="green-filled-light"
            isLoading={isRefreshButtonLoading}
            onClick={onRefreshContainerGroup}
          >
            {intl.formatMessage(ContainerGroupDetailsMainContentMessages.refresh)}
          </Button>
          <ButtonLink
            iconClassName="fa-solid fa-copy"
            onClick={onDuplicateContainerGroup}
            url={createContainerGroupPagePath}
            variant="green-filled-light"
          >
            {intl.formatMessage(ContainerGroupDetailsMainContentMessages.duplicateButtonLabel)}
          </ButtonLink>

          <ButtonLink
            url={editContainerGroupsPagePath}
            variant="blue-filled-light"
            iconClassName="fa-solid fa-pen-to-square"
            isDisabled={pendingChange}
          >
            {intl.formatMessage(ContainerGroupDetailsMainContentMessages.edit)}
          </ButtonLink>

          <Button onClick={onDeleteContainerGroup} variant="red-filled-light" iconClassName="fa-solid fa-trash">
            {intl.formatMessage(ContainerGroupDetailsMainContentMessages.delete)}
          </Button>
        </div>
        {resourcesSelectedAreInHighDemand && (
          <InlineNotification
            body={intl.formatMessage(
              ContainerGroupDetailsMainContentMessages.highDemandHardwareSelectedNotificationBody,
            )}
            title={intl.formatMessage(
              ContainerGroupDetailsMainContentMessages.highDemandHardwareSelectedNotificationTitle,
            )}
            type="info"
          />
        )}
        {containerGateway?.dns && (
          <div className="mt-6 w-full">
            <TextField
              defaultValue={`https://${containerGateway?.dns}`}
              onRightIconClick={() => handleCopyAccessDomainName(`https://${containerGateway?.dns}`)}
              readonly
              label={
                containerGateway.auth
                  ? intl.formatMessage(ContainerGroupDetailsMainContentMessages.accessDomainNameProtectedTitle)
                  : intl.formatMessage(ContainerGroupDetailsMainContentMessages.accessDomainNameOpenTitle)
              }
              leftIconClassName={containerGateway.auth ? 'fa-solid fa-lock' : 'fa-solid fa-unlock'}
              leftIconColor="text-blue-80"
              rightIconClassName="fa-solid fa-clone"
              rightIconColor="text-blue-80"
              isFullWidth
              helperText={
                <p className="mt-1 text-xs font-normal">
                  {intl.formatMessage(ContainerGroupDetailsMainContentMessages.accessDomainNameDescription)}
                  <Link url="https://docs.salad.com/products/sce/networking">
                    {intl.formatMessage(ContainerGroupDetailsMainContentMessages.readMoreHere)}
                  </Link>
                </p>
              }
            />
          </div>
        )}
      </div>

      {/* Instances and Deployment Details */}
      <div className="mt-10 max-w-7xl">
        <Tabs tabs={tabs} selectedTabIndex={selectedTabIndex} onSelectedTabIndexChange={setSelectedTabIndex} />
      </div>

      {/* Instance Status Explainer Modal */}
      {isInstanceStatusExplainerModalShowing && (
        <InstanceStatusesExplainerModal onCancel={() => setInstanceStatusExplainerModalShowing(false)} />
      )}
    </div>
  )
}
