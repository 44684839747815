import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { HelpScoutSupportButton } from '../../../components/block/HelpScoutSupportButton'
import { Button } from '../../../components/Button'
import { ModalLayout } from '../../../layouts/authentication'
import { ResetPasswordEmailSentMessages } from './ResetPasswordEmailSentMessages'

export interface ResetPasswordEmailSentProps {
  /** The email. */
  email: string
  /** The flag indicating if the onResendEmail request is pending */
  isResendEmailPending: boolean
  /** Resend email handler. */
  onResendEmail: () => void
}

export const ResetPasswordEmailSent: FunctionComponent<ResetPasswordEmailSentProps> = ({
  email,
  isResendEmailPending,
  onResendEmail,
}) => {
  const intl = useIntl()

  const isLongEmail = email.length > 30

  return (
    <ModalLayout type="wide">
      <h1 className="text-2xl font-bold">{intl.formatMessage(ResetPasswordEmailSentMessages.title)}</h1>
      <h2
        className={classNames('font-bold text-green-70', {
          'text-2xl': !isLongEmail,
          'text-xl': isLongEmail,
        })}
      >
        {email}
      </h2>
      <p>{intl.formatMessage(ResetPasswordEmailSentMessages.subTitle)}</p>
      <div className="flex max-w-md gap-6">
        <Button isFullWidth isDisabled={isResendEmailPending} isLoading={isResendEmailPending} onClick={onResendEmail}>
          {intl.formatMessage(ResetPasswordEmailSentMessages.resendEmailButton)}
        </Button>
        <HelpScoutSupportButton
          eventLabel={ResetPasswordEmailSentMessages.contactSupportButtonClickedMixpanelEventLabel.defaultMessage}
          failedEventLabel={
            ResetPasswordEmailSentMessages.contactSupportHelpScoutWidgetFailedMixpanelEventLabel.defaultMessage
          }
        />
      </div>
    </ModalLayout>
  )
}
