import { defineMessages } from 'react-intl'

export const CreateContainerGroupQueueConnectionNotSetErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'You must add and configure a job queue to enable autoscaling on your container.',
    id: 'zE1uo2jX',
    description: 'The description shown when we can not create a container group because the queue is not set.',
  },
  title: {
    defaultMessage: 'Unable to create container group',
    id: '3bgyplnB',
    description:
      'The toast notification title shown when we can not create a container group because the queue is not set.',
  },
})
