import { defineMessages } from 'react-intl'

export const AutoscalingFieldsMessages = defineMessages({
  desiredQueueLengthHelperText: {
    defaultMessage: 'The target value for the queue length to be used by the scaler. Must be between 1 and 100.',
    id: '2gsdKsD7',
    description: 'The helper text for the desired queue length field.',
  },
  desiredQueueLengthLabel: {
    defaultMessage: 'Desired Queue Length',
    id: 'Yak5lYR3',
    description: 'The label for the desired queue length field.',
  },
  maximumDownscalePerMinuteHelperText: {
    defaultMessage: 'Maximum number of instances that can be removed per minute. Must be between 1 and 100',
    id: 'tVhN9KUZ',
    description: 'The helper text for the maximum downscale per minute field.',
  },
  maximumDownscalePerMinuteLabel: {
    defaultMessage: 'Maximum Downscale Per Minute (Optional)',
    id: 'b6+MhdGz',
    description: 'The label for the maximum downscale per minute field.',
  },
  maximumReplicasHelperText: {
    defaultMessage:
      'Maximum number of instances the container will scale up to. Must be between 1 and {max_replica_count}.',
    id: 'fwXS2YEf',
    description: 'The helper text for the maximum replicas field.',
  },
  maximumReplicasLabel: {
    defaultMessage: 'Maximum Replicas',
    id: '7OgGflVQ',
    description: 'The label for the maximum replicas field.',
  },
  maximumUpscalePerMinuteHelperText: {
    defaultMessage: 'Maximum number of instances that can be added per minute. Must be between 1 and 100.',
    id: '2MmYtacP',
    description: 'The helper text for the maximum upscale per minute field.',
  },
  maximumUpscalePerMinuteLabel: {
    defaultMessage: 'Maximum Upscale Per Minute (Optional)',
    id: 'yVqFLBqN',
    description: 'The label for the maximum upscale per minute field.',
  },
  minimumReplicasHelperText: {
    defaultMessage: 'Minimum number of instances the container will scale down to. Must be between 0 and 100',
    id: 'ZHzfRC5w',
    description: 'The helper text for the minimum replicas field.',
  },
  minimumReplicasHelperTextWarning: {
    defaultMessage:
      'WARNING: Cold starts may take longer on SaladCloud. Dropping to 0 replicas could impact request processing speed upon scaling back up.',
    id: 'RFQVSGkl',
    description: 'The warning helper text for the minimum replicas field.',
  },
  minimumReplicasLabel: {
    defaultMessage: 'Minimum Replicas',
    id: 'MD+K9Dnh',
    description: 'The label for the minimum replicas field.',
  },
  periodHelperText: {
    defaultMessage: 'The period in which the queue checks the formula. Must be between 15 and 1800',
    id: '/k9tP+uk',
    description: 'The helper text for the period field.',
  },
  periodLabel: {
    defaultMessage: 'Period (Seconds)',
    id: 'qg21IDqA',
    description: 'The label for the period field.',
  },
  title: {
    defaultMessage: 'Autoscaling',
    id: 'NtABeLyi',
    description: 'The title for the Autoscaling section.',
  },
})
