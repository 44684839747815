import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface OrganizationInformationState {
  isOrganizationNameAvailable?: boolean
}

const initialState: OrganizationInformationState = {
  isOrganizationNameAvailable: undefined,
}

export const organizationInformationSlice = createSlice({
  name: 'organizationInformation',
  initialState,
  reducers: {
    setIsOrganizationNameAvailable(
      state,
      action: PayloadAction<{
        isOrganizationNameAvailable: boolean | undefined
      }>,
    ) {
      state.isOrganizationNameAvailable = action.payload.isOrganizationNameAvailable
    },
  },
})

export const { setIsOrganizationNameAvailable } = organizationInformationSlice.actions
