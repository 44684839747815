import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { EditContainerGroupQueueConnectionNotSetErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * attempting to edit a container group where the queue connection is not set and autoscaling is enabled.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getEditContainerGroupQueueConnectionNotSetErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(EditContainerGroupQueueConnectionNotSetErrorContentMessages.body),
    type: 'error',
    title: intl.formatMessage(EditContainerGroupQueueConnectionNotSetErrorContentMessages.title),
  }
}
