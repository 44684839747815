import type { RamOption } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Returns the flag indicating if the `Ram Option` is in high demand.
 *
 * @param ramOptions The `Ram Options` that SaladCloud Provides users to select from.
 * @param ramValue The `Ram Option` value that the user has listed in their resources.
 */
export const getRamOptionIsHighDemand = (
  ramOptions: RamOption[] | undefined,
  ramValue: number | null | undefined,
): boolean => {
  if (ramValue === undefined || ramOptions === undefined) return false

  return ramOptions.find((ramOption) => ramOption.value === ramValue)?.isHighDemand ?? false
}
