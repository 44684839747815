import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface TurnstileState {
  isTurnstileErrorModalOpen: boolean
}

const initialState: TurnstileState = {
  isTurnstileErrorModalOpen: false,
}

export const turnstileSlice = createSlice({
  name: 'turnstile',
  initialState,
  reducers: {
    setIsTurnstileErrorModalOpen(state, action: PayloadAction<{ isOpen: boolean }>) {
      state.isTurnstileErrorModalOpen = action.payload.isOpen
    },
  },
})

export const { setIsTurnstileErrorModalOpen } = turnstileSlice.actions

export default turnstileSlice.reducer
