import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { ClientGpuOptions } from './models'

export interface gpuClassesProps {
  organizationName: string
  gpuClasses: ClientGpuOptions
}

export const gpuClassesAdapter = createEntityAdapter<gpuClassesProps, string>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const gpuClassesSlice = createSlice({
  name: 'gpuClasses',
  initialState: gpuClassesAdapter.getInitialState(),
  reducers: {
    gpuClassesReceived(state, action: PayloadAction<{ gpuClasses: gpuClassesProps[] }>) {
      gpuClassesAdapter.setAll(state, action.payload.gpuClasses)
    },
  },
})

export const { gpuClassesReceived } = gpuClassesSlice.actions
