import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

type LastViewedResourceProps = {
  organizationName: string
  projectName: string | undefined
}

export const lastViewedResourceAdapter = createEntityAdapter<LastViewedResourceProps, string>({
  selectId: (lastViewedResource) => lastViewedResource.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const lastResourceViewedSlice = createSlice({
  name: 'lastResourceViewed',
  initialState: lastViewedResourceAdapter.getInitialState(),
  reducers: {
    lastViewedResourceAdded: lastViewedResourceAdapter.addOne,
  },
})

export const { lastViewedResourceAdded } = lastResourceViewedSlice.actions
