import { defineMessages } from 'react-intl'

export const verificationEmailSentMessages = defineMessages({
  VerificationEmailSentTitle: {
    defaultMessage: 'We sent a verification email to',
    id: 'e3Xtdc8j',
    description: 'The title of verification email sent dialog',
  },
  VerificationEmailSentDescription: {
    defaultMessage:
      'Just click on the link in your email to activate your account complete your registration. If you don’t see it, you may need to check your spam folder.',
    id: 'lFXjTowj',
    description: 'The description of verification email sent dialog',
  },
  resendEmail: {
    defaultMessage: 'Resend Email',
    id: 'y3FhIXK8',
    description: 'The "Resend Email" button text',
  },
  contactSupport: {
    defaultMessage: 'Contact Support',
    id: 'W9fl/XeE',
    description: 'The "Contact Support" button text',
  },
  contactSupportButtonClickedMixpanelEventLabel: {
    defaultMessage: 'Contact Support Clicked On Verification Email',
    id: 'BlHghVxM',
    description: 'The label for the contact support button Clicked Mixpanel',
  },
  contactSupportHelpScoutWidgetFailedMixpanelEventLabel: {
    defaultMessage: 'Help Scout Widget Failed to Open On Verification Email',
    id: 'BigwFDZz',
    description: 'The label for the HelpScout Widget Failed to Open Mixpanel Event',
  },
})
