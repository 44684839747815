import { defineMessages } from 'react-intl'

export const InviteTeamMemberSuccessContentMessages = defineMessages({
  body: {
    defaultMessage: '{user_email} has been sent an email to join {organization_display_name}.',
    id: 'RCRpmVBH',
    description:
      'The description shown in a toast notification that tells the user that we successfully sent an email that invites a team member to our organization.',
  },
  title: {
    defaultMessage: 'Team Member Invited',
    id: 'R44DCpMw',
    description: 'The toast notification title for when we successfully invited a team member to our organization.',
  },
})
