import mixpanel from 'mixpanel-browser'
import { EMPTY, filter, mergeMap, withLatestFrom } from 'rxjs'
import {
  trackMixpanelElementClickedEvent,
  trackMixpanelEvent,
  trackMixpanelPageViewedEvent,
} from '../features/analytics/analyticsSlice'
import { getContainerGroupLogsMixpanelQuery } from '../features/analytics/utils'
import { selectOrganizationId } from '../features/organizations/organizationsSelectors'
import type { AppEpic } from '../store'

export const onTrackMixpanelEvent: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(trackMixpanelEvent.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { event, properties } = action.payload

      const organizationName: string | undefined = properties['organizationName']
      const organizationId = organizationName ? selectOrganizationId(state, organizationName) : undefined
      if (organizationId) {
        properties['Organization ID'] = organizationId
      }

      mixpanel.track(event, properties)
      return EMPTY
    }),
  )

export const onTrackMixpanelElementClickedEvent: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(trackMixpanelElementClickedEvent.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        containerGroupLogsQuery,
        dockerCommand,
        jobQueueName,
        label,
        organizationName,
        path,
        projectName,
        resourceName,
      } = action.payload

      const organizationId = organizationName ? selectOrganizationId(state, organizationName) : undefined
      const properties = {
        label,
        path,
        ...(containerGroupLogsQuery && {
          containerGroupLogsQuery: getContainerGroupLogsMixpanelQuery(containerGroupLogsQuery),
        }),
        ...(dockerCommand && { 'Docker Command': dockerCommand }),
        ...(jobQueueName && { jobQueueName }),
        ...(organizationId && { 'Organization ID': organizationId }),
        ...(organizationName && { organizationName }),
        ...(projectName && { projectName }),
        ...(resourceName && { resourceName }),
      }

      mixpanel.track('Element Clicked', properties)
      return EMPTY
    }),
  )

export const onTrackMixpanelPageViewEvent: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(trackMixpanelPageViewedEvent.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { instanceId, organizationName, path, projectName, resourceName, subPage } = action.payload

      const organizationId = organizationName ? selectOrganizationId(state, organizationName) : undefined
      const properties = {
        path,
        ...(instanceId && { instanceId }),
        ...(organizationId && { 'Organization ID': organizationId }),
        ...(organizationName && { organizationName }),
        ...(projectName && { projectName }),
        ...(resourceName && { resourceName }),
        ...(subPage && { subPage }),
      }

      mixpanel.track('Page Viewed', properties)
      return EMPTY
    }),
  )
