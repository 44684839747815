import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectTranscriptionFreeTrialEnabled } from '../../features/featureFlags/featureFlagsSelectors'
import { selectInferenceEndpoints } from '../../features/inferenceEndpoints/inferenceEndpointsSelectors'
import { getInferenceEndpointsMarketplacePageData } from '../../features/inferenceEndpoints/inferenceEndpointsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjects } from '../../features/projects/projectsSelectors'
import {
  selectRequestStatusIsFailed,
  selectRequestStatusIsPending,
} from '../../features/requestStatus/requestStatusSelectors'
import { inferenceEndpointsMarketplacePageRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { InferenceEndpointsMarketplacePage } from './InferenceEndpointsMarketplacePage'

export const ConnectedInferenceEndpointsMarketplacePage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isGetInferenceEndpointsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getInferenceEndpointsMarketplacePageData'),
  )
  const isGetInferenceEndpointsFailed = useAppSelector((state) =>
    selectRequestStatusIsFailed(state, 'getInferenceEndpointsMarketplacePageData'),
  )
  const isTranscriptionFreeTrialFeatureFlagEnabled = useAppSelector((state) =>
    selectTranscriptionFreeTrialEnabled(state),
  )
  const inferenceEndpoints = useAppSelector((state) => selectInferenceEndpoints(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: false,
    }
  })

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleGetInferenceEndpointsMarketplacePageData = useCallback(() => {
    dispatch(getInferenceEndpointsMarketplacePageData({ organizationName }))
  }, [dispatch, organizationName])

  useEffect(() => {
    handleGetInferenceEndpointsMarketplacePageData()
    dispatch(trackMixpanelPageViewedEvent({ path: inferenceEndpointsMarketplacePageRoutePath }))
  }, [dispatch, handleGetInferenceEndpointsMarketplacePageData, organizationName])

  return isGetInferenceEndpointsPending ? (
    <LoadingPage />
  ) : (
    <InferenceEndpointsMarketplacePage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      inferenceEndpointsList={inferenceEndpoints}
      isErrorLoadingInferenceEndpointsMarketplace={isGetInferenceEndpointsFailed}
      isLeftColumnOpen={isLeftColumnOpen}
      isTranscriptionFreeTrialFeatureFlagEnabled={isTranscriptionFreeTrialFeatureFlagEnabled}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onProjectChange={handleProjectChange}
      onReloadPage={handleGetInferenceEndpointsMarketplacePageData}
      projects={projectSelectOptions}
    />
  )
}
