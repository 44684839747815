import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../../../components/base'
import { FileUpload } from '../../../../../../components/forms/FileUpload'
import { EditContainerGroupMainContentMessages, EditGoogleInputFieldsMessages } from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditGoogleArtifactRegistryInputFieldsProps {
  /** The control for the edit container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
}

export const EditGoogleArtifactRegistryInputFields: FunctionComponent<EditGoogleArtifactRegistryInputFieldsProps> = ({
  control,
}) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={EditContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <FileUpload
              {...field}
              {...fieldState}
              id={EditContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY}
              label={intl.formatMessage(EditGoogleInputFieldsMessages.jsonKeyLabel)}
            />
          )
        }}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(EditGoogleInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/products/sce/registries/gcp-gcr" variant="blue-filled-light">
          {intl.formatMessage(EditContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
