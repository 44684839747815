import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { CreateOrganizationValues } from './CreateOrganizationMainContent'

export const useCreateOrganizationFormValidation = (): ObjectSchema<CreateOrganizationValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        organizationName: string()
          .required()
          .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/),
        projectName: string()
          .required()
          .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/),
      }),
    [],
  )
  return validationScheme
}
