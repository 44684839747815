import classNames from 'classnames'
import { FunctionComponent, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Link } from '../../../../components/base'
import { Select } from '../../../../components/forms/Select'
import { PresetContainerMessages } from '../../messages'
import {
  CreateContainerGroupValues,
  DreamshaperPresetValues,
  FluxSchnellPresetValues,
  OllamaLlama31PresetValues,
  RecipeWorkloadType,
  SDXLPresetValues,
  StableDiffusionLargePresetValues,
  StableDiffusionMediumPresetValues,
  TgiMistral7bInstructPresetValues,
  UbuntuPresetValues,
  Yolov8MPresetValues,
} from '../../models'
import { applyPresetConfig } from '../../utils'
import { PresetContainerCard } from '../PresetContainerCard'

export interface PresetContainerProps {
  /** The function to handle docker card click event. */
  onDockerClick: () => void
  /** The function to handle card click event. */
  onSelectPresetCard: (
    cardTitle: string,
    values?: Partial<CreateContainerGroupValues>,
    overrideRequiredFields?: Partial<Record<keyof CreateContainerGroupValues, boolean>>,
  ) => void
}

export const PresetContainer: FunctionComponent<PresetContainerProps> = ({ onDockerClick, onSelectPresetCard }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [selectedWorkloadType, setSelectedWorkloadType] = useState<string | undefined>('all')

  const recipeCards = (
    intl: IntlShape,
    onSelectPresetCard: (
      cardTitle: string,
      values?: Partial<CreateContainerGroupValues>,
      overrideRequiredFields?: Partial<Record<keyof CreateContainerGroupValues, boolean>>,
    ) => void,
  ) => [
    {
      key: intl.formatMessage(PresetContainerMessages.recipesDreamshaperTitle),
      title: intl.formatMessage(PresetContainerMessages.recipesDreamshaperTitle),
      description: intl.formatMessage(PresetContainerMessages.recipesDreamshaperDescription, {
        learn_more: (
          <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/dreamshaper8-comfyui#dreamshaper-8---comfyui-api">
            {intl.formatMessage(PresetContainerMessages.learnMoreText)}
          </Link>
        ),
      }),
      workloadTypes: [RecipeWorkloadType.ImageGen],
      onClick: () =>
        onSelectPresetCard(
          intl.formatMessage(PresetContainerMessages.recipesDreamshaperTitle),
          applyPresetConfig(DreamshaperPresetValues),
        ),
    },
    {
      key: intl.formatMessage(PresetContainerMessages.recipesSDXLRefinerTitle),
      title: intl.formatMessage(PresetContainerMessages.recipesSDXLRefinerTitle),
      description: intl.formatMessage(PresetContainerMessages.recipesSDXLRefinerDescription, {
        learn_more: (
          <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/sdxl-with-refiner-comfyui#stable-diffusion-xl-with-refiner---comfyui-api">
            {intl.formatMessage(PresetContainerMessages.learnMoreText)}
          </Link>
        ),
      }),
      workloadTypes: [RecipeWorkloadType.ImageGen],
      onClick: () =>
        onSelectPresetCard(
          intl.formatMessage(PresetContainerMessages.recipesSDXLRefinerTitle),
          applyPresetConfig(SDXLPresetValues),
        ),
    },
    {
      key: intl.formatMessage(PresetContainerMessages.recipesYolov8MTitle),
      title: intl.formatMessage(PresetContainerMessages.recipesYolov8MTitle),
      description: intl.formatMessage(PresetContainerMessages.recipesYolov8MDescription, {
        learn_more: (
          <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/yolov8#yolov8-object-detection-api">
            {intl.formatMessage(PresetContainerMessages.learnMoreText)}
          </Link>
        ),
      }),
      workloadTypes: [RecipeWorkloadType.ObjectDetection],
      onClick: () =>
        onSelectPresetCard(
          intl.formatMessage(PresetContainerMessages.recipesYolov8MTitle),
          applyPresetConfig(Yolov8MPresetValues),
        ),
    },
    {
      key: intl.formatMessage(PresetContainerMessages.recipesOllamaLlama31Title),
      title: intl.formatMessage(PresetContainerMessages.recipesOllamaLlama31Title),
      description: intl.formatMessage(PresetContainerMessages.recipesOllamaLlama31Description, {
        learn_more: (
          <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/ollama-llama3.1#llama-31-8b-with-ollama">
            {intl.formatMessage(PresetContainerMessages.learnMoreText)}
          </Link>
        ),
      }),
      workloadTypes: [RecipeWorkloadType.LLM],
      onClick: () =>
        onSelectPresetCard(
          intl.formatMessage(PresetContainerMessages.recipesOllamaLlama31Title),
          applyPresetConfig(OllamaLlama31PresetValues),
        ),
    },
    {
      key: intl.formatMessage(PresetContainerMessages.recipesTgiMistral7bInstructTitle),
      title: intl.formatMessage(PresetContainerMessages.recipesTgiMistral7bInstructTitle),
      description: intl.formatMessage(PresetContainerMessages.recipesTgiMistral7bInstructDescription, {
        learn_more: (
          <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/tgi-mistral-7b#mistral-7b-on-tgi">
            {intl.formatMessage(PresetContainerMessages.learnMoreText)}
          </Link>
        ),
      }),
      workloadTypes: [RecipeWorkloadType.LLM],
      onClick: () =>
        onSelectPresetCard(
          intl.formatMessage(PresetContainerMessages.recipesTgiMistral7bInstructTitle),
          applyPresetConfig(TgiMistral7bInstructPresetValues),
        ),
    },
    {
      key: intl.formatMessage(PresetContainerMessages.recipesStableDiffusionLargeTitle),
      title: intl.formatMessage(PresetContainerMessages.recipesStableDiffusionLargeTitle),
      description: intl.formatMessage(PresetContainerMessages.recipesStableDiffusionLargeDescription, {
        learn_more: (
          <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/sd3.5-large-comfyui#stable-diffusion-35-large">
            {intl.formatMessage(PresetContainerMessages.learnMoreText)}
          </Link>
        ),
      }),
      workloadTypes: [RecipeWorkloadType.ImageGen],
      onClick: () =>
        onSelectPresetCard(
          intl.formatMessage(PresetContainerMessages.recipesStableDiffusionLargeTitle),
          applyPresetConfig(StableDiffusionLargePresetValues),
        ),
    },
    {
      key: intl.formatMessage(PresetContainerMessages.recipesStableDiffusionMediumTitle),
      title: intl.formatMessage(PresetContainerMessages.recipesStableDiffusionMediumTitle),
      description: intl.formatMessage(PresetContainerMessages.recipesStableDiffusionMediumDescription, {
        learn_more: (
          <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/sd3.5-medium-comfyui#stable-diffusion-35-medium">
            {intl.formatMessage(PresetContainerMessages.learnMoreText)}
          </Link>
        ),
      }),
      workloadTypes: [RecipeWorkloadType.ImageGen],
      onClick: () =>
        onSelectPresetCard(
          intl.formatMessage(PresetContainerMessages.recipesStableDiffusionMediumTitle),
          applyPresetConfig(StableDiffusionMediumPresetValues),
        ),
    },
  ]

  const filteredRecipeCards = recipeCards(intl, onSelectPresetCard).filter(
    (preset) =>
      selectedWorkloadType === 'all' || preset.workloadTypes.includes(selectedWorkloadType as RecipeWorkloadType),
  )

  return (
    <div className="relative h-screen w-full pb-16">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-36 w-full max-w-4xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={() => navigate(-1)}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(PresetContainerMessages.backLinkText)}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(PresetContainerMessages.presetContainerTitle)}
          </h1>
          <h3 className="mb-10 text-lg">{intl.formatMessage(PresetContainerMessages.presetContainerSubtitle)}</h3>

          <h3 className="mb-4 text-lg">
            <strong>{intl.formatMessage(PresetContainerMessages.featuredText)}</strong>
          </h3>
          <div className="mb-8 grid grid-cols-2 gap-4 sm:grid-cols-3">
            <PresetContainerCard
              buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
              title={intl.formatMessage(PresetContainerMessages.ubuntuCardTitle)}
              description={intl.formatMessage(PresetContainerMessages.ubuntuCardDescription, {
                learn_more: (
                  <Link url="https://docs.salad.com/tutorials/ubuntu-image">
                    {intl.formatMessage(PresetContainerMessages.learnMoreText)}
                  </Link>
                ),
              })}
              onClick={() =>
                onSelectPresetCard(
                  PresetContainerMessages.ubuntuCardTitle.defaultMessage,
                  applyPresetConfig(UbuntuPresetValues),
                )
              }
            />
            <PresetContainerCard
              buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
              description={intl.formatMessage(PresetContainerMessages.dockerDescription)}
              onClick={onDockerClick}
              title={intl.formatMessage(PresetContainerMessages.dockerTitle)}
            />
            <PresetContainerCard
              buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
              title={intl.formatMessage(PresetContainerMessages.recipesFluxSchnellTitle)}
              description={intl.formatMessage(PresetContainerMessages.recipesFluxSchnellDescription, {
                learn_more: (
                  <Link url="https://github.com/SaladTechnologies/salad-recipes/tree/master/src/flux1-schnell-fp8-comfyui">
                    {intl.formatMessage(PresetContainerMessages.learnMoreText)}
                  </Link>
                ),
              })}
              onClick={() =>
                onSelectPresetCard(
                  PresetContainerMessages.recipesFluxSchnellTitle.defaultMessage,
                  applyPresetConfig(FluxSchnellPresetValues),
                )
              }
            />
          </div>

          <div className="mb-8">
            <PresetContainerCard
              buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
              title={intl.formatMessage(PresetContainerMessages.customContainerGroupCardTitle)}
              description={intl.formatMessage(PresetContainerMessages.customContainerGroupCardDescription, {
                learn_more: (
                  <Link url="https://docs.salad.com/products/sce/quickstart">
                    {intl.formatMessage(PresetContainerMessages.learnMoreText)}
                  </Link>
                ),
              })}
              buttonVariant="green-filled"
              onClick={() => onSelectPresetCard(PresetContainerMessages.customContainerGroupCardTitle.defaultMessage)}
            />
          </div>

          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-lg font-bold">{intl.formatMessage(PresetContainerMessages.recipesTitle)}</h3>

            <Select
              defaultSelectedValue={selectedWorkloadType}
              hideLabelText
              isFullWidth={false}
              isPlaceholderOptionDisabled
              labelText={intl.formatMessage(PresetContainerMessages.selectWorkloadTypeLabel)}
              onChange={(value) => setSelectedWorkloadType(value)}
              options={[
                {
                  label: intl.formatMessage(PresetContainerMessages.allWorkloadsOption),
                  value: 'all',
                },
                {
                  label: intl.formatMessage(PresetContainerMessages.imageGenWorkloadOption),
                  value: 'imageGen',
                },
                {
                  label: intl.formatMessage(PresetContainerMessages.objectDetectionWorkloadOption),
                  value: 'objectDetection',
                },
                // { // TODO add in when we have Whisper model
                //   label: intl.formatMessage(PresetContainerMessages.transcriptionWorkloadOption),
                //   value: 'transcription',
                // },
                {
                  label: intl.formatMessage(PresetContainerMessages.llmWorkloadOption),
                  value: 'llm',
                },
              ]}
            />
          </div>

          <div className="mb-8 grid grid-cols-2 gap-4 sm:grid-cols-3">
            {filteredRecipeCards.map((preset) => (
              <PresetContainerCard
                buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
                description={preset.description}
                key={preset.key}
                onClick={preset.onClick}
                title={preset.title}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
