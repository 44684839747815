import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { createOrganizationWizardOrganizationNonUniqueNameContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user attempted to create an
 * organization with a name that has already been taken.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getCreateOrganizationWizardOrganizationNonUniqueNameContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(createOrganizationWizardOrganizationNonUniqueNameContentMessages.body),
    type: 'error',
    title: intl.formatMessage(createOrganizationWizardOrganizationNonUniqueNameContentMessages.title),
  }
}
