import { InferenceEndpoint } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { SaladCloudVerified } from '../SaladCloudVerified'
import { Tag } from '../Tag'
import { InferenceEndpointCardMessages } from './messages'

export interface InferenceEndpointCardProps {
  /** The inference endpoint item to display. */
  inferenceEndpoint: InferenceEndpoint
  /** A flag indicating if the transcription free trial feature flag is enabled. */
  isTranscriptionFreeTrialFeatureFlagEnabled?: boolean
  /** The callback called when the recipe card is clicked. */
  path: string
}

export const InferenceEndpointCard: FunctionComponent<InferenceEndpointCardProps> = ({
  inferenceEndpoint,
  isTranscriptionFreeTrialFeatureFlagEnabled,
  path,
}) => {
  const intl = useIntl()
  const { description, displayName, iconImage, name } = inferenceEndpoint
  const inferenceEndpointName = displayName || name
  return (
    <Link
      className="flex w-full flex-row flex-nowrap overflow-hidden rounded-lg shadow-md hover:shadow-green-30 focus-visible:shadow-green-30 focus-visible:outline-none"
      to={path}
    >
      <img src={iconImage} alt={inferenceEndpointName} className="size-64 object-cover" />
      <div className="flex w-full flex-col p-4">
        <div className="flex items-center justify-between space-x-2">
          <p className="text-lg font-bold">{inferenceEndpointName}</p>
          <SaladCloudVerified />
        </div>
        <p className="mt-2 text-sm">{description}</p>
        {isTranscriptionFreeTrialFeatureFlagEnabled && (
          <p className="mt-2">
            <Tag color="blue">{intl.formatMessage(InferenceEndpointCardMessages.apiEndpointsIntroCreditsTag)}</Tag>
          </p>
        )}
      </div>
    </Link>
  )
}
