import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { EditContainerGroupMainContentMessages, EditDockerHubInputFieldsMessages } from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditDockerHubInputFieldsProps {
  /** The control for the edit container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
}

export const EditDockerHubInputFields: FunctionComponent<EditDockerHubInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={EditContainerGroupField.DOCKER_HUB_USERNAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditDockerHubInputFieldsMessages.usernameLabel)}
            placeholder={intl.formatMessage(EditDockerHubInputFieldsMessages.usernamePlaceholderText)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditDockerHubInputFieldsMessages.personalAccessTokenLabel)}
            placeholder={intl.formatMessage(EditDockerHubInputFieldsMessages.personalAccessTokenPlaceholderText)}
            type="password"
            isFullWidth
          />
        )}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(EditDockerHubInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/products/sce/registries/dockerhub" variant="blue-filled-light">
          {intl.formatMessage(EditContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
