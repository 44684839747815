import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { Button } from '../../components/Button'
import { organizationsPageRoutePath } from '../../routes/routePaths'
import pageNotFoundBackgroundUrl from './assets/page-not-found-background.png'
import pageNotFoundUrl from './assets/page-not-found.png'
import { PageNotFoundMessages } from './messages/PageNotFoundMessages'

export interface PageNotFoundProps {}

export const PageNotFound: FunctionComponent<PageNotFoundProps> = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const handleGoBackClick = () => {
    if (window.history.state.idx === 0) {
      navigate(organizationsPageRoutePath)
    } else {
      navigate(-1)
    }
  }

  return (
    <div className="fixed size-full bg-neutral-100">
      <img
        className="absolute right-0 top-0 h-full opacity-10"
        src={pageNotFoundBackgroundUrl}
        alt={intl.formatMessage(PageNotFoundMessages.pageNotFoundBackgroundImage)}
      />
      <div className="relative flex size-full flex-row items-center justify-between p-10 md:p-32">
        <div className="flex-1">
          <div className="flex w-full flex-col gap-8 md:w-96">
            <h1 className="text-5xl text-green-40">{intl.formatMessage(PageNotFoundMessages.pageNotFoundTitle)}</h1>
            <p className="text-3xl text-neutral-40">
              {intl.formatMessage(PageNotFoundMessages.pageNotFoundDescription)}
            </p>
            <div className="w-1/3">
              <Button onClick={handleGoBackClick} variant="green-filled" isFullWidth>
                {intl.formatMessage(PageNotFoundMessages.goBack)}
              </Button>
            </div>
          </div>
        </div>
        <img
          className="left-0 hidden max-h-96 sm:block"
          src={pageNotFoundUrl}
          alt={intl.formatMessage(PageNotFoundMessages.pageNotFoundImage)}
        />
      </div>
    </div>
  )
}
