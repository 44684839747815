import { useCallback, type FunctionComponent, type ReactNode } from 'react'
import { selectIsHelpScoutBeaconFailedToOpenModalOpen } from '../features/helpScout/helpScoutSelectors'
import { setIsHelpScoutBeaconFailedToOpenModalOpen } from '../features/helpScout/helpScoutSlice'
import { ToastContainer } from '../notifications'
import { useAppDispatch, useAppSelector } from '../store'
import { HelpScoutBeaconFailedToLoadModal } from '../utils/helpScout/components'

interface PublicRouteProps {
  children: ReactNode
}

export const PublicRoute: FunctionComponent<PublicRouteProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const isHelpScoutBeaconFailedToLoadModalOpen = useAppSelector(selectIsHelpScoutBeaconFailedToOpenModalOpen)

  const handleCloseHelpScoutBeaconFailedToLoadModal = useCallback(() => {
    dispatch(setIsHelpScoutBeaconFailedToOpenModalOpen({ isOpen: false }))
  }, [dispatch])

  return (
    <>
      <ToastContainer />
      {children}
      {isHelpScoutBeaconFailedToLoadModalOpen && (
        <HelpScoutBeaconFailedToLoadModal onClose={handleCloseHelpScoutBeaconFailedToLoadModal} />
      )}
    </>
  )
}
