import { InferenceEndpoint } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { FailedToLoadInferenceEndpointsMarketplace } from './components/FailedToLoadInferenceEndpointsMarketplace/FailedToLoadInferenceEndpointsMarketplace'
import { InferenceEndpointsMarketplaceMainContent } from './components/InferenceEndpointsMarketplaceMainContent/InferenceEndpointsMarketplaceMainContent'

export interface InferenceEndpointsMarketplacePageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The list of inference endpoints available in the marketplace */
  inferenceEndpointsList: InferenceEndpoint[]
  /** The flag indicating if there was an error loading the inference endpoints marketplace. */
  isErrorLoadingInferenceEndpointsMarketplace: boolean
  /** The flag indicating if the left column is showing. */
  isLeftColumnOpen: boolean
  /** The flag indicating if the transcription free trial feature flag is enabled */
  isTranscriptionFreeTrialFeatureFlagEnabled?: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The handler updating the project */
  onProjectChange: (projectName: string) => void
  /** The callback that loads the page again */
  onReloadPage: () => void
  /** An array of the current available projects. */
  projects: ProjectSelectOptions
}

export const InferenceEndpointsMarketplacePage: FunctionComponent<InferenceEndpointsMarketplacePageProps> = ({
  currentOrganization,
  inferenceEndpointsList,
  isErrorLoadingInferenceEndpointsMarketplace,
  isLeftColumnOpen,
  isTranscriptionFreeTrialFeatureFlagEnabled,
  onCloseLeftDrawer,
  onProjectChange,
  onReloadPage,
  projects,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          isProjectSelectPlaceholderOptionSelectable
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      MainColumn={
        isErrorLoadingInferenceEndpointsMarketplace ? (
          <FailedToLoadInferenceEndpointsMarketplace onReloadPage={onReloadPage} />
        ) : (
          <InferenceEndpointsMarketplaceMainContent
            inferenceEndpointsList={inferenceEndpointsList}
            isTranscriptionFreeTrialFeatureFlagEnabled={isTranscriptionFreeTrialFeatureFlagEnabled}
            organizationName={currentOrganization.name}
          />
        )
      }
    />
  )
}
