import { Inbox } from '@novu/react'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { REACT_APP_NOVU_APP_ID, REACT_APP_NOVU_BACKEND_URL, REACT_APP_NOVU_WEBSOCKET_URL } from '../../config'
import { useDetectClickOutsideElement } from '../../hooks'
import { Button } from '../Button'
import { SaladLogo } from '../SaladLogo'
import { DropdownMenuItem } from '../base/DropdownMenuItem/DropdownMenuItem'
import { NavigationBarMessages } from './messages'

interface NavigationBarProps {
  /** The user's avatar url. */
  avatarUrl: string | undefined
  /** The user's email */
  email: string
  /** The user's Id */
  userId?: string
  /** The novu's hmac hash signature. */
  novuSignature?: string
  /** Indicates whether logout process is in progress. */
  isLogoutPending: boolean
  /** The feature flag indicating if the inbox is enabled. */
  isInboxFeatureFlagEnabled: boolean
  /** On alert button click handler TODO: Update this to required once Alerts is ready */
  onAlertsClick?: () => void
  /** The handler for opening the Help Scout icon */
  onContactSupportViaHelpScoutAnswers?: () => void
  /** The handler for opening and closing the left colummn */
  onLeftMenuClick: () => void
  /** The handler for logging out */
  onLogOut: () => void
  /** The display name of the user's organization. */
  organizationDisplayName?: string
}

export const NavigationBar: FunctionComponent<NavigationBarProps> = ({
  avatarUrl,
  email,
  isInboxFeatureFlagEnabled,
  isLogoutPending,
  novuSignature,
  onAlertsClick,
  onContactSupportViaHelpScoutAnswers,
  onLeftMenuClick,
  onLogOut,
  organizationDisplayName,
  userId,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isNavBarDropdownOpen, setIsNavBarDropdownOpen] = useState(false)
  const navBarButtonRef = useRef<HTMLDivElement>(null)
  useDetectClickOutsideElement(navBarButtonRef, () => setIsNavBarDropdownOpen(false))

  const isAlertFeatureReady = false
  const isNovuInboxVisible = isInboxFeatureFlagEnabled && userId && REACT_APP_NOVU_APP_ID && novuSignature

  return (
    <header className="sticky top-0 z-50 flex h-16 w-full items-center justify-between bg-neutral-100 px-6">
      {/* LeftNavigationContent */}
      <nav aria-label={intl.formatMessage(NavigationBarMessages.leftSideNavigationAriaLabel)}>
        <Link to="/organizations" className="max-sm:hidden">
          <SaladLogo className="w-24" height="100%" width={96} />
        </Link>
        <div className="sm:hidden">
          <Button
            iconClassName="fa-solid fa-bars"
            variant="neutral-text"
            onClick={onLeftMenuClick}
            ariaLabel={intl.formatMessage(NavigationBarMessages.hamburgerMenuButtonAriaLabel)}
          />
        </div>
      </nav>

      {/* RightNavigationContent */}
      <nav
        className="flex items-center gap-x-6"
        aria-label={intl.formatMessage(NavigationBarMessages.rightSideNavigationAriaLabel)}
      >
        {isAlertFeatureReady && (
          <div className="sm:hidden">
            <Button iconClassName="fa-solid fa-bell" variant="neutral-text" onClick={onAlertsClick}>
              {intl.formatMessage(NavigationBarMessages.alertButtonLabel)}
            </Button>
          </div>
        )}
        {isNovuInboxVisible && (
          <Inbox
            backendUrl={REACT_APP_NOVU_BACKEND_URL}
            socketUrl={REACT_APP_NOVU_WEBSOCKET_URL}
            applicationIdentifier={REACT_APP_NOVU_APP_ID as string}
            subscriberId={userId}
            subscriberHash={novuSignature}
            routerPush={(path: string) => navigate(path)}
            appearance={{
              elements: {
                bellIcon: 'text-neutral-40 w-full h-full',
                button: 'flex hover:bg-neutral-80',
                bellDot: 'bg-green-50',
                notificationDot: 'bg-green-50',
                inboxStatus__dropdownTrigger: 'hover:stroke-neutral-10 hover:text-neutral-10 px-1',
                moreActions__dropdownTrigger: 'hover:stroke-neutral-10',
                preferences__button: 'hover:stroke-neutral-10',
                back__button: 'hover:stroke-neutral-10',
                popoverContent: 'bg-neutral-20',
                notification: 'bg-neutral-10 text-neutral-100 hover:bg-green-10',
                inboxHeader: 'px-4',
                notificationListContainer: 'px-4',
                notificationList: 'gap-3',
              },
            }}
          />
        )}

        <Button
          variant="neutral-text"
          iconClassName="fa-regular fa-fw fa-circle-question bg-transparent text-2xl text-neutral-40"
          onClick={onContactSupportViaHelpScoutAnswers}
        />

        <div ref={navBarButtonRef}>
          <button
            className="flex cursor-pointer items-center hover:text-green-60 focus:text-green-80 active:text-green-80"
            onClick={() => setIsNavBarDropdownOpen(!isNavBarDropdownOpen)}
          >
            <figure className="mr-3">
              <img
                className="size-8 rounded-full"
                src={avatarUrl || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'}
                alt={intl.formatMessage(NavigationBarMessages.avatarAlt)}
              />
            </figure>
            <div className="mr-4 text-start">
              <p className="text-sm font-bold text-neutral-10">{email}</p>
              {organizationDisplayName && <p className="text-xs text-neutral-60">{organizationDisplayName}</p>}
            </div>
            <div
              className={classNames('text-neutral-60', {
                'fa-solid fa-chevron-down': !isNavBarDropdownOpen,
                'fa-solid fa-chevron-up': isNavBarDropdownOpen,
              })}
            />
          </button>

          {isNavBarDropdownOpen && (
            <ul
              className="absolute right-0 top-16 z-10 w-52 overflow-hidden rounded-md border border-neutral-50 bg-neutral-10 shadow-lg ring-1"
              onClick={() => setIsNavBarDropdownOpen(false)}
            >
              <DropdownMenuItem path="/organizations" label={intl.formatMessage(NavigationBarMessages.orgLabel)} />
              <DropdownMenuItem path="/account" label={intl.formatMessage(NavigationBarMessages.accountLabel)} />
              <DropdownMenuItem path="/api-key" label={intl.formatMessage(NavigationBarMessages.apiLabel)} hasDivider />
              <DropdownMenuItem
                isLoading={isLogoutPending}
                onClick={onLogOut}
                label={intl.formatMessage(NavigationBarMessages.logoutLabel)}
                isDanger
              />
            </ul>
          )}
        </div>
      </nav>
    </header>
  )
}
